import { create } from 'zustand'

type navBar = {
  statusBar: boolean
  setStatus: () => void
}
export const navBarStore = create<navBar>(set => ({
  statusBar: true,
  setStatus: () => {
    set(state => ({ statusBar: !state.statusBar }))
  }
}))
