// administratorConfig.ts
import { FieldTypeEnum, FormField } from '../../Form/DynamicForm'
import { TableColumn, enumTypeColumn } from '../../Table'

export const columnsAdministrator: TableColumn[] = [
  {
    title: 'Foto',
    dataIndex: 'photo',
    key: 'photo',
    type: enumTypeColumn.photo
  },
  {
    title: 'Primer nombre',
    dataIndex: 'name',
    key: 'name',
    type: enumTypeColumn.string
  },
  {
    title: 'Apellido',
    dataIndex: 'lastName',
    key: 'lastName',
    type: enumTypeColumn.string
  },
  {
    title: 'User',
    dataIndex: 'userName',
    key: 'userName',
    type: enumTypeColumn.string
  },
  {
    title: 'Teléfono',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    type: enumTypeColumn.string
  },
  {
    title: 'Creado en',
    dataIndex: 'createdAt',
    key: 'createdAt',
    type: enumTypeColumn.date
  }
]

export const administratorFormConfig: FormField[] = [
  { type: FieldTypeEnum.text, label: 'Primer nombre', name: 'name' },
  { type: FieldTypeEnum.text, label: 'Apellido', name: 'lastName' },
  { type: FieldTypeEnum.text, label: 'User', name: 'userName' },
  { type: FieldTypeEnum.text, label: 'Contraseña', name: 'password' },
  { type: FieldTypeEnum.image, label: 'Foto', name: 'photo' },
  { type: FieldTypeEnum.phone, label: 'Teléfono', name: 'phoneNumber' }
]
