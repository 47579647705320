// src/pages/BrandsPage.tsx
import React, { useEffect, useState } from 'react'

import { columnsBrand } from '../../components/Tables/Brand/Columns'

import VisibilityIcon from '@mui/icons-material/Visibility'
import { IconButton } from '@mui/material'
import { useLoading } from '../../Provider/LoadingProvider'
import Modal from '../../components/GeneralComponents/Modal'
import Table from '../../components/Table'
import DeleteButton from '../../components/Table/components/DeleteButton'
import FormBrand from '../../components/Tables/Brand/Form'
import { deleteBrand, listBrand } from '../../services/brand'
import { brand } from '../../types/brand'
import { Pagination } from '../../types/types'
import './style.scss'

const BrandsPage = () => {
  const [data, setData] = useState<Pagination<brand>>({
    currentPage: 1,
    hasNextPage: false,
    hasPrevPage: false,
    total: 1,
    totalPages: 1,
    data: []
  })
  const limit = 6
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [search, setSearch] = useState('')
  const [filteredBySearch, setFilteredBySearch] = useState(false)
  const [selected, setSelected] = useState<brand | undefined>(undefined)
  const { startLoading, stopLoading } = useLoading()

  useEffect(() => {
    getData(search.length > 3 ? search : '', data.currentPage)
  }, [])

  useEffect(() => {
    if (search.length >= 3) {
      setFilteredBySearch(true)
      getData(search, 1)
    } else if (filteredBySearch) {
      setFilteredBySearch(false)
      getData('', 1)
    }
  }, [search])

  useEffect(() => {
    getData(search, data.currentPage)
  }, [data.currentPage])

  const onCloseModal = () => {
    setIsOpenModal(false)
    setSelected(undefined)
  }

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const getData = async (searchValue: string, page: number) => {
    try {
      startLoading()

      const brands = await listBrand({
        params: { page, search: searchValue, limit }
      })
      setData({
        ...brands,
        data: brands.data.map((e) => ({ ...e, originName: e?.origin?.name }))
      })
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const handleSubmit = async () => {
    try {
      startLoading()
      setIsOpenModal(false)
      await getData(search.length > 3 ? search : '', data.currentPage)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const handlePageChange = (newPage: number) => {
    getData(search.length >= 3 ? search : '', newPage)
  }

  const handleDelete = async (id: string) => {
    try {
      startLoading()
      await deleteBrand(id)
      await getData(search.length > 3 ? search : '', data.currentPage)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }
  const handleView = (id: brand) => {
    setSelected(id)
  }

  const Actions = ({ id, item }: { id: string; item?: brand }) => {
    return (
      <>
        <IconButton onClick={() => handleView(item as brand)}>
          <VisibilityIcon style={{ color: 'white' }} />
        </IconButton>
        <DeleteButton OnDelete={() => handleDelete(id)} />
      </>
    )
  }

  useEffect(() => {
    if (selected) {
      setIsOpenModal(true)
    }
  }, [selected])

  return (
    <>
      <div className='brandContainer'>
        <div className='title'>
          <p>Marcas</p>
        </div>
        <div className='tableContainer'>
          <Table
            onPageChange={handlePageChange}
            onChangeSearch={setSearch}
            pagination={data}
            handleOpenModal={handleOpenModal}
            columns={columnsBrand}
            Actions={Actions}
            itemsPerPage={limit}
          />
        </div>
      </div>
      <Modal isOpen={isOpenModal} onClose={onCloseModal}>
        <FormBrand initialValue={selected} onSubmit={handleSubmit} />
      </Modal>
    </>
  )
}

export default BrandsPage
