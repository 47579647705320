import React, { useState, useEffect, forwardRef } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import './styles.scss'

interface SelectOption {
  value: string
  label: string
}

interface CustomSelectProps {
  icon?: string
  options: SelectOption[]
  value?: string
  onChange?: (selectedValue: string) => void
  onChangeText?: (value: string) => void
  label?: string
  DefaultValue?: string
}

// Usando forwardRef para poder pasar una referencia desde el componente padre
const CustomSelect = forwardRef<HTMLInputElement, CustomSelectProps>(
  ({ icon, options, value, onChange, label, onChangeText, DefaultValue }, ref) => {
    const [selected, setSelected] = useState<SelectOption | null>(null)
    const [inputText, setInputText] = useState('')

    useEffect(() => {
      if (value) {
        const selectedOption = options.find(option => option.value === value)
        setSelected(selectedOption || null)
      } else {
        setSelected(null)
      }
    }, [value, options]) // Añadido para evitar que el efecto se ejecute en cada render

    const handleSelectChange = (
      _event: React.SyntheticEvent,
      selectedValue: SelectOption | null
    ) => {
      setSelected(selectedValue)
      if (selectedValue && onChange) {
        onChange(selectedValue.value)
      }
    }

    return (
      <div className="custom-select-container">
        <Autocomplete
          value={selected}
          onChange={handleSelectChange}
          inputValue={inputText}
          onInputChange={(_event, newInputValue) => {
            setInputText(newInputValue)
            if (onChangeText) {
              onChangeText(newInputValue)
            }
            if (onChange && !newInputValue) {
              onChange(newInputValue)
            }
          }}
          options={options}
          getOptionLabel={option => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value} // Comparación personalizada
          renderInput={params => (
            <TextField
              {...params}
              value={inputText}
              onChange={e => {
                setInputText(e.target.value)
                if (onChangeText) {
                  onChangeText(e.target.value)
                }
              }}
              placeholder={label ? `Seleccionar ${label}` : 'Seleccionar...'}
              inputRef={ref} // Asignamos la referencia al input
              InputLabelProps={{
                style: { color: 'white' }
              }}
              InputProps={{
                ...params.InputProps,
                style: { color: 'white', padding: '5px' },
                startAdornment: icon ? (
                  <img src={icon} alt="Icono" className="select-icon" />
                ) : null,
                // Aplicamos estilos para cortar el texto largo
                sx: {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '100%' // O ajusta este valor según sea necesario
                }
              }}
            />
          )}
        />
      </div>
    )
  }
)

export default CustomSelect
