import React, { FC } from 'react'
import DynamicForm from '../../Form/DynamicForm'
import './styles.scss'

import { useLoading } from '../../../Provider/LoadingProvider'
import { createCorp, updateCorp } from '../../../services/corps'
import { Corporate, CorporateCreate } from '../../../types/Corporate'
import { CorporateFormConfig } from './Columns'
import {} from '../../../utils/utils'

const FormCorporate: FC<{ onSubmit: () => void; initialValue?: Corporate }> = ({
  onSubmit,
  initialValue
}) => {
  const { startLoading, stopLoading } = useLoading()

  const handleSubmit = async (formData: CorporateCreate) => {
    try {
      startLoading()
      const data = new FormData()
      Object.entries({
        name: formData.name,
        lastName: formData.lastName,
        userName: formData.userName,
        phoneNumber: formData.phoneNumber,
        ...(formData.password ? { password: formData.password } : {}),
        photo: formData.photo
      }).forEach(([key, value]) => {
        data.append(key, value)
      })
      if (initialValue) {
        await updateCorp(initialValue.id, data)
      } else {
        await createCorp(data)
      }

      onSubmit()
    } catch (error) {
      // Manejo de errores
    } finally {
      stopLoading()
    }
  }

  return (
    <div className='ContainerCorp'>
      <h2>{initialValue ? 'Actualizar Corporativo' : 'Nuevo Corporativo'}</h2>
      <DynamicForm
        initialValues={initialValue}
        formConfig={CorporateFormConfig}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

export default FormCorporate
