import { TableColumn, enumTypeColumn } from '../../Table'

export const columnsCheckInOkId: TableColumn[] = [
  {
    title: 'id',
    dataIndex: 'showId',
    key: 'showId',
    type: enumTypeColumn.showId
  },
  {
    title: 'DNI',
    dataIndex: 'DNI',
    key: 'DNI',
    type: enumTypeColumn.string
  },
  {
    title: 'Foto',
    dataIndex: 'photo',
    key: 'photo',
    type: enumTypeColumn.photo
  },

  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    type: enumTypeColumn.string
  },
  {
    title: 'Apellido',
    dataIndex: 'lastName',
    key: 'lastName',
    type: enumTypeColumn.string
  },
  {
    title: 'Destino',
    dataIndex: 'destination',
    key: 'destination',
    type: enumTypeColumn.string
  },
  {
    title: 'Origen',
    dataIndex: 'origin',
    key: 'origin',
    type: enumTypeColumn.string
  },
  {
    title: 'Marca',
    dataIndex: 'brand',
    key: 'brand',
    type: enumTypeColumn.string
  },

  {
    title: 'Hora IN',
    dataIndex: 'timeIn',
    key: 'timeIn',
    type: enumTypeColumn.date,
    options: 'time'
  },
  {
    title: 'Hora OUT',
    dataIndex: 'timeOut',
    key: 'timeOut',
    type: enumTypeColumn.date,
    options: 'time'
  },
  {
    title: 'RF',
    dataIndex: 'trm',
    key: 'trm',
    type: enumTypeColumn.string,
    options: 'day'
  },

  {
    title: 'OKID',
    dataIndex: 'okid',
    key: 'okid',
    type: enumTypeColumn.string
  }
]
