// src/services/super_admins.ts
import { Destination, DestinationCreate } from '../types/Destination'
import { Pagination, PaginationQuery } from '../types/types'
import axiosInstance from './axiosInstance'

export const listDestinations = async ({
  params
}: {
  params: PaginationQuery
}): Promise<Pagination<Destination>> => {
  try {
    const response = await axiosInstance.get('/api/destinations', {
      params
    })

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de destinos:', error)
    throw error
  }
}

export const listDestinationsAll = async (token?: string): Promise<Destination[]> => {
  try {
    const headers = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      : {}
    const response = await axiosInstance.get('/api/destinations/all', headers)

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de destinos:', error)
    throw error
  }
}

export const updateDestination = async (
  id: string,
  params: DestinationCreate
): Promise<Destination> => {
  try {
    const response = await axiosInstance.post(
      `/api/destinations/${id}`,
      JSON.stringify(params),
      {}
    )

    return response.data
  } catch (error) {
    console.error('Error en la creación de Destino:', error)
    throw error
  }
}

export const createDestination = async (params: DestinationCreate): Promise<Destination> => {
  try {
    const response = await axiosInstance.post('/api/destinations', JSON.stringify(params), {})

    return response.data
  } catch (error) {
    console.error('Error en la creación de Destino:', error)
    throw error
  }
}

export const deleteDestination = async (id: string): Promise<void> => {
  try {
    const response = await axiosInstance.delete(`/api/destinations/${id}`)

    return response.data
  } catch (error) {
    console.error('Error al eliminar Destino:', error)
    throw error
  }
}
