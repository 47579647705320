import { SelectOption } from '../components/Form/Select'
import { PaymentConceptResident } from '../types/Payments'
import axiosInstance from './axiosInstance'

export const getConcept = async (
  residentId: string,
  clientId: string
): Promise<SelectOption[]> => {
  try {
    const response = await axiosInstance.get(`/api/conceptsResident/${residentId}/${clientId}`)

    return response.data
  } catch (error) {
    throw error
  }
}

export const newCuota = async (
  params: PaymentConceptResident
): Promise<PaymentConceptResident> => {
  try {
    const response = await axiosInstance.post(
      `/api/conceptsResident`,
      JSON.stringify(params),
      {}
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export const updateCuota = async (
  params: PaymentConceptResident,
  conceptId: string
): Promise<PaymentConceptResident> => {
  try {
    const response = await axiosInstance.post(
      `/api/conceptsResident/${conceptId}`,
      JSON.stringify(params),
      {}
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export const statusConceptResident = async (id: string) => {
  try {
    await axiosInstance.post(`/api/conceptsResident/status/${id}`)
  } catch (error) {
    throw error
  }
}

export const deleteConceptResident = async (id: string) => {
  try {
    await axiosInstance.delete(`/api/conceptsResident/${id}`)
  } catch (error) {
    throw error
  }
}
