// services/workerService.ts
import { Worker } from '../types/worker'
import { Pagination, PaginationQuery } from '../types/types'
import axiosInstance from './axiosInstance'

export const listWorkersByResident = async ({
  id,
  params
}: {
  id: string
  params: PaginationQuery
}): Promise<Pagination<Worker>> => {
  try {
    const response = await axiosInstance.get(`/api/workers/byResident/${id}`, {
      params
    })

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de trabajadores:', error)
    throw error
  }
}

export const createWorker = async (params: FormData): Promise<Worker> => {
  try {
    const response = await axiosInstance.post(`/api/workers`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de creación de trabajador:', error)
    throw error
  }
}

export const updateWorker = async (
  id: string,
  params: FormData
): Promise<Worker> => {
  try {
    const response = await axiosInstance.post(`/api/workers/${id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de creación de trabajador:', error)
    throw error
  }
}

export const enableWorker = async (
  id: string,
  params: FormData
): Promise<Worker> => {
  try {
    const response = await axiosInstance.post(
      `/api/workers/enable/${id}`,
      params,
      {
        headers: {
          'Content-Type': 'multipart/form-data;'
        }
      }
    )

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de creación de trabajador:', error)
    throw error
  }
}

export const deleteWorker = async (id: string): Promise<void> => {
  try {
    const response = await axiosInstance.delete(`/api/workers/${id}`)

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de eliminación de trabajador:', error)
    throw error
  }
}
export const regenerateCodeWorker = async (
  id: string
): Promise<{ qr: string }> => {
  try {
    const response = await axiosInstance.post(
      `/api/workers/regenerate_qr/${id}`
    )

    return response.data
  } catch (error) {
    console.error('Error al eliminar Residente:', error)
    throw error
  }
}
