import { Receptionist } from '../types/receptionist'
import { Pagination, PaginationQuery } from '../types/types'
import axiosInstance from './axiosInstance'

export const listReceptionistClient = async ({
  id,
  params
}: {
  id: string
  params: PaginationQuery
}): Promise<Pagination<Receptionist>> => {
  try {
    const response = await axiosInstance.get(`/api/receptionists/byclient/${id}`, {
      params
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de inicio de sesión:', error)
    throw error
  }
}

export const createReceptionist = async (params: FormData): Promise<Receptionist> => {
  try {
    const response = await axiosInstance.post(`/api/receptionists`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de inicio de sesión:', error)
    throw error
  }
}
export const updateReceptionist = async (
  id: string,
  params: FormData
): Promise<Receptionist> => {
  try {
    const response = await axiosInstance.post(`/api/receptionists/${id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de inicio de sesión:', error)
    throw error
  }
}
export const deleteReceptionist = async (id: string): Promise<void> => {
  try {
    const response = await axiosInstance.delete(`/api/receptionists/${id}`)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de inicio de sesión:', error)
    throw error
  }
}
