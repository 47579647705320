import React, { useState, useRef, useCallback, useEffect } from 'react'
import './style.scss'

interface ImageUploadProps {
  label: string
  value: File | null
  onChange: (value: File | null) => void
  styles?: React.CSSProperties
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  label,
  value,
  onChange,
  styles
}) => {
  const [isDragging, setIsDragging] = useState(false)
  /*   const [isHovered, setIsHovered] = useState(false)
   */ const [imageSrc, setImageSrc] = useState<string | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (value instanceof File) {
      const reader = new FileReader()
      reader.onload = () => {
        const imgData = reader.result !== 'null' ? `${reader.result}` : ''

        setImageSrc(imgData as string)
      }
      reader.readAsDataURL(value)
    } else if (typeof value === 'string' && value !== 'null') {
      setImageSrc(value)
    } else {
      setImageSrc(null)
    }
  }, [value])

  const handleDragEnter = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(true)
  }, [])

  const handleDragLeave = useCallback(() => {
    setIsDragging(false)
  }, [])

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault()
      setIsDragging(false)

      const files = e.dataTransfer.files
      if (files.length > 0) {
        const selectedFile = files[0]
        onChange(selectedFile)
      }
    },
    [onChange]
  )

  const handleFileInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files
      if (files && files.length > 0) {
        const selectedFile = files[0]
        onChange(selectedFile)
      }
    },
    [onChange]
  )

  const openFilePicker = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }, [])

  const handleDeleteClick = () => {
    onChange(null)
  }

  return (
    <div className='image-upload-container'>
      {label && <label className='input-label'>{label}</label>}
      <div
        className={`image-upload ${isDragging ? 'dragging' : ''}`}
        onDragEnter={handleDragEnter}
        onDragOver={(e) => e.preventDefault()}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={openFilePicker}
        /*  onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)} */
      >
        <input
          ref={inputRef}
          type='file'
          accept='image/*'
          style={{ display: 'none' }}
          onChange={handleFileInputChange}
        />

        <div
          className='image-container'
          style={styles ? styles : { height: imageSrc ? 'auto' : '200px' }}>
          {isDragging ? (
            <p>Suelta aquí</p>
          ) : imageSrc ? null : (
            <p className='drag-text'>Arrastra y suelta ó sube {label ?? ''}</p>
          )}
          {imageSrc && (
            <img
              src={imageSrc || ''}
              alt='Selected'
              className='uploaded-image'
            />
          )}
          {imageSrc && (
            <button
              type='button'
              className='close-button'
              onClick={(e) => {
                e.stopPropagation()
                handleDeleteClick()
              }}>
              X
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default ImageUpload
