import React, { useState, useEffect, ChangeEvent } from 'react'
import './style.scss'

interface SelectOption {
  value: string
  label: string
}

interface SelectProps {
  icon?: string
  options: SelectOption[]
  value?: string
  Name?: string
  style?: React.CSSProperties | undefined
  placeholder: string
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void
  label?: string
}

const Select: React.FC<SelectProps> = ({
  options,
  placeholder,
  onChange,
  label,
  value,
  Name,
  style
}) => {
  const [selected, setSelected] = useState<string | undefined>(value)

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newValue = event.target.value
    setSelected(newValue)
    if (onChange) {
      onChange(event)
    }
  }

  useEffect(() => {
    setSelected(value)
  }, [value])

  const [filteredOptions, setFilteredOptions] =
    useState<SelectOption[]>(options)

  useEffect(() => {
    setFilteredOptions(options)
  }, [options])

  return (
    <div className='select-container2'>
      {label && <label className='input-label2'>{label}</label>}
      <select
        className=' select-element2'
        style={style}
        value={selected}
        name={Name}
        onChange={handleChange}>
        <option value=''>{placeholder}</option>
        {filteredOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default Select
