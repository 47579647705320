import React from 'react'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #dadde9',
    fontWeight: 600,
    whiteSpace: 'pre-wrap' // Añadir esta línea para manejar saltos de línea
  }
}))
