import React, { useState, useEffect } from 'react'
import './style.scss'

export interface SelectOption {
  value: string
  label: string
}

interface SelectProps {
  icon?: string
  options: SelectOption[]
  value?: string
  placeholder?: string
  onChange?: (selectedValue: string) => void
  label?: string
}

const Select: React.FC<SelectProps> = ({
  options,
  value,
  placeholder,
  onChange,
  label,
  icon
}) => {
  const [selected, setSelected] = useState(value || '')
  /*   const [filterText, setFilterText] = useState('')
   */ const [filteredOptions, setFilteredOptions] = useState<SelectOption[]>(options)

  useEffect(() => {
    setFilteredOptions(options.filter(option => option.label.toLowerCase()))
  }, [options])

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value
    setSelected(selectedValue)
    if (onChange) {
      onChange(selectedValue)
    }
  }
  /* 
  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value)
  } */
  useEffect(() => {
    setSelected(value || '')
  }, [value])

  return (
    <div className="select-container">
      {label && <label className="input-label">{label}</label>}
      <div className={`icon-input-container`}>
        {icon && <img className="icon" src={icon} alt={label} />}
        <select
          className="input-element select-element"
          value={selected}
          onChange={handleSelectChange}
        >
          <option value="">
            {placeholder ? `Seleccionar ${placeholder}` : 'Seleccionar...'}
          </option>
          {filteredOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default Select
