import axios from 'axios'

export const get_whatsapp = async (
  clientId: string,
  token: string
): Promise<{ status: string }> => {
  try {
    const response = await axios.get(
      `${
        process.env.REACT_APP_API_URL_WHATS || 'https://whats.ipass.com.gt'
      }/get_whatsapp/${clientId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export const reset_whatsapp = async (
  clientId: string,
  token: string
): Promise<{ status: string }> => {
  try {
    const response = await axios.get(
      `${
        process.env.REACT_APP_API_URL_WHATS || 'https://whats.ipass.com.gt'
      }/reset/${clientId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de inicio de sesión:', error)
    throw error
  }
}

export const send_ticket = async (
  clientId: string,
  oldStaffId: string,
  newStaff: string,
  ticket: string,
  token: string,
  notify?: boolean
): Promise<{ status: string }> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL_WHATS || 'https://whats.ipass.com.gt'}/send_ticket`,
      { oldStaffId, newStaff, clientId, ticket, notify },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de inicio de sesión:', error)
    throw error
  }
}

export const ticket_status = async (
  params: FormData,
  token: string
): Promise<{ status: string }> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL_WHATS || 'https://whats.ipass.com.gt'}/ticket_status`,
      params,
      {
        headers: {
          'Content-Type': 'multipart/form-data;',
          Authorization: `Bearer ${token}`
        }
      }
    )

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de inicio de sesión:', error)
    throw error
  }
}

export const sendDiscredit = async (id: string, token: string): Promise<boolean> => {
  try {
    const response = await axios.post(
      `${
        process.env.REACT_APP_API_URL_WHATS || 'https://whats.ipass.com.gt'
      }/sendDiscredit/${id}`,
      undefined,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en desacreditar al trabajador del plan', error)
    throw error
  }
}
