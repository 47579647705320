import React, { FC, useEffect, useState } from 'react'
import { Buffer } from 'buffer'
import { io } from 'socket.io-client'
import { readQrDataFromBase64 } from '../../../utils/readQR'
import { typeQrEnum } from '../../../types/qr'
import { entries } from '../../../types/entries'
import { useAuth } from '../../../Provider/AuthProvider'

import { useDebounce } from '../../../hooks/useDebounce'
import { validate_qr_recepcion } from '../../../services/entries'
import { listcamerasFile, startCamerasLocaFile } from '../../../services/cameras'
import { AccessConfig } from '../../../types/AccessConfig'
import SocketReadQr from './readQr'

const FilesWachs: FC = () => {
  const { user, token } = useAuth()
  const [clientCameras, setclientCameras] = useState<AccessConfig[]>([])
  const [Img64, setImg64] = useState<{ file: string; base64File: string } | undefined>(
    undefined
  )
  const debounceValue = useDebounce(Img64, 1000)

  useEffect(() => {
    if (user?.role !== 'reception' || clientCameras.length === 0) return

    const socketFiles = io('http://localhost:8085') // Socket principal
    const socketQrs: any[] = [] // Almacena las conexiones de socket para cada cámara

    // Cuando se conecta, emitir una acción al servidor
    socketFiles.on('connect', () => {
      console.log('Conectado al servidor localxx')
      startCamerasLocaFile(clientCameras, token)
    })

    clientCameras.forEach(camera => {
      const socketQr = io('http://localhost:8085') // Crear un nuevo socket por cámara

      socketQr.on(`${camera.FileDNI1}`, base64File1 => {
        console.log('entrada base64File por:', camera.FileDNI1)
        setImg64({ base64File: base64File1, file: camera.FileDNI1 })
      })

      socketQr.on(`${camera.FileFace}`, base64File2 => {
        console.log('entrada base64File por:', camera.FileFace)
        setImg64({ base64File: base64File2, file: camera.FileFace })
      })

      // Almacena el socket para poder desconectarlo al desmontar
      socketQrs.push(socketQr)
    })

    // Limpiar los sockets cuando se desmonte el componente
    return () => {
      socketFiles.disconnect() // Desconectar el socket principal
      socketQrs.forEach(socket => socket.disconnect()) // Desconectar todos los sockets de las cámaras
    }
  }, [clientCameras, user, token, setImg64])

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      if (!user?.clientId) return
      const clientCameras = await listcamerasFile(user.clientId)
      setclientCameras(clientCameras)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (debounceValue && debounceValue) {
      // Verificar que sea diferente
      handlerImg(debounceValue).finally(() => {
        setImg64(undefined)
      })
    }
  }, [debounceValue])

  const handlerImg = async (data: { file: string; base64File: string }) => {
    if (!data || !data.base64File) {
      return
    }

    const base64 = data.base64File
    const transform = base64 ? dataUrlToFile(base64, 'captured-image.png') : undefined
    const { buff } = transform ? transform : { buff: undefined }
    console.log('buscando qr EXTERNO')
    const dataQr = buff ? await readQrDataFromBase64(buff) : false
    if (dataQr) {
      await processDataqr(dataQr, data.file)

      setTimeout(() => {
        setImg64(undefined)
      }, 5000)
    }
  }

  const dataUrlToFile = (
    dataUrl: string,
    filename: string
  ): { file: File; buff: Buffer } | undefined => {
    const arr = dataUrl.split(',')
    if (arr.length < 2) {
      return undefined
    }
    const mimeArr = arr[0].match(/:(.*?);/)
    if (!mimeArr || mimeArr.length < 2) {
      return undefined
    }
    const mime = mimeArr[1]
    const buff = Buffer.from(arr[1], 'base64')

    const file = new File([buff], filename, { type: mime })

    return { file, buff }
  }

  const processDataqr = async (dataQr: string, file: string) => {
    if (dataQr) {
      const type = dataQr.slice(0, 4)
      if (Object.values(typeQrEnum).includes(type as typeQrEnum)) {
        const entrie = new entries()
        entrie.qrCode = dataQr
        entrie.cameraIn = file
        const params = {
          camera: file,
          code: dataQr,
          clientID: user?.clientId as string
        }
        try {
          await validate_qr_recepcion({ params, token })
        } catch (error) {
          setImg64(undefined)
        }
      }
    }
  }

  return <>{<SocketReadQr userId={user?.clientId} />}</>
}

export default FilesWachs
