// FormAdministrator.tsx
import React, { FC } from 'react'
import DynamicForm from '../../Tables/Routines/DynamicFormRoutines'
import './styles.scss'
///ipass/IPASS-FRONTEND/src/components/Tables/Routines/DynamicFormRoutine
import { useLoading } from '../../../Provider/LoadingProvider'

import { CheckPointsLocationFormConfig } from './Columns'
import {
  CheckPointsLocationCreate,
  CheckPointsLocation
} from '../../../types/CheckPoints_Location'
import {
  createCheckPointsLocation,
  updateCheckPointsLocation
} from '../../../services/checkPoint'
import { useAuth } from '../../../Provider/AuthProvider'

const FormCheckPointsLocation: FC<{
  initialValue?: CheckPointsLocation
  onSubmit: () => void
}> = ({ onSubmit, initialValue }) => {
  const { startLoading, stopLoading } = useLoading()
  const { user } = useAuth()

  const handleSubmit = async (formData: CheckPointsLocationCreate) => {
    try {
      startLoading()
      const data = new FormData()
      Object.entries({
        name: formData.name,
        address: formData.address,
        photo: formData.photo,
        type: formData.type,
        clientId: user?.clientId as string,
        ...(formData.target && formData.type === 'Seguridad' && { target: formData.target })
      }).forEach(([key, value]) => {
        data.append(key, value)
      })

      if (initialValue) {
        await updateCheckPointsLocation(initialValue.id, data)
      } else {
        await createCheckPointsLocation(data)
      }

      onSubmit()
    } catch (error) {
      console.error('error')
    } finally {
      stopLoading()
    }
  }

  return (
    <div className="newCheckPointsLocationForm">
      <h2>{initialValue ? 'Actualizar Locación' : 'Nueva Locación'}</h2>
      <DynamicForm
        formConfig={CheckPointsLocationFormConfig}
        onSubmit={handleSubmit}
        initialValues={initialValue}
      />
    </div>
  )
}

export default FormCheckPointsLocation
