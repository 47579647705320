// administratorConfig.ts
import { origin } from '../../../types/origin'
import { FieldTypeEnum, FormField } from '../../Form/DynamicForm'
import { TableColumn, enumTypeColumn } from '../../Table'

export const columnsBrand: TableColumn[] = [
  {
    title: 'Foto',
    dataIndex: 'photo',
    key: 'photo',
    type: enumTypeColumn.photo
  },
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    type: enumTypeColumn.string
  },
  {
    title: 'Origen',
    dataIndex: 'originName',
    key: 'originName',
    type: enumTypeColumn.string
  },
  {
    title: 'Creado en',
    dataIndex: 'createdAt',
    key: 'createdAt',
    type: enumTypeColumn.date
  }
]

export const brandFormConfig = (origins: origin[]): FormField[] => {
  return [
    { type: FieldTypeEnum.text, label: 'Nombre', name: 'name' },
    {
      type: FieldTypeEnum.select,
      label: 'Origen',
      name: 'originId',
      options: origins.map(e => ({ label: e.name, value: e.id }))
    },
    { type: FieldTypeEnum.image, label: 'Foto', name: 'photo' }
  ]
}
