import { origin, originCreate } from '../types/origin'
import { Pagination, PaginationQuery } from '../types/types'
import axiosInstance from './axiosInstance'

export const listOriginAll = async (token?: string): Promise<origin[]> => {
  try {
    const headers = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      : {}
    const response = await axiosInstance.get('/api/origins/all', headers)
    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de origen:', error)
    throw error
  }
}

export const listOrigin = async ({
  params
}: {
  params: PaginationQuery
}): Promise<Pagination<origin>> => {
  try {
    const response = await axiosInstance.get('/api/origins', {
      params
    })

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de origenes:', error)
    throw error
  }
}

export const createOrigin = async (params: originCreate): Promise<origin> => {
  try {
    const response = await axiosInstance.post('/api/origins', params)
    return response.data
  } catch (error) {
    console.error('Error en la creación de Origen:', error)
    throw error
  }
}

export const updateOrigin = async (id: string, params: originCreate): Promise<origin> => {
  try {
    const response = await axiosInstance.post(`/api/origins/${id}`, params)
    return response.data
  } catch (error) {
    console.error('Error al actualizar Origen:', error)
    throw error
  }
}

export const deleteOrigin = async (id: string): Promise<void> => {
  try {
    await axiosInstance.delete(`/api/origins/${id}`)
  } catch (error) {
    console.error('Error al eliminar Origin:', error)
    throw error
  }
}
