import React, { useRef, useEffect, useState } from 'react'
import './style.scss'
import { useAuth } from '../../Provider/AuthProvider'
import { GeneralDataCorp, ClientsCorp } from '../../types/dashboard'
import ReactECharts from 'echarts-for-react'

import { getGeneralDataCorp } from '../../services/dashboard'
import { useLoading } from '../../Provider/LoadingProvider'
import Fade from '@mui/material/Fade'
import { LightTooltip } from '../../components/tooltips/text'

import {
  faEarthAmericas,
  faFileLines,
  faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Dashboard = () => {
  const { user } = useAuth()
  const { startLoading, stopLoading } = useLoading()
  const [GeneralData, setGeneralData] = useState<GeneralDataCorp | null>()
  const slideRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (user?.id || user?.clientSelect) {
      getGeneralData(user?.id, `${new Date().getMonth()}`, user?.clientSelect)
    }
  }, [])

  useEffect(() => {
    const slide = slideRef.current
    if (slide && GeneralData?.Clients) {
      const clientItemWidth = 200 // Width of .client-item
      const totalWidth = clientItemWidth * GeneralData?.Clients.length // Duplicated clients
      const containerWidth = slide.parentElement?.offsetWidth || 0

      const width =
        totalWidth < containerWidth ? `${containerWidth}px` : `${totalWidth}px`
      const animation = `${0.03 * parseInt(width)}s scroll infinite linear`

      const clientItems = slide.querySelectorAll('.client-slide')
      clientItems.forEach((item) => {
        ;(item as HTMLElement).style.animation = animation
        ;(item as HTMLElement).style.width = width
      })
    }
  }, [GeneralData, slideRef])

  const getGeneralData = async (id: string, month: string, listId: string) => {
    try {
      startLoading()
      console.log('data')

      const data = await getGeneralDataCorp(id, month, listId)
      setGeneralData(data)
      console.log(data)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ]

  if (!GeneralData)
    return (
      <div className='DashboardContainer'>
        <div className='title_Dashboard'>
          <h4>Dashboard</h4>
        </div>
      </div>
    )

  const ClientChart = ({ client }: { client: ClientsCorp }) => {
    const options = {
      title: {
        text: `${client.name}`,
        subtext: `Mes en curso ${months[new Date().getMonth()]}`,
        left: 'center',
        textStyle: {
          color: '#fff'
        }
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        textStyle: { color: '#fff' },
        data: [
          'Total',
          'Cerrados',
          'Abiertos',
          'En espera',
          'Irrelevantes',
          'Evaluando'
        ],
        left: 'center',
        bottom: '1%'
      },
      xAxis: {
        type: 'category',
        data: ['Reportes']
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: 'Total',
          type: 'bar',
          data: [client.C_total]
        },
        {
          name: 'Cerrados',
          type: 'bar',
          data: [client.C_close]
        },
        {
          name: 'Abiertos',
          type: 'bar',
          data: [client.C_open]
        },
        {
          name: 'En espera',
          type: 'bar',
          data: [client.C_hold]
        },
        {
          name: 'Irrelevantes',
          type: 'bar',
          data: [client.C_spam]
        },
        {
          name: 'Evaluando',
          type: 'bar',
          data: [client.C_Evaluation]
        }
      ]
    }

    return (
      <ReactECharts
        option={options}
        style={{ height: '400px', width: '100%' }}
      />
    )
  }

  return (
    <div className='DashboardContainer'>
      <div className='title_Dashboard'>
        <h4>Dashboard Corporativo </h4>
      </div>
      {GeneralData && (
        <div>
          <div className='infoGeneralContainer'>
            <LightTooltip
              title='Eficiencia Global'
              followCursor
              TransitionComponent={Fade}>
              <li className='generalInf tooltip'>
                <FontAwesomeIcon icon={faEarthAmericas} className='icon' />
                <span>{`${GeneralData?.overall_efficiency}`}%</span>
              </li>
            </LightTooltip>

            <LightTooltip
              title='Eficiencia en reportes '
              followCursor
              TransitionComponent={Fade}>
              <li className='generalInf'>
                <FontAwesomeIcon icon={faFileLines} className='icon' />
                <span>{GeneralData.efficiency_reports}%</span>
              </li>
            </LightTooltip>

            <LightTooltip
              title='Eficiencia en emergencias'
              followCursor
              TransitionComponent={Fade}>
              <li className='generalInf'>
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  className='icon'
                />
                <span>{GeneralData.emergency_efficiency}%</span>
              </li>
            </LightTooltip>
          </div>

          {/* barra lateral inf de los Clientes asignado primera y la otra para efecto */}
          <div className='client-container' ref={slideRef}>
            <div className={`client-slide`}>
              {GeneralData.Clients.map((client, id) => (
                <li key={id} className='client-item'>
                  <LightTooltip
                    title={`${client.name || ''}`}
                    followCursor
                    TransitionComponent={Fade}>
                    <img
                      className='client-photo'
                      src={
                        client.photo !== 'undefined'
                          ? client.photo
                          : '/images/icons/user.png'
                      }
                      alt={client.name || 'User'}
                    />
                  </LightTooltip>

                  <LightTooltip
                    title='Reportes tomados'
                    followCursor
                    TransitionComponent={Fade}>
                    <p className='client-info'>{client.C_total || 0} /</p>
                  </LightTooltip>

                  <LightTooltip
                    title='Reportes resueltos'
                    followCursor
                    TransitionComponent={Fade}>
                    <p className='client-info'>{client.C_close || 0} /</p>
                  </LightTooltip>

                  <LightTooltip
                    title='Eficiencia'
                    followCursor
                    TransitionComponent={Fade}>
                    <p className='client-info'>{client.C_percent || 0}%</p>
                  </LightTooltip>
                </li>
              ))}
            </div>

            {/* barra lateral inf de los Clientes asignado segunda para efecto banner */}

            <div className={`client-slide`}>
              {GeneralData.Clients.map((client, id) => (
                <li key={id} className='client-item'>
                  <LightTooltip
                    title={`${client.name || ''}`}
                    followCursor
                    TransitionComponent={Fade}>
                    <img
                      className='client-photo'
                      src={
                        client.photo !== 'undefined'
                          ? client.photo
                          : '/images/icons/user.png'
                      }
                      alt={client.name || 'User'}
                    />
                  </LightTooltip>

                  <LightTooltip
                    title='Reportes tomados'
                    followCursor
                    TransitionComponent={Fade}>
                    <p className='client-info'>{client.C_total || 0} /</p>
                  </LightTooltip>

                  <LightTooltip
                    title='Reportes resueltos'
                    followCursor
                    TransitionComponent={Fade}>
                    <p className='client-info'>{client.C_close || 0} /</p>
                  </LightTooltip>

                  <LightTooltip
                    title='Eficiencia'
                    followCursor
                    TransitionComponent={Fade}>
                    <p className='client-info'>{client.C_percent || 0}%</p>
                  </LightTooltip>
                </li>
              ))}
            </div>
          </div>

          {/* data gráfica de cada cliente */}
          <div className='graph-container'>
            {/*             <div className="graph">
              <ReactECharts
                option={barChartOptions}
                style={{ height: '400px', width: '100%' }}
              />
            </div> */}
            {GeneralData.Clients.map((client, index) => (
              <div key={index} className='graph'>
                <ClientChart client={client} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Dashboard
