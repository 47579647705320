export enum typeQrEnum {
  'RESIDENT' = '0000',
  'DELEGATE_RESIDENT' = '0001',
  'GUEST' = '0002',
  'VISITOR' = '0003',
  'WORKER' = '0004',
  'STAFF' = '0005',
  'CHECKPOINT' = '0006'
}

export enum typeQrEnumString {
  '0000' = 'Residente',
  '0001' = 'Residente delegado',
  '0002' = 'Invitado',
  '0003' = 'Visitante',
  '0004' = 'Trabajador',
  '0005' = 'Personal',
  '0006' = 'CheckPoint'
}

export enum typeQrEnumAbbreviationString {
  '0000' = 'RP',
  '0001' = 'RD',
  '0002' = 'INV',
  '0003' = 'VIS',
  '0004' = 'TR',
  '0005' = 'STF'
}


export enum typeQrEnumStringCheckPointCentral {
  '0000' = 'RESIDENTE',
  '0001' = 'RESIDENTE D',
  '0002' = 'INVITADO',
  '0003' = 'VISITANTE',
  '0004' = 'TRABAJADOR',
  '0005' = 'PERSONAL',
}
