// DynamicForm.tsx

import React, { useState, useEffect } from 'react'
import InputDark from '../InputDark'
import Select from '../Select'
import ImageUpload from '../ImageUpload'
import './styles.scss'
import Button from '../Button'
import Switch from '../Switch'
import PhoneInput from '../PhoneInput'
import { useAuth } from '../../../Provider/AuthProvider'

export enum FieldTypeEnum {
  'text' = 'text',
  'number' = 'number',
  'phone' = 'phone',
  'select' = 'select',
  'image' = 'image',
  'boolean' = 'boolean',
  'FileMaster' = 'FileMaster'
}

export interface FormField {
  name: string
  label: string
  type: FieldTypeEnum
  options?: SelectOption[]
}

interface SelectOption {
  value: string
  label: string
}

interface DynamicFormProps {
  containtFileMaster?: boolean
  formConfig: FormField[]
  onSubmit: (formData: any) => void
  initialValues?: Record<string, any>
}

const DynamicForm: React.FC<DynamicFormProps> = ({
  formConfig,
  onSubmit,
  initialValues,
  containtFileMaster
}) => {
  const [formData, setFormData] = useState<any>(initialValues ?? {})
  const { user } = useAuth()

  const handleChange = (name: string, value: string | boolean) => {
    setFormData((prevData: any) => ({ ...prevData, [name]: value }))
  }

  let FileMaster: string = ''

  useEffect(() => {
    setFormData(initialValues)

    if (
      !initialValues &&
      containtFileMaster &&
      user?.client?.accessConfigs &&
      user.client.accessConfigs.length > 0 &&
      user.client.accessConfigs[user.client.accessConfigs.length - 1]?.FileMaster
    ) {
      FileMaster = user.client.accessConfigs[user.client.accessConfigs.length - 1]?.FileMaster
      handleChange('FileMaster', FileMaster)
    }
  }, [initialValues])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onSubmit(formData)
  }

  return (
    <form className="formContainer" onSubmit={handleSubmit}>
      <div className="elementsContainer">
        {formConfig.map(field => (
          <div className={`formElement ${field.type}`} key={field.name}>
            {
              {
                [FieldTypeEnum.text]: (
                  <InputDark
                    placeholder={field.label}
                    key={field.name}
                    label={field.label}
                    value={formData ? formData[field?.name] ?? '' : ''}
                    onChange={value => handleChange(field?.name, value.target.value)}
                  />
                ),
                [FieldTypeEnum.FileMaster]: (
                  <InputDark
                    placeholder={field.label}
                    key={field.name}
                    label={field.label}
                    value={formData ? formData[field?.name] ?? FileMaster : FileMaster}
                    onChange={value => handleChange(field?.name, value.target.value)}
                  />
                ),
                [FieldTypeEnum.number]: (
                  <InputDark
                    placeholder={field.label}
                    key={field.name}
                    type="number"
                    label={field.label}
                    value={formData ? formData[field?.name] ?? '' : ''}
                    onChange={value => handleChange(field?.name, value.target.value)}
                  />
                ),
                [FieldTypeEnum.phone]: (
                  <PhoneInput
                    placeholder={field.label}
                    key={field.name}
                    label={field.label}
                    value={formData ? formData[field?.name] ?? '' : ''}
                    onChange={value => handleChange(field?.name, value)}
                  />
                ),
                [FieldTypeEnum.select]: (
                  <Select
                    key={field.name}
                    label={field.label}
                    options={field.options || []}
                    value={formData ? formData[field?.name] ?? '' : ''}
                    onChange={value => handleChange(field?.name, value)}
                  />
                ),
                [FieldTypeEnum.image]: (
                  <ImageUpload
                    label={field.label}
                    key={field.name}
                    value={formData ? formData[field?.name] ?? '' : ''}
                    onChange={(value: any) => handleChange(field.name, value)}
                  />
                ),
                [FieldTypeEnum.boolean]: (
                  <Switch
                    label={field.label}
                    key={field.name}
                    value={formData ? formData[field?.name] ?? false : false}
                    onChange={(value: boolean) => handleChange(field.name, value)}
                  />
                )
              }[field.type]
            }
          </div>
        ))}
      </div>
      <Button
        className="button"
        text={initialValues ? 'Actualizar' : `Enviar`}
        type="submit"
      />
    </form>
  )
}

export default DynamicForm
