import React, { FC } from 'react'
import { useLoading } from '../../../Provider/LoadingProvider'
import {
  createReceptionist,
  updateReceptionist
} from '../../../services/receptionist'
import { Receptionist, ReceptionistCreate } from '../../../types/receptionist'
import DynamicForm from '../../Form/DynamicForm'
import { FormConfig } from './Columns'
import './styles.scss'

const FormReceptionist: FC<{
  onSubmit: () => void
  clientId: string
  initialValue?: Receptionist
}> = ({ onSubmit, initialValue, clientId }) => {
  const { startLoading, stopLoading } = useLoading()

  const handleSubmit = async (formData: ReceptionistCreate) => {
    try {
      startLoading()
      const data = new FormData()
      Object.entries({
        name: formData.name,
        lastName: formData.lastName,
        userName: formData.userName,
        ...(formData.password ? { password: formData.password } : {}),
        photo: formData.photo,
        clientId: clientId,
        canApprove: formData.canApprove?.toString() ?? 'false'
      }).forEach(([key, value]) => {
        data.append(key, value)
      })
      if (initialValue) {
        await updateReceptionist(initialValue.id, data)
      } else {
        await createReceptionist(data)
      }

      onSubmit()
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  return (
    <div className='newClientForm'>
      <h2>
        {initialValue ? 'Actualizar Recepcionista' : 'Nuevo Recepcionista'}
      </h2>
      <DynamicForm
        initialValues={initialValue}
        formConfig={FormConfig}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

export default FormReceptionist
