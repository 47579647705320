// FormAdministrator.tsx
import React, { FC, useEffect, useState } from 'react'
import DynamicForm, { FieldTypeEnum, FormField } from '../../Form/DynamicForm'
import './styles.scss'

import { useLoading } from '../../../Provider/LoadingProvider'

import { residentFormConfig } from './Columns' // Ajusta el nombre del archivo según tu implementación
import { ResidentCreate } from '../../../types/resident'
import { createResident } from '../../../services/resident'
import { Destination } from '../../../types/Destination'
import { listDestinationsAll } from '../../../services/destination'
import { useAuth } from '../../../Provider/AuthProvider'
import {} from '../../../utils/utils'

const FormResident: FC<{ onSubmit: () => void }> = ({ onSubmit }) => {
  const { startLoading, stopLoading } = useLoading()
  const [destinations, setDestinations] = useState<Destination[]>([])
  const { user } = useAuth()
  const handleSubmit = async (formData: ResidentCreate) => {
    try {
      startLoading()
      const dataToSend = {
        ...formData,
        phone1: formData.phone1,
        phone2: formData.phone2,
        clientId: user?.clientId as string
      }
      await createResident(dataToSend)
      onSubmit()
    } catch (error) {
      // Manejo de errores
    } finally {
      stopLoading()
    }
  }
  useEffect(() => {
    getDestination()
  }, [])

  const getDestination = async () => {
    try {
      const destinations = await listDestinationsAll()
      setDestinations(destinations)
    } catch (error) {
      console.error(error)
    }
  }

  const fullResidentFormConfig: FormField[] = [
    ...residentFormConfig,
    {
      type: FieldTypeEnum.select,
      label: 'Tipo de Destino (obligatorio)',
      name: 'destinationId',
      options: destinations.map((e) => ({
        value: e.id,
        label: e.name
      }))
    }
  ]

  return (
    <div className='newResidentForm'>
      <h2>Nuevo Residente</h2>
      <DynamicForm
        formConfig={fullResidentFormConfig}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

export default FormResident
