import React from 'react'
import './style.scss'
const Loading = () => {
  return (
    <div className='loading-overlay'>
      <div className='loading-circle'></div>
    </div>
  )
}

export default Loading
