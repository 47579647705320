import React, { FC } from 'react'
import { useLoading } from '../../../Provider/LoadingProvider'

import DynamicForm from '../../Form/DynamicForm'
import { LocationFormConfig } from './Columns'
import './styles.scss'
import { Location, LocationCreate } from '../../../types/location'
import { createLocation, updateLocation } from '../../../services/location'

const FormLocation: FC<{
  onSubmit: () => void
  clientId: string
  initialValues?: Location
}> = ({ onSubmit, clientId, initialValues }) => {
  const { startLoading, stopLoading } = useLoading()

  const handleSubmit = async (formData: LocationCreate) => {
    try {
      startLoading()
      if (initialValues) {
        await updateLocation(initialValues.id, {
          name: formData.name,
          type: formData.type,
          clientId
        })
      } else {
        await createLocation({
          ...formData,
          clientId
        })
      }

      onSubmit()
    } catch (error) {
      console.error('Error al crear la locación:', error)
    } finally {
      stopLoading()
    }
  }

  return (
    <div className='newLocationForm'>
      <h2>{initialValues ? 'Actualizar locación' : 'Nueva locación'}</h2>
      <DynamicForm
        initialValues={initialValues}
        formConfig={LocationFormConfig}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

export default FormLocation
