import React, { FC, useEffect, useState } from 'react'
import { useLoading } from '../../../Provider/LoadingProvider'
import { Pagination } from '../../../types/types'
import Table from '../../Table'

import VisibilityIcon from '@mui/icons-material/Visibility'
import { IconButton } from '@mui/material'
import {
  deleteEquipment,
  listEquipmentByClient
} from '../../../services/equipment'
import { Equipment } from '../../../types/equipment'
import Modal from '../../GeneralComponents/Modal'
import DeleteButton from '../../Table/components/DeleteButton'
import { convertTypeLocation } from '../Location/Columns'
import { columnsEquipment } from './Columns'
import FormEquipment from './Form'
const EquipmentByClientTable: FC<{ id: string }> = ({ id }) => {
  const [data, setData] = useState<Pagination<Equipment>>({
    currentPage: 1,
    hasNextPage: false,
    hasPrevPage: false,
    total: 1,
    totalPages: 1,
    data: []
  })
  const limit = 6
  const [search, setSearch] = useState('')
  const { startLoading, stopLoading } = useLoading()
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<Equipment | undefined>(undefined)

  useEffect(() => {
    getData('', 1)
  }, [])

  const onCloseModal = () => {
    setOpen(false)
    setSelected(undefined)
  }
  const handleOpenModal = () => {
    setOpen(true)
  }
  const handleDelete = async (id: string) => {
    try {
      startLoading()
      await deleteEquipment(id)
      getData(search.length > 3 ? search : '', data.currentPage)
      stopLoading()
    } catch (error) {
      console.error(error)
    }
  }
  const handleView = (item: Equipment) => {
    setSelected(item)
  }
  const Actions = ({ id, item }: { id: string; item?: Equipment }) => {
    return (
      <>
        <IconButton onClick={() => handleView(item as Equipment)}>
          <VisibilityIcon style={{ color: 'white' }} />
        </IconButton>
        <DeleteButton OnDelete={() => handleDelete(id)} />
      </>
    )
  }
  const handlePageChange = (newPage: number) => {
    getData(search.length >= 3 ? search : '', newPage)
  }
  const getData = async (searchValue: string, page: number) => {
    try {
      startLoading()
      const equipment = await listEquipmentByClient({
        id,
        params: { page, search: searchValue, limit }
      })
      setData({
        ...equipment,
        data: equipment.data.map((e) => ({
          ...e,
          typeShow: convertTypeLocation(e.type as any)
        }))
      })
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }
  const handleSubmit = () => {
    setOpen(false)
    getData(search.length > 3 ? search : '', data.currentPage)
  }

  useEffect(() => {
    if (selected) {
      setOpen(true)
    }
  }, [selected])
  return (
    <>
      <div className='title'>
        <h2>Equipos</h2>
      </div>
      <Table
        onPageChange={handlePageChange}
        onChangeSearch={setSearch}
        pagination={data}
        handleOpenModal={handleOpenModal}
        columns={columnsEquipment}
        Actions={Actions}
        itemsPerPage={limit}
      />
      <Modal
        styleContainer={{ width: '900px' }}
        isOpen={open}
        onClose={onCloseModal}>
        <FormEquipment
          initialValue={selected}
          clientId={id}
          onSubmit={handleSubmit}
        />
      </Modal>
    </>
  )
}

export default EquipmentByClientTable
