import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import { message } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import readXlsxFile from 'read-excel-file'
import { useAuth } from '../../Provider/AuthProvider'
import { useLoading } from '../../Provider/LoadingProvider'
import Button from '../../components/Form/Button'
import FileUpload from '../../components/Form/FileUpload'
import Modal from '../../components/GeneralComponents/Modal'
import Table from '../../components/Table'
import DeleteButton from '../../components/Table/components/DeleteButton'
import { columnsResident } from '../../components/Tables/Resident/Columns'
import { createResidents } from '../../services/resident'
import { Resident } from '../../types/resident'
import { Pagination } from '../../types/types'

const UploadExcel: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { user } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const [valueFile, setValueFile] = useState<File | null>(null)
  const [data, setData] = useState<Resident[]>([])
  const [pagination, setPagination] = useState<Pagination<Resident>>({
    currentPage: 1,
    hasNextPage: false,
    hasPrevPage: false,
    total: 0,
    totalPages: 1,
    data: []
  })
  const { startLoading, stopLoading, showMessage } = useLoading()
  const onCloseModal = () => {
    setIsOpen(false)
  }

  const Actions = ({ id }: { id: string; item: Resident }) => {
    return (
      <div style={{ display: 'flex' }}>
        <DeleteButton OnDelete={() => handleDelete(id)} />
      </div>
    )
  }
  const parsePhoneNumber = (phoneNumber: string | undefined): number => {
    const countryCode = '502'
    if (!phoneNumber) {
      return NaN
    }
    const formattedPhoneNumber = phoneNumber.startsWith(countryCode)
      ? phoneNumber
      : `${countryCode}${phoneNumber}`

    return parseInt(formattedPhoneNumber)
  }

  const handleChangeFile = async (file: File | null) => {
    if (!file) return
    setValueFile(file)
    const rows = await readXlsxFile(file)
    if (rows[0][0] !== 'TIPO DE DESTINO') {
      message.error('No coincide el formato')
    }
    const result = []
    if (rows.length >= 0) {
      for (let k = 1; k < rows.length; k++) {
        result.push({
          id: (k - 1).toString(),
          destinationName: rows[k][0] as string,
          destinationCode: (rows[k][1] as string).toString(),
          name: rows[k][2] as string,
          lastName: rows[k][3] as string,
          phone1: parsePhoneNumber(rows[k][4].toString()),
          phone2: parsePhoneNumber(rows[k][5].toString()),
          email: rows[k][6] as string,
          clientId: user?.clientId as string,
          delegatedQuantity: parseInt(rows[k][7] as string)
        })
      }
    }
    setData(result as any)
  }
  const calculatePagination = (data: Resident[]): Pagination<Resident> => {
    const totalElements = data.length
    const elementsPerPage = 6
    const totalPages = Math.ceil(totalElements / elementsPerPage)

    return {
      currentPage: 1,
      hasNextPage: totalPages > 1,
      hasPrevPage: false,
      total: totalElements,
      totalPages: totalPages,
      data: data.slice(0, elementsPerPage)
    }
  }
  const handleDelete = (id: string) => {
    const newData = pagination.data.filter((resident) => resident.id !== id)
    const newPagination = calculatePagination(newData)
    setPagination(newPagination)
  }

  useEffect(() => {
    if (data.length) {
      setPagination(calculatePagination(data))
    }
  }, [data])

  const handlePageChange = (page: number) => {
    const startIndex = (page - 1) * 6
    const endIndex = startIndex + 6
    setPagination({
      ...pagination,
      currentPage: page,
      hasNextPage: page < pagination.totalPages,
      hasPrevPage: page > 1,
      data: data.slice(startIndex, endIndex)
    })
  }
  const handleCreateResidents = async () => {
    try {
      startLoading()
      await sendMultiple(data)
      showMessage('success', 'Creados con éxito')
      onClose()
    } catch (error) {
      showMessage('error', (error as any)?.response?.data?.message)
    } finally {
      stopLoading()
    }
  }
  async function sendMultiple(residents: Resident[]): Promise<void> {
    const batchSize = 10
    const chunks = residents.reduce<Resident[][]>(
      (resultArray, item, index) => {
        const chunkIndex = Math.floor(index / batchSize)

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []
        }

        resultArray[chunkIndex].push(item)

        return resultArray
      },
      []
    )

    const promises = chunks.map(async (chunk) => {
      try {
        await createResidents({ residents: chunk })
      } catch (error) {
        console.error('Error al enviar chunk:', error)
      }
    })

    try {
      await Promise.all(promises)
      console.info('Todos los residentes han sido enviados')
    } catch (error) {
      console.error('Error al enviar residentes:', error)
    }
  }

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        icon={<CloudUploadOutlinedIcon />}
      />
      <Modal onClose={onCloseModal} isOpen={isOpen}>
        <div className='title'>
          <h2>{`Nuevos residentes`}</h2>

          <FileUpload
            label={'Archivo excel'}
            value={valueFile}
            onChange={handleChangeFile}
          />

          <Table
            onPageChange={handlePageChange}
            pagination={pagination}
            canAdd={false}
            columns={columnsResident}
            Actions={Actions as any}
            itemsPerPage={6}
          />
          <Button onClick={handleCreateResidents} text='Crear' />
        </div>
      </Modal>
    </>
  )
}

export default UploadExcel
