import { FieldTypeEnum, FormField } from '../../Form/DynamicForm'
import { TableColumn, enumTypeColumn } from '../../Table'

export const columnsClient: TableColumn[] = [
  {
    title: 'Foto',
    dataIndex: 'photo',
    key: 'photo',
    type: enumTypeColumn.photo
  },
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    type: enumTypeColumn.string
  },
  {
    title: 'Teléfono',
    dataIndex: 'phone',
    key: 'phone',
    type: enumTypeColumn.string
  },
  {
    title: 'Correo Electrónico',
    dataIndex: 'email',
    key: 'email',
    type: enumTypeColumn.string
  },
  {
    title: 'NIT',
    dataIndex: 'nit',
    key: 'nit',
    type: enumTypeColumn.string
  },
  {
    title: 'Administrador',
    dataIndex: 'administratorName',
    key: 'administratorName',
    type: enumTypeColumn.string
  },
  {
    title: 'Creado en',
    dataIndex: 'createdAt',
    key: 'createdAt',
    type: enumTypeColumn.date
  }
]

export const clientFormConfig: FormField[] = [
  { type: FieldTypeEnum.text, label: 'Nombre', name: 'name' },
  { type: FieldTypeEnum.phone, label: 'Teléfono', name: 'phone' },
  { type: FieldTypeEnum.text, label: 'Correo Electrónico', name: 'email' },
  { type: FieldTypeEnum.text, label: 'NIT', name: 'nit' },
  { type: FieldTypeEnum.text, label: 'Nombre de administrador', name: 'administratorName' },
  { type: FieldTypeEnum.image, label: 'Foto', name: 'photo' }
]
