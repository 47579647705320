import React, { FC, useEffect, useState } from 'react'
import Table from '../../Table'
import { useLoading } from '../../../Provider/LoadingProvider'
import { Pagination } from '../../../types/types'
import { Administrator } from '../../../types/administrators'
import {
  deleteAdministrator,
  listAdministratorClient
} from '../../../services/administrators'
import { columnsAdministrator } from './Columns'
import Modal from '../../GeneralComponents/Modal'
import FormAdministrator from './Form'
import DeleteButton from '../../Table/components/DeleteButton'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { IconButton } from '@mui/material'

const AdministratorByClientTable: FC<{ id: string }> = ({ id }) => {
  const [data, setData] = useState<Pagination<Administrator>>({
    currentPage: 1,
    hasNextPage: false,
    hasPrevPage: false,
    total: 1,
    totalPages: 1,
    data: []
  })
  const limit = 6
  const [search, setSearch] = useState('')
  const { startLoading, stopLoading } = useLoading()
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<Administrator | undefined>(undefined)

  useEffect(() => {
    getDataAdministrators('', 1)
  }, [])

  const onCloseModal = () => {
    setOpen(false)
  }
  const handleOpenModal = () => {
    setOpen(true)
  }
  const handleDelete = async (id: string) => {
    try {
      startLoading()
      await deleteAdministrator(id)
      getDataAdministrators(search.length > 3 ? search : '', data.currentPage)
      stopLoading()
    } catch (error) {
      console.error(error)
    }
  }
  const handleView = (value: Administrator) => {
    setSelected(value)
  }

  const Actions = ({ id, item }: { id: string; item?: Administrator }) => {
    return (
      <>
        <IconButton onClick={() => handleView(item as Administrator)}>
          <VisibilityIcon style={{ color: 'white' }} />
        </IconButton>
        <DeleteButton OnDelete={() => handleDelete(id)} />
      </>
    )
  }
  const handlePageChange = (newPage: number) => {
    getDataAdministrators(search.length >= 3 ? search : '', newPage)
  }
  const getDataAdministrators = async (searchValue: string, page: number) => {
    try {
      startLoading()
      const administrators = await listAdministratorClient({
        id,
        params: { page, search: searchValue, limit }
      })
      setData(administrators)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }
  const handleSubmit = () => {
    setOpen(false)
    getDataAdministrators(search.length > 3 ? search : '', data.currentPage)
  }

  useEffect(() => {
    if (selected) {
      setOpen(true)
    }
  }, [selected])

  return (
    <>
      <div className='title'>
        <h2>Administradores</h2>
      </div>
      <Table
        onPageChange={handlePageChange}
        onChangeSearch={setSearch}
        pagination={data}
        handleOpenModal={handleOpenModal}
        columns={columnsAdministrator}
        Actions={Actions}
        itemsPerPage={limit}
      />
      <Modal
        styleContainer={{ width: '900px' }}
        isOpen={open}
        onClose={onCloseModal}>
        <FormAdministrator
          initialValue={selected}
          clientId={id}
          onSubmit={handleSubmit}
        />
      </Modal>
    </>
  )
}

export default AdministratorByClientTable
