// FormSuperAdmin.tsx
import React, { FC } from 'react'
import DynamicForm from '../../Form/DynamicForm'
import './styles.scss'

import { useLoading } from '../../../Provider/LoadingProvider'
import {
  createDestination,
  updateDestination
} from '../../../services/destination'

import { destinationFormConfig } from './Columns'
import { Destination, DestinationCreate } from '../../../types/Destination'

const FormDestination: FC<{
  onSubmit: () => void
  initialValue?: Destination
}> = ({ onSubmit, initialValue }) => {
  const { startLoading, stopLoading } = useLoading()

  const handleSubmit = async (formData: DestinationCreate) => {
    try {
      const data = {
        name: formData.name
      }
      startLoading()
      if (initialValue) {
        await updateDestination(initialValue.id, data)
      } else {
        await createDestination(data)
      }

      onSubmit()
    } catch (error) {
      // Manejo de errores
    } finally {
      stopLoading()
    }
  }

  return (
    <div className='newDestinationForm'>
      <h2>Nuevo Destino</h2>
      <DynamicForm
        initialValues={initialValue}
        formConfig={destinationFormConfig}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

export default FormDestination
