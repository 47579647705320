import React, { useEffect, useState } from 'react'
import './styles.scss'

import { detailCheckReport, DashCheckReport } from '../../types/dashboard'
import { useLoading } from '../../Provider/LoadingProvider'
import { getLogsCheckPoint } from '../../services/dashboard'
import PhotoTooltip from '../../components/tooltips/img'

export interface ReportModalProps {
  PreReport: DashCheckReport
  ClientName: string
}

const ReportModal: React.FC<ReportModalProps> = ({ PreReport, ClientName }) => {
  const [report, setReport] = useState<detailCheckReport | null>(null)
  const { startLoading, stopLoading } = useLoading()

  useEffect(() => {
    getTicketData(PreReport.id)
  }, [PreReport])

  const getTicketData = async (_id: string) => {
    try {
      startLoading()
      const data = await getLogsCheckPoint(PreReport.id)
      setReport(data)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  if (!report) return <div className="modal">.</div>

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h2>Historial de acciones de {PreReport.typeRoutine}</h2>
          <p>
            <span>CheckPoint en {ClientName}</span>
          </p>
        </div>

        <div className="CheckPoint-header">
          <div className={`photoStaff`}>
            <PhotoTooltip
              photos={[PreReport.photoStaff]}
              altText={PreReport.nameStaff}
              altPhoto="/images/Staff.png"
              className="checkPoint-photo"
            />
          </div>

          <div className="CheckPoint-dataHeader">
            <div>
              <span>{PreReport.nameStaff} </span>
            </div>
            <div>{PreReport.typeRoutine}</div>
            <div>{PreReport.lastUpdateTime}</div>

            {PreReport.status === 'inProgress' && (
              <div>
                Esta en:
                <span>{PreReport.namePlan}</span>
              </div>
            )}
            {PreReport.status !== 'inProgress' && (
              <div>
                <span>Acciones finalizadas</span>
              </div>
            )}

            <div>
              <span> Acciones de {PreReport.typeRoutine}: </span>
              {PreReport.T_actions}
            </div>

            <div>
              <span>Tiempo ejecución: </span> {PreReport.recordReport}
            </div>

            <div>
              <span> Tiempo fuera: </span>
              {PreReport.C_TOut || 0} / {PreReport.C_OutMS || 0}
            </div>
          </div>
        </div>

        {report.logs && (
          <div className="logs-timeline">
            {report.logs.map((log, index) => (
              <div key={index} className="log-entry">
                <div className="textRight">
                  <div>
                    <span>Acción :</span>
                    {log.name}
                  </div>
                  <div>{log.address}</div>
                </div>
                <div className="separate">
                  {
                    <div
                      className={`${
                        index === -1 || index + 1 === report.logs?.length
                          ? 'separateTicketPhotoLast'
                          : 'separateTicketPhoto'
                      }`}
                    >
                      <PhotoTooltip
                        photos={[log.photo ? log.photo : '']}
                        altText={report.id}
                        altPhoto={`${
                          log.description === 'Inicio'
                            ? '/images/Start.png'
                            : log.description === 'finalized'
                            ? '/images/fin_checkPoint.png'
                            : log.hour
                            ? '/images/linear.png'
                            : '/images/No_Data.png'
                        }`}
                        className="ticket-photo"
                      />
                    </div>
                  }
                </div>
                {log.hour ? (
                  <div className="dataLogs">
                    <span
                      className={`${log.recordMS && log.recordMS > 900000 ? 'red' : 'green'}`}
                    >
                      {log.record}
                    </span>
                    <br /> <span>{log.hour}</span>
                  </div>
                ) : (
                  <div className="dataLogs">NO REALIZADA AÚN</div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default ReportModal
