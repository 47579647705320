// src/pages/OriginsPage.tsx
import React, { useEffect, useState } from 'react'

import { columnsOrigin } from '../../components/Tables/Origin/Columns'

import { useLoading } from '../../Provider/LoadingProvider'
import Modal from '../../components/GeneralComponents/Modal'
import Table from '../../components/Table'
import DeleteButton from '../../components/Table/components/DeleteButton'
import FormOrigin from '../../components/Tables/Origin/Form'

import { deleteOrigin, listOrigin } from '../../services/origin'
import { origin } from '../../types/origin'
import { Pagination } from '../../types/types'
import './style.scss'
import { IconButton } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'

const OriginsPage = () => {
  const [data, setData] = useState<Pagination<origin>>({
    currentPage: 1,
    hasNextPage: false,
    hasPrevPage: false,
    total: 1,
    totalPages: 1,
    data: []
  })
  const limit = 6
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [search, setSearch] = useState('')
  const [filteredBySearch, setFilteredBySearch] = useState(false)
  const { startLoading, stopLoading } = useLoading()
  const [selected, setSelected] = useState<origin | undefined>(undefined)

  useEffect(() => {
    getData(search.length > 3 ? search : '', data.currentPage)
  }, [])

  useEffect(() => {
    if (search.length >= 3) {
      setFilteredBySearch(true)
      getData(search, 1)
    } else if (filteredBySearch) {
      setFilteredBySearch(false)
      getData('', 1)
    }
  }, [search])

  useEffect(() => {
    getData(search, data.currentPage)
  }, [data.currentPage])

  const onCloseModal = () => {
    setIsOpenModal(false)
  }

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const getData = async (searchValue: string, page: number) => {
    try {
      startLoading()
      const origins = await listOrigin({
        params: { page, search: searchValue, limit }
      })
      setData(origins)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const handleSubmit = async () => {
    try {
      startLoading()
      setIsOpenModal(false)
      await getData(search.length > 3 ? search : '', data.currentPage)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const handlePageChange = (newPage: number) => {
    getData(search.length >= 3 ? search : '', newPage)
  }

  const handleDelete = async (id: string) => {
    try {
      startLoading()
      await deleteOrigin(id)
      await getData(search.length > 3 ? search : '', data.currentPage)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }
  const handleView = (id: origin) => {
    setSelected(id)
  }
  const Actions = ({ id, item }: { id: string; item?: origin }) => {
    return (
      <>
        <IconButton onClick={() => handleView(item as origin)}>
          <VisibilityIcon style={{ color: 'white' }} />
        </IconButton>
        <DeleteButton OnDelete={() => handleDelete(id)} />
      </>
    )
  }
  useEffect(() => {
    if (selected) {
      setIsOpenModal(true)
    }
  }, [selected])

  return (
    <>
      <div className='originContainer'>
        <div className='title'>
          <p>Origen</p>
        </div>
        <div className='tableContainer'>
          <Table
            onPageChange={handlePageChange}
            onChangeSearch={setSearch}
            pagination={data}
            handleOpenModal={handleOpenModal}
            columns={columnsOrigin}
            Actions={Actions}
            itemsPerPage={limit}
          />
        </div>
      </div>
      <Modal isOpen={isOpenModal} onClose={onCloseModal}>
        <FormOrigin initialValue={selected} onSubmit={handleSubmit} />
      </Modal>
    </>
  )
}

export default OriginsPage
