import React, { FC, useEffect, useRef, useState } from 'react'
import { io } from 'socket.io-client'
import Icon from '@ant-design/icons'
import { NotificationPlacement } from 'antd/es/notification/interface'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { ReactComponent as waitInLobby } from '../../../assets/svg/waitInLobby.svg'
import { ReactComponent as leaveInLobby } from '../../../assets/svg/leaveInLobby.svg'
import { ReactComponent as noLeaveInLobby } from '../../../assets/svg/noLeaveInLobby.svg'
import { entries } from '../../../types/entries'
import { ConfigProvider, notification } from 'antd'
import { brand } from '../../../types/brand'

type NotificationsProps = {
  userId: string | undefined
  brands: brand[]
  setNewEntrySocket?: (entry: entries) => void
}

const SocketNotifications: FC<NotificationsProps> = ({
  userId,
  setNewEntrySocket,
  brands
}) => {
  const [api, contextHolder] = notification.useNotification({ stack: { threshold: 1 } })
  const DEBE_DEJAR_PAQUETE = useRef<HTMLAudioElement>(null)
  const INGRESO_AUTORIZADO = useRef<HTMLAudioElement>(null)
  const NO_ESPERA_PAQUETERÍA = useRef<HTMLAudioElement>(null)
  const NO_ESPERA_VISITA = useRef<HTMLAudioElement>(null)
  const VISITANTE_DEBE_ESPERAR = useRef<HTMLAudioElement>(null)
  const [allbrands, setAllbrands] = useState<brand[]>([])
  const [userInteracted, setUserInteracted] = useState(false)

  useEffect(() => {
    setAllbrands(brands)
  }, [brands])

  useEffect(() => {
    const handleUserInteraction = () => {
      setUserInteracted(true)
      document.removeEventListener('click', handleUserInteraction)
      document.removeEventListener('keydown', handleUserInteraction)
    }

    document.addEventListener('click', handleUserInteraction)
    document.addEventListener('keydown', handleUserInteraction)

    const socketWhats = io(
      process.env.REACT_APP_API_URL_WHATS || ('https://whats.ipass.com.gt' as string)
    )
    socketWhats.on(`notification-${userId}`, ({ entry }: { entry: entries }) => {
      if (!entry.approved) return
      if (setNewEntrySocket) setNewEntrySocket(entry)
      const brandPhoto =
        allbrands.find(e => e.id === entry?.brandId)?.photo ||
        allbrands.find(e => e.name === 'VISITA')?.photo

      const statusSound: {
        [key: string]: React.RefObject<HTMLAudioElement>
      } = {
        APPROVED: INGRESO_AUTORIZADO,
        REJECT: NO_ESPERA_VISITA,
        RECESSION: VISITANTE_DEBE_ESPERAR,
        LEAVE: DEBE_DEJAR_PAQUETE,
        NO_LEAVE: NO_ESPERA_PAQUETERÍA
      }

      const positionsStylesSvg: React.CSSProperties | undefined = {
        position: 'fixed',
        fontSize: '40px',
        bottom: '15%',
        left: '12px'
      }

      const positionsStylesImg: React.CSSProperties | undefined = {
        position: 'fixed',
        height: '40px',
        width: '40px',
        bottom: '40%',
        left: '12px',
        margin: '0px'
      }

      const statusText: {
        [key: string]: {
          message: React.JSX.Element
          icon: React.JSX.Element
        }
      } = {
        APPROVED: {
          message: (
            <span>
              <strong> {entry.cameraOut ? entry.cameraOut : entry.cameraIn}</strong>
              {<br />} <strong>{entry.destination}</strong> INDICA: {<br />}
              <strong>
                {entry.name} {entry.lastName ? `${entry.lastName}` : ''}
                <br /> {entry.brand}
                {<br />}INGRESO AUTORIZADO
              </strong>
            </span>
          ),

          icon: (
            <>
              <img src={brandPhoto} alt="brandPhoto" style={positionsStylesImg} />
              <CheckCircleOutlined
                style={{
                  color: 'green',
                  ...positionsStylesSvg
                }}
              />
            </>
          )
        },

        REJECT: {
          message: (
            <span>
              <strong> {entry.cameraOut ? entry.cameraOut : entry.cameraIn}</strong>
              {<br />} <strong>{entry.destination}</strong> INDICA: {<br />}
              <strong>
                {entry.name} {entry.lastName ? `${entry.lastName}` : ''}
                <br /> {entry.brand}
                {<br />}NO ESPERO VISITA
              </strong>
            </span>
          ),

          icon: (
            <>
              <img src={brandPhoto} alt="brandPhoto" style={positionsStylesImg} />{' '}
              <CloseCircleOutlined
                style={{
                  color: 'red',
                  ...positionsStylesSvg
                }}
              />
            </>
          )
        },

        RECESSION: {
          message: (
            <span>
              <strong> {entry.cameraOut ? entry.cameraOut : entry.cameraIn}</strong>
              {<br />} <strong>{entry.destination}</strong> INDICA: {<br />}
              <strong>
                {entry.name} {entry.lastName ? `${entry.lastName}` : ''}
                <br /> {entry.brand}
                {<br />}ESPERAR EN RECEPCION
              </strong>
            </span>
          ),

          icon: (
            <>
              <img src={brandPhoto} alt="brandPhoto" style={positionsStylesImg} />
              <Icon
                component={waitInLobby}
                style={{
                  ...positionsStylesSvg
                }}
              />
            </>
          )
        },

        LEAVE: {
          message: (
            <span>
              <strong> {entry.cameraOut ? entry.cameraOut : entry.cameraIn}</strong>
              {<br />} <strong>{entry.destination}</strong> INDICA: {<br />}
              <strong>
                {entry.name} {entry.lastName ? `${entry.lastName}` : ''}
                <br /> {entry.brand}
                {<br />}DEJAR PAQUETERIA
              </strong>
            </span>
          ),

          icon: (
            <>
              <Icon
                component={leaveInLobby}
                style={{
                  ...positionsStylesSvg
                }}
              />

              <img src={brandPhoto} alt="brandPhoto" style={positionsStylesImg} />
            </>
          )
        },

        NO_LEAVE: {
          message: (
            <span>
              <strong> {entry.cameraOut ? entry.cameraOut : entry.cameraIn}</strong>
              {<br />} <strong>{entry.destination}</strong> INDICA: {<br />}
              <strong>
                {entry.name} {entry.lastName ? `${entry.lastName}` : ''}
                <br /> {entry.brand}
                {<br />}NO ESPERO PAQUETERIA
              </strong>
            </span>
          ),

          icon: (
            <>
              <Icon
                component={noLeaveInLobby}
                style={{
                  ...positionsStylesSvg
                }}
              />
              <img src={brandPhoto} alt="brandPhoto" style={positionsStylesImg} />
            </>
          )
        }
      }

      const soundTrack = statusSound[entry.approved]
      api.open({
        ...statusText[entry.approved],
        showProgress: true,
        pauseOnHover: true,
        duration: 10,
        placement: 'topRight' as NotificationPlacement
      })

      if (soundTrack.current && userInteracted) {
        soundTrack.current.play()
      }
    })

    return () => {
      socketWhats.disconnect()
    }
  }, [userId, setNewEntrySocket, userInteracted])

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorInfo: '#5b1fca',
            colorPrimary: '#5b1fca',
            colorInfoText: '5b1fca',
            colorLink: '5b1fca',
            colorPrimaryText: '5b1fca'
          }
        }}
      >
        {contextHolder}
      </ConfigProvider>
      <audio ref={DEBE_DEJAR_PAQUETE} src="sounds/statusCheckIn/DEBE_DEJAR_PAQUETE.mp3" />
      <audio ref={INGRESO_AUTORIZADO} src="sounds/statusCheckIn/INGRESO_AUTORIZADO.mp3" />
      <audio ref={NO_ESPERA_PAQUETERÍA} src="sounds/statusCheckIn/NO_ESPERA_PAQUETERÍA.mp3" />
      <audio ref={NO_ESPERA_VISITA} src="sounds/statusCheckIn/NO_ESPERA_VISITA.mp3" />
      <audio
        ref={VISITANTE_DEBE_ESPERAR}
        src="sounds/statusCheckIn/VISITANTE_DEBE_ESPERAR.mp3"
      />
    </>
  )
}

export default SocketNotifications
