import React, { FC } from 'react'
import { Task } from './index'
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable'
import { useDroppable } from '@dnd-kit/core'
import { SortableItem } from './SortableItem'

interface Props {
  tasks: Task[]
  list: string
  width?: string
}

export const DropZone: FC<Props> = ({ tasks, list }) => {
  const { setNodeRef } = useDroppable({ id: list })

  const filteredTasks = tasks.filter((task) => task.list === list)

  return (
    <SortableContext items={filteredTasks} strategy={rectSortingStrategy}>
      <div className='containerColumnDragAndDrop'>
        <label className='headingDragAndDrop'>
          {list === 'Locaciones'
            ? 'Locaciones disponibles'
            : 'Locaciones asignadas'}
        </label>
        <div className='columnDragAndDrop' ref={setNodeRef}>
          {filteredTasks.length === 0 ? (
            <div className='emptyDropZone'></div>
          ) : (
            filteredTasks.map((task) => (
              <SortableItem key={task.id} task={task} />
            ))
          )}
        </div>
      </div>
    </SortableContext>
  )
}
