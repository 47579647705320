import React from 'react'
import './App.scss'
import { AuthProvider } from './Provider/AuthProvider'
import { CameraProvider } from './Provider/CameraProvider'
import { LoadingProvider } from './Provider/LoadingProvider'
import AppRouter from './pages/router'
function App({ isCheckIn }: { isCheckIn: boolean }) {
  return (
    <LoadingProvider>
      <AuthProvider>
        <CameraProvider>
          <AppRouter isCheckIn={isCheckIn} />
        </CameraProvider>
      </AuthProvider>
    </LoadingProvider>
  )
}

export default App
