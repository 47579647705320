// FormreSuperAdmin.tsx
import React, { FC, useEffect, useState } from 'react'
import DynamicForm from '../../Form/DynamicForm'
import './styles.scss'

import { useLoading } from '../../../Provider/LoadingProvider'

import { createBrand, updateBrand } from '../../../services/brand'
import { brand, brandCreate } from '../../../types/brand'
import { brandFormConfig } from './Columns'
import { origin } from '../../../types/origin'
import { listOriginAll } from '../../../services/origin'

const FormBrand: FC<{ onSubmit: () => void; initialValue?: brand }> = ({
  onSubmit,
  initialValue
}) => {
  const { startLoading, stopLoading } = useLoading()
  const [origins, setOrigins] = useState<origin[]>([])

  const getData = async () => {
    const origins = await listOriginAll()
    setOrigins(origins)
  }

  const handleSubmit = async (formData: brandCreate) => {
    try {
      startLoading()
      const data = new FormData()
      Object.entries({
        name: formData.name,
        originId: formData.originId,
        photo: formData.photo
      }).forEach(([key, value]) => {
        data.append(key, value)
      })
      if (initialValue) {
        await updateBrand(initialValue.id, data)
      } else {
        await createBrand(data)
      }
      onSubmit()
    } catch (error) {
      // Manejo de errores
    } finally {
      stopLoading()
    }
  }
  useEffect(() => {
    getData()
  }, [])

  return (
    <div className="newDestinationForm">
      <h2>{initialValue ? 'Actualizar marca' : 'Nueva marca'}</h2>
      <DynamicForm
        initialValues={initialValue}
        formConfig={brandFormConfig(origins)}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

export default FormBrand
