// FormAdministrator.tsx
import React, { FC, useEffect, useState } from 'react'
import { useLoading } from '../../../Provider/LoadingProvider'
import { createEquipment, updateEquipment } from '../../../services/equipment'
import { Equipment, EquipmentCreate } from '../../../types/equipment'
import DynamicForm, { FieldTypeEnum } from '../../Form/DynamicForm'
import { equipmentFormConfig } from './Columns'
import './styles.scss'
import { Location } from '../../../types/location'
import { listLocations } from '../../../services/location'
import CustomMessage from '../../CustomMessage'

const FormEquipment: FC<{
  onSubmit: () => void
  clientId: string
  initialValue?: Equipment
}> = ({ onSubmit, clientId, initialValue }) => {
  const { startLoading, stopLoading } = useLoading()
  const [locations, setLocations] = useState<Location[]>([])
  const [visible, setVisible] = useState(false)
  const [message, setMessage] = useState('')
  useEffect(() => {
    getData()
  }, [])
  const getData = async () => {
    try {
      const locations = await listLocations({ id: clientId })
      setLocations(locations)
    } catch (error) {
      console.error(error)
    }
  }
  const handleSubmit = async (formData: EquipmentCreate) => {
    try {
      startLoading()
      if (initialValue) {
        await updateEquipment(initialValue.id, {
          name: formData.name,
          type: formData.type,
          serial: formData.serial,
          locationId: formData.locationId,
          clientId
        })
      } else {
        await createEquipment({
          ...formData,
          clientId
        })
      }

      onSubmit()
    } catch (error: any) {
      setMessage(error.error)
      setVisible(true)
    } finally {
      stopLoading()
    }
  }
  const FormConfigWithLocation = [
    ...equipmentFormConfig,
    {
      type: FieldTypeEnum.select,
      label: 'Locación',
      name: 'locationId',
      options: locations.map((e) => ({ value: e.id, label: e.name }))
    }
  ]

  return (
    <div className='newEquipmentForm'>
      <div className='title'>
        <h2>{initialValue ? 'Actualizar equipo' : 'Nuevo equipo'}</h2>
      </div>

      <DynamicForm
        initialValues={initialValue}
        formConfig={FormConfigWithLocation}
        onSubmit={handleSubmit}
      />
      <CustomMessage
        type='error'
        onClose={() => {
          setMessage('')
          setVisible(false)
        }}
        message={message}
        visible={visible}
      />
    </div>
  )
}

export default FormEquipment
