// administratorConfig.ts
import { FieldTypeEnum, FormField } from '../../Form/DynamicForm'
import { TableColumn, enumTypeColumn } from '../../Table'

export const columnsOrigin: TableColumn[] = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    type: enumTypeColumn.string
  },
  {
    title: 'Creado en',
    dataIndex: 'createdAt',
    key: 'createdAt',
    type: enumTypeColumn.date
  }
]

export const originFormConfig: FormField[] = [
  { type: FieldTypeEnum.text, label: 'Nombre', name: 'name' }
]
