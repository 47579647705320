import { details, GeneralPayment, PaymentDetails } from '../types/Payments'
import { PaymentConcept, statement } from '../types/Payments'
import { Pagination, PaginationQuery } from '../types/types'
import axiosInstance from './axiosInstance'

export const listPaymentConcept = async ({
  id,
  params
}: {
  id: string
  params: PaginationQuery
}): Promise<Pagination<PaymentConcept>> => {
  try {
    const response = await axiosInstance.get(`/api/payments/${id}`, {
      params
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de inicio de sesión:', error)
    throw error
  }
}

export const createPaymentConcept = async (params: FormData): Promise<PaymentConcept> => {
  try {
    const response = await axiosInstance.post(`/api/payments`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de inicio de sesión:', error)
    throw error
  }
}

export const updatePaymentConcept = async (
  id: string,
  params: FormData
): Promise<PaymentConcept> => {
  try {
    const response = await axiosInstance.post(`/api/payments/${id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de inicio de sesión:', error)
    throw error
  }
}

export const setGlobalConcepts = async (id: string): Promise<PaymentConcept> => {
  try {
    const response = await axiosInstance.post(`/api/payments/setglobal/${id}`)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de inicio de sesión:', error)
    throw error
  }
}

export const deletePaymentConcept = async (id: string): Promise<void> => {
  try {
    const response = await axiosInstance.delete(`/api/payments/${id}`)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de inicio de sesión:', error)
    throw error
  }
}

export const getGeneralPaymentByClientId = async (id: string): Promise<GeneralPayment> => {
  try {
    const response = await axiosInstance.get(`/api/payments/dashboard/${id}`)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('error dashboard payment Get', error)
    throw error
  }
}

export const getstatement = async (id: string, clientId: string): Promise<statement> => {
  try {
    const response = await axiosInstance.get(`/api/payments/getstatement/${id}/${clientId}`)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('error getstatement payment Get', error)
    throw error
  }
}

export const getPaymentDetail = async (PaymentId: string): Promise<PaymentDetails> => {
  try {
    const response = await axiosInstance.get(`/api/payments/getPaymentDetail/${PaymentId}`)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('error dashboard payment Get', error)
    throw error
  }
}

export const processPayment = async (
  paymentId: string,
  type: 'approve' | 'partially' | 'decline',
  details: details[]
): Promise<PaymentConcept> => {
  try {
    const response = await axiosInstance.post(
      `/api/payments/processPayment/${type}/${paymentId}`,
      JSON.stringify({ details })
    )

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de inicio de sesión:', error)
    throw error
  }
}

export const getStatementPdf = async (id: string): Promise<string> => {
  try {
    const response = await axiosInstance.get(`/pdf_payment/${id}`)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('error getstatement payment Get', error)
    throw error
  }
}
