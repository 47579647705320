import React, { useEffect, useRef } from 'react'

interface ClientBannerProps {
  items: any[] // Puedes especificar el tipo de los items si es necesario
  renderItem: (item: any, index: number) => React.ReactNode
  containerClassName?: string // Para permitir personalizar el contenedor
  slideClassName?: string // Para personalizar la clase de la diapositiva
  itemClassName?: string // Para personalizar la clase de cada item
}

const ClientBanner: React.FC<ClientBannerProps> = ({
  items,
  renderItem,
  containerClassName = 'client-container',
  slideClassName = 'client-slide',
  itemClassName = 'client-item'
}) => {
  const slideRef = useRef<HTMLDivElement | null>(null)

  const calculateBannerWidth = () => {
    const slide = slideRef.current
    if (slide && items.length > 0) {
      const clientItems = slide.querySelectorAll(`.${itemClassName}`)
      const firstClientItem = clientItems[0] as HTMLElement

      // Obtener el ancho dinámico del primer '.client-item'
      const clientItemWidth = firstClientItem ? firstClientItem.offsetWidth : 200

      // Calcular el ancho total y la animación
      const totalWidth = clientItemWidth * items.length
      const containerWidth = slide.parentElement?.offsetWidth || 0

      const width = totalWidth < containerWidth ? `${containerWidth}px` : `${totalWidth}px`
      const animation = `${0.03 * parseInt(width)}s scroll infinite linear`
      const clientSlides = slide.querySelectorAll(`.${slideClassName}`)

      clientSlides.forEach(item => {
        ;(item as HTMLElement).style.animation = animation
        ;(item as HTMLElement).style.width = width
      })
    }
  }

  useEffect(() => {
    // Calcular el ancho al montar el componente

    calculateBannerWidth()

    window.addEventListener('resize', calculateBannerWidth)

    // Limpiar el listener cuando el componente se desmonte
    return () => {
      window.removeEventListener('resize', calculateBannerWidth)
    }
  }, [items, itemClassName, slideClassName])

  return (
    <div className={containerClassName} ref={slideRef}>
      <div className={slideClassName}>
        {items.map((item, index) => (
          <li key={index} className={itemClassName}>
            {renderItem(item, index)}
          </li>
        ))}
      </div>

      {/* Segunda fila para el banner repetido */}
      <div className={slideClassName}>
        {items.map((item, index) => (
          <li key={index} className={itemClassName}>
            {renderItem(item, index)}
          </li>
        ))}
      </div>
    </div>
  )
}

export default ClientBanner
