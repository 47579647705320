// administratorConfig.ts
import { FieldTypeEnum, FormField } from '../../Form/DynamicForm'
import { TableColumn, enumTypeColumn } from '../../Table'

export const columnsResident: TableColumn[] = [
  {
    title: 'Tipo de Destino',
    dataIndex: 'destinationName',
    key: 'destinationName',
    type: enumTypeColumn.string
  },
  {
    title: 'Código Destino',
    dataIndex: 'destinationCode',
    key: 'destinationCode',
    type: enumTypeColumn.string
  },
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    type: enumTypeColumn.string
  },
  {
    title: 'Apellido',
    dataIndex: 'lastName',
    key: 'lastName',
    type: enumTypeColumn.string
  },
  {
    title: 'Teléfono 1',
    dataIndex: 'phone1',
    key: 'phone1',
    type: enumTypeColumn.string
  }
]

export const residentFormConfig: FormField[] = [
  { type: FieldTypeEnum.text, label: 'Código Destino (obligatorio)', name: 'destinationCode' },
  { type: FieldTypeEnum.text, label: 'Primer Nombre (obligatorio)', name: 'name' },
  { type: FieldTypeEnum.text, label: 'Apellido (obligatorio)', name: 'lastName' },
  { type: FieldTypeEnum.phone, label: 'Teléfono 1 (obligatorio)', name: 'phone1' },
  { type: FieldTypeEnum.phone, label: 'Teléfono 2', name: 'phone2' },
  { type: FieldTypeEnum.text, label: 'Correo Electrónico', name: 'email' },
  { type: FieldTypeEnum.number, label: 'Delegados', name: 'delegatedQuantity' }
]
