// Modal.tsx
import React, { FC, ReactNode } from 'react'
import { createPortal } from 'react-dom'
import './style.scss'

type ModalProps = {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
  styleContainer?: React.CSSProperties
  styleContainerContent?: React.CSSProperties
}

const Modal: FC<ModalProps> = ({
  isOpen,
  styleContainerContent,
  onClose,
  children,
  styleContainer
}) => {
  return isOpen
    ? createPortal(
        <div className='modal-overlay'>
          <div style={styleContainer} className='modal'>
            <div style={styleContainerContent} className='content'>
              <div className='subContent'>{children}</div>
            </div>
            <button className='close-button' onClick={onClose}>
              X
            </button>
          </div>
        </div>,
        document.body
      )
    : null
}

export default Modal
