import React, { useState, useEffect } from 'react'
import { DndContext, closestCorners, DragEndEvent } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import './styles.css'

import { DropZone } from './DropZone'
import { LocationsItems } from '../../types/routines'

export interface Task {
  id: string
  title: string
  address: string
  list: string
  type: string
}

const DragAndDrop: React.FC<{
  search: string
  defaultSearch: string
  dataSelect: LocationsItems[]
  onChange: (tasks: LocationsItems[]) => void
}> = ({ dataSelect, onChange, search, defaultSearch = '' }) => {
  const [tasks, setTasks] = useState<LocationsItems[]>([])

  useEffect(() => {
    setTasks(dataSelect)
  }, [dataSelect])

  useEffect(() => {
    if (!tasks || !dataSelect) return

    // Separar tareas de "Orden rutina"
    const ordenRutina = tasks.filter(task => task.list === 'Orden rutina')

    // Filtrar las tareas de "Locaciones", excluyendo las que ya están en "Orden rutina"
    const locaciones = dataSelect
      .filter(task => !ordenRutina.some(rutinaTask => rutinaTask.id === task.id))
      .map(task => ({ ...task, list: 'Locaciones' })) // Cambiar la lista a "Locaciones" si no está en "Orden rutina"

    // Dividir el término de búsqueda en palabras individuales
    const searchWords = search.toLowerCase().split(' ')
    if (defaultSearch) searchWords.push(defaultSearch.toLowerCase())
    // Aplicar el filtro de búsqueda a las "Locaciones"
    const filteredLocaciones = locaciones.filter(task =>
      searchWords.every(
        word =>
          task.title.toLowerCase().includes(word) ||
          task.address.toLowerCase().includes(word) ||
          task.type.toLowerCase().includes(word)
      )
    )

    // Combinar las listas y actualizar el estado
    setTasks([...ordenRutina, ...filteredLocaciones])
  }, [search,defaultSearch])

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (!over) return

    const activeTask = tasks.find(task => task.id === active.id)
    const overTask = tasks.find(task => task.id === over.id)

    if (activeTask && overTask && activeTask.list === overTask.list) {
      const oldIndex = tasks.findIndex(task => task.id === activeTask.id)
      const newIndex = tasks.findIndex(task => task.id === overTask.id)
      const newTasks = arrayMove(tasks, oldIndex, newIndex)
      setTasks(newTasks)
      onChange(newTasks)
    }
  }

  const handleDragOver = (event: DragEndEvent) => {
    const { active, over } = event

    if (!over) return

    const activeTask = tasks.find(task => task.id === active.id)
    const overTask = tasks.find(task => task.id === over.id)

    if (activeTask) {
      const newList = overTask ? overTask.list : `${over.id}`

      if (activeTask.list !== newList) {
        const newTasks = tasks.map(task =>
          task.id === activeTask.id ? { ...task, list: newList } : task
        )
        setTasks(newTasks)
        // onChange(newTasks)
      }
    }
  }

  return (
    <div className="containerDragAndDrop">
      <DndContext
        collisionDetection={closestCorners}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <DropZone tasks={tasks} list="Locaciones" />
        <DropZone tasks={tasks} list="Orden rutina" />
      </DndContext>
    </div>
  )
}

export default DragAndDrop
