import { toDataURL } from 'qrcode'
import { entries } from '../types/entries'
import moment from 'moment-timezone'

export const generateQRBase64 = async (qrValue: string): Promise<string> => {
  try {
    const dataUrl = await toDataURL(qrValue)

    return dataUrl
  } catch (error) {
    console.error('Error al generar el código QR en formato base64:', error)
    throw error
  }
}
export const printQRCode = async (
  imagenBase64: string,
  value: entries,
  clientName: string
) => {
  try {
    const ipcRenderer = (window as any).ipcRenderer

    const ValueImage = await generateQRBase64(imagenBase64)

    ipcRenderer.send('print-to-printer', {
      ValueImage,
      clientName,
      timeIn: moment().tz('America/Guatemala').format('DD/MM/YYYY HH:mm'),
      destine: (value as entries).destination,
      showId: (value as entries).showId?.substring(6),
      name: `${
        value.name && value.lastName
          ? `${value.name.split(' ')[0]} ${value.lastName.split(' ')[0]}`
          : value.destination
      }`,
      brand: value.brand,
      origin: value.origin
    })
  } catch (error) {
    console.error('error')
  }
}
