// administratorConfig.ts
import { FieldTypeEnum, FormField } from '../../Form/DynamicForm'
import { TableColumn, enumTypeColumn } from '../../Table'

export const columnsLocation: TableColumn[] = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    type: enumTypeColumn.string
  },
  {
    title: 'Tipo',
    dataIndex: 'typeName',
    key: 'typeName',
    type: enumTypeColumn.string
  },

  {
    title: 'Creado en',
    dataIndex: 'createdAt',
    key: 'createdAt',
    type: enumTypeColumn.date
  }
]
export enum typeLocationEnum {
  'IN' = 'IN',
  'OUT' = 'OUT',
  'BOTH' = 'AMBOS'
}
export type TypeLocationEnum = keyof typeof typeLocationEnum

export const convertTypeLocation = (value: TypeLocationEnum): string => {
  return typeLocationEnum[value] || value
}
export const convertFromExtendedTypeLocation = (value: string): TypeLocationEnum => {
  const invertedEnum: Record<string, TypeLocationEnum> = {
    IN: 'IN',
    OUT: 'OUT',
    AMBOS: 'BOTH'
  }
  return invertedEnum[value] || (value as TypeLocationEnum)
}

export const LocationFormConfig: FormField[] = [
  { type: FieldTypeEnum.text, label: 'Nombre', name: 'name' },
  {
    type: FieldTypeEnum.select,
    label: 'Tipo',
    name: 'type',
    options: [
      {
        value: 'IN',
        label: 'IN'
      },
      {
        value: 'OUT',
        label: 'OUT'
      },
      {
        value: 'BOTH',
        label: 'AMBOS'
      }
    ]
  }
]
