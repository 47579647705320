import { loginType } from '../Provider/AuthProvider'
import { IUser } from '../types/user'
import axiosInstance from './axiosInstance'

interface LoginResponse {
  token: string
}

export const loginService = async (
  params: loginType
): Promise<LoginResponse> => {
  try {
    const response = await axiosInstance.post(`/api/auth/login`, params)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de inicio de sesión:', error)
    throw error
  }
}

export const validateToken = async (token?: string): Promise<IUser> => {
  try {
    const headers = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      : {}
    const response = await axiosInstance.get(
      `/api/auth/validate-token`,
      headers
    )

    return response.data.user
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de inicio de sesión:', error)
    throw error
  }
}
