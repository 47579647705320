import React, { useEffect, useState } from 'react'
import { Pagination } from '../../types/types'
import './style.scss'
import VisibilityIcon from '@mui/icons-material/Visibility'
import FactCheck from '@mui/icons-material/FactCheck'
import { IconButton } from '@mui/material'
import { useLoading } from '../../Provider/LoadingProvider'
import Modal from '../../components/GeneralComponents/Modal'
import Table from '../../components/Table'
import DeleteButton from '../../components/Table/components/DeleteButton'
import FormCorporate from '../../components/Tables/Corporate/Form'
import {
  deleteCorp,
  listCorp,
  listClient,
  submitClientSelect
} from '../../services/corps'
import { Corporate } from '../../types/Corporate'
import { columnsCorporate } from '../../components/Tables/Corporate/Columns'
import MultiSelectCells, {
  KeyValue
} from '../../components/Tables/Corporate/MultiSelectCells'

const Corps = () => {
  const [data, setData] = useState<Pagination<Corporate>>({
    currentPage: 1,
    hasNextPage: false,
    hasPrevPage: false,
    total: 1,
    totalPages: 1,
    data: []
  })
  const limit = 6
  const [Clients, setClients] = useState<KeyValue>({})
  const [isOpenFormModal, setIsOpenFormModal] = useState(false)
  const [isOpenMultiSelectModal, setIsOpenMultiSelectModal] = useState(false)
  const [search, setSearch] = useState('')
  const [filteredBySearch, setFilteredBySearch] = useState(false)
  const [selected, setSelected] = useState<Corporate | undefined>(undefined)
  const [multiSelectItems, setMultiSelectItems] = useState<KeyValue>({}) // State for MultiSelectCells data
  const { startLoading, stopLoading } = useLoading()

  useEffect(() => {
    getData(search.length > 3 ? search : '', data.currentPage)
  }, [])

  useEffect(() => {
    if (search.length >= 3) {
      setFilteredBySearch(true)
      getData(search, 1)
    } else if (filteredBySearch) {
      setFilteredBySearch(false)
      getData('', 1)
    }
  }, [search])

  useEffect(() => {
    getData(search, data.currentPage)
  }, [data.currentPage])

  const onCloseFormModal = () => {
    setIsOpenFormModal(false)
    setSelected(undefined)
  }

  const handleOpenFormModal = () => {
    setIsOpenFormModal(true)
  }

  const onCloseMultiSelectModal = () => {
    setIsOpenMultiSelectModal(false)
  }

  const getData = async (searchValue: string, page: number) => {
    try {
      startLoading()
      const dataCorp = await listCorp({
        params: { page, search: searchValue, limit }
      })
      const dataClients = await listClient()
      setClients(dataClients)
      setData(dataCorp)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const handleSubmit = async () => {
    setSelected(undefined)
    setIsOpenFormModal(false)
    await getData(search.length > 3 ? search : '', data.currentPage)
  }

  const handlePageChange = (newPage: number) => {
    getData(search.length >= 3 ? search : '', newPage)
  }

  const handleView = (id: Corporate) => {
    setSelected(id)
    setIsOpenFormModal(true)
  }

  const handleDelete = async (id: string) => {
    try {
      startLoading()
      setSelected(undefined)

      await deleteCorp(id)
      await getData(search.length > 3 ? search : '', data.currentPage)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const handleSubmitClientSelect = async (dataSelect: KeyValue) => {
    try {
      startLoading()
      const clientSelect = dataSelect
        ? JSON.stringify(dataSelect)
        : JSON.stringify({})
      await submitClientSelect(selected?.id, { clientSelect })
      await getData(search.length > 3 ? search : '', data.currentPage)
      setSelected(undefined)
      setIsOpenMultiSelectModal(false)
    } catch (error) {
      console.error(error)
    }
  }

  const handleFactCheckClick = (id: Corporate) => {
    console.log('item data ', id)
    setSelected(id)
    const data: KeyValue = id.clientSelect ? JSON.parse(id.clientSelect) : {}
    console.log('clear data', data)
    setMultiSelectItems(data)
    setIsOpenMultiSelectModal(true)
  }

  const Actions = ({ id, item }: { id: string; item?: Corporate }) => {
    return (
      <>
        <IconButton onClick={() => handleView(item as Corporate)}>
          <VisibilityIcon style={{ color: 'white' }} />
        </IconButton>

        <IconButton onClick={() => handleFactCheckClick(item as Corporate)}>
          <FactCheck style={{ color: 'white' }} />
        </IconButton>

        <DeleteButton OnDelete={() => handleDelete(id)} />
      </>
    )
  }

  return (
    <>
      <div className='CorporateContainer'>
        <div className='title'>
          <p>Corporativos</p>
        </div>
        <div className='tableContainer'>
          <Table
            onPageChange={handlePageChange}
            onChangeSearch={setSearch}
            pagination={data}
            handleOpenModal={handleOpenFormModal}
            columns={columnsCorporate}
            Actions={Actions}
            itemsPerPage={limit}
          />
        </div>
      </div>
      <Modal isOpen={isOpenFormModal} onClose={onCloseFormModal}>
        <FormCorporate initialValue={selected} onSubmit={handleSubmit} />
      </Modal>
      <Modal isOpen={isOpenMultiSelectModal} onClose={onCloseMultiSelectModal}>
        {isOpenMultiSelectModal && (
          <MultiSelectCells
            items={Clients}
            initialSelected={multiSelectItems}
            personalSelect={selected}
            onSubmit={(updatedSelected: KeyValue) => {
              handleSubmitClientSelect(updatedSelected)
            }}
          />
        )}
      </Modal>
    </>
  )
}

export default Corps
