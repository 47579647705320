import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'
import io from 'socket.io-client'
import { listEntriesByClient } from '../services/entries'
import { brand } from '../types/brand'
import { entries } from '../types/entries'
import { Pagination } from '../types/types'
import { useAuth } from './AuthProvider'
import { useLoading } from './LoadingProvider'

import { listBrandsAll } from '../services/brand'
import { convertEntriesAttributes } from '../utils/utils'
import SocketNotifications from '../components/CheckIn/SocketNotifications/whats'
type CheckInContextType = {
  data: Pagination<entries>
  search: string
  setSearch: Dispatch<SetStateAction<string>>
  brands: brand[]
  getDataCheckIn: (searchValue: string, page: number) => void
  lastEntry: entries | null
  setLastEntry: Dispatch<SetStateAction<entries | null>>
}

const CheckInContext = createContext<CheckInContextType | undefined>(undefined)

type CheckInProviderProps = {
  children: ReactNode
}

const CheckInProvider: React.FC<CheckInProviderProps> = ({ children }) => {
  const [search, setSearch] = useState('')
  const { startLoading, stopLoading } = useLoading()
  const { token } = useAuth()
  const { user } = useAuth()
  const [filteredBySearch, setFilteredBySearch] = useState(false)
  const [brands, setBrands] = useState<brand[]>([])
  // const [destine, setDestine] = useState<string[]>([])
  const [lastEntry, setLastEntry] = useState<entries | null>(null)
  const [data, setData] = useState<Pagination<entries>>({
    currentPage: 1,
    hasNextPage: false,
    hasPrevPage: false,
    total: 1,
    totalPages: 1,
    data: []
  })
  const getDataCheckIn = async (searchValue: string, page: number, noLoading?: boolean) => {
    try {
      !noLoading && startLoading()
      const entries = await listEntriesByClient({
        id: user?.clientId as string,
        params: { page, search: searchValue },
        token
      })
      setData({
        ...entries,
        data: entries.data.map(convertEntriesAttributes)
      })
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }
  const getData = async () => {
    try {
      const brands = await listBrandsAll(token)
      setBrands(brands)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    if (search.length >= 3) {
      setFilteredBySearch(true)
      getDataCheckIn(search, 1)
    } else if (filteredBySearch) {
      setFilteredBySearch(false)
      getDataCheckIn('', 1)
    }
  }, [search])

  useEffect(() => {
    getDataCheckIn('', 1)
  }, [])

  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_URL_BACKEND as string)
    const socketWhats = io(
      process.env.REACT_APP_API_URL_WHATS || ('https://whats.ipass.com.gt' as string)
    )

    socket.on(`newEntry-${user?.clientId}`, (message: any) => {
      setData(prevData => ({
        ...prevData,
        data: [convertEntriesAttributes(message.newEntry), ...prevData.data]
      }))
    })
    socket.on(`updateEntry-${user?.clientId}`, (message: any) => {
      if (search !== '' || data.currentPage !== 1) return
      getDataCheckIn('', 1, true)
    })
    socketWhats.on(`updateEntry-${user?.clientId}`, (message: any) => {
      if (search !== '' || data.currentPage !== 1) return
      getDataCheckIn('', 1, true)
    })

    return () => {
      socket.disconnect()
    }
  }, [])

  useEffect(() => {
    if (!brands.length /*  || !destine.length */) {
      getData()
    }
  }, [])
  useEffect(() => {
    setLastEntry(data.data[0])
  }, [data.data])

  return (
    <>
      <CheckInContext.Provider
        value={{
          setLastEntry,
          lastEntry,
          data,
          brands,
          search,
          setSearch,
          getDataCheckIn
        }}
      >
        <>{children}</>
      </CheckInContext.Provider>
      {<SocketNotifications userId={user?.clientId} brands={brands} />}
    </>
  )
}

const useCheckIn = () => {
  const context = useContext(CheckInContext)
  if (!context) {
    throw new Error('useCheckIn must be used within CheckInProvider')
  }
  return context
}

export { CheckInProvider, useCheckIn }
