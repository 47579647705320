// services/delegateService.ts

import { DelegateResident, DelegateResidentCreate } from '../types/delegate_resident'
import { Pagination, PaginationQuery } from '../types/types'
import axiosInstance from './axiosInstance'

export const listDelegateByResident = async ({
  id,
  params
}: {
  id: string
  params: PaginationQuery
}): Promise<Pagination<DelegateResident>> => {
  try {
    const response = await axiosInstance.get(`/api/delegate_resident/by_resident/${id}`, {
      params
    })

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de delegados:', error)
    throw error
  }
}

export const createDelegate = async (
  params: DelegateResidentCreate
): Promise<DelegateResident> => {
  try {
    const response = await axiosInstance.post(
      `/api/delegate_resident`,
      JSON.stringify(params),
      {}
    )

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de creación de delegado:', error)
    throw error
  }
}

export const updateDelegate = async (
  id: string,
  params: DelegateResidentCreate
): Promise<DelegateResident> => {
  try {
    const response = await axiosInstance.post(
      `/api/delegate_resident/${id}`,
      JSON.stringify(params),
      {}
    )

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de creación de delegado:', error)
    throw error
  }
}

export const enableDelegate = async (
  id: string,
  params: {
    enable: boolean
    guest: boolean
  }
): Promise<DelegateResident> => {
  try {
    const response = await axiosInstance.post(
      `/api/delegate_resident/enable/${id}`,
      JSON.stringify(params),
      {}
    )

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de creación de delegado:', error)
    throw error
  }
}

export const NotificationResident = async (
  id: string,
  notification: boolean
): Promise<DelegateResident> => {
  try {
    const response = await axiosInstance.post(`/api/delegate_resident/notifications/${id}`, {
      enableNotification: notification
    })

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de creación de delegado:', error)
    throw error
  }
}

export const deleteDelegate = async (id: string): Promise<void> => {
  try {
    const response = await axiosInstance.delete(`/api/delegate_resident/${id}`)

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de eliminación de delegado:', error)
    throw error
  }
}

export const regenerateCodeDelegate = async (id: string): Promise<{ qr: string }> => {
  try {
    const response = await axiosInstance.post(`/api/delegate_resident/regenerate_qr/${id}`)

    return response.data
  } catch (error) {
    console.error('Error al eliminar Residente:', error)
    throw error
  }
}
