import React, { useEffect, useState } from 'react'
import './styles.scss'
import { useAuth } from '../../Provider/AuthProvider'

import { detailReport } from '../../types/dashboard'
import { useLoading } from '../../Provider/LoadingProvider'
import { getLogsTicket } from '../../services/dashboard'
import PhotoTooltip from '../../components/tooltips/img'
import Select from '../../components/Form/select2'
import { Staff } from '../../types/dashboard'
import Modal from '../GeneralComponents/Modal'

import Button from '../../components/Form/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloud } from '@fortawesome/free-solid-svg-icons'
import { send_ticket } from '../../services/whatsapp'

export interface ReportModalProps {
  reportId: string
  Staffs: Staff[]
  onClose: () => void
}

const AssignWorkerModal: React.FC<ReportModalProps> = ({ Staffs, reportId, onClose }) => {
  const [report, setReport] = useState<detailReport | null>(null)
  const [open, setOpen] = useState<boolean>(false)

  const [selected, setSelected] = useState<{ staff: string; ticketId: string } | undefined>(
    undefined
  )

  const { startLoading, stopLoading } = useLoading()

  const { user, token } = useAuth()

  useEffect(() => {
    getTicketData(reportId)
  }, [reportId])

  const getTicketData = async (_id: string) => {
    try {
      startLoading()
      const data = await getLogsTicket(reportId)
      setReport(data)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const handleChange = (staff: string, ticketId: string) => {
    setSelected({ staff, ticketId })
  }

  const options = [
    { value: 'Open', label: 'Abierto' },
    { value: 'Close', label: 'Cerrado' },
    { value: 'Hold', label: 'Espera' },
    { value: 'Evaluating', label: 'Evaluando' },
    { value: 'Spam', label: 'Irrelevante' }
  ]

  const priority: {
    [key: string]: string
  } = {
    Emergency: 'Emergencia',
    Report: 'reporte',
    Express: 'Ticket Express',
    CheckPoint: 'CheckPoint'
  }

  if (!report) return <div className="modal">.</div>

  const sendTicketStaff = async (accept: boolean, notify?: boolean) => {
    startLoading()

    try {
      if (user?.clientId && selected && (accept || report.status === 'Open')) {
        await send_ticket(
          user.clientId,
          report.responsibleID,
          selected?.staff,
          selected?.ticketId,
          token,
          notify
        )
        onClose()
      } else {
        setOpen(true)
      }
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const closeModal = () => {
    setOpen(false)
    setSelected(undefined)
  }

  const Waring: React.FC = () => {
    return (
      <>
        <div className="WaringModal">
          <div className="Waring-header">
            <h2>ADVERTENCIA</h2>
          </div>
          <div className="Waring-text">
            Estas a punto de reasignar trabajador <br /> <br />
            <span>¿Deseas que notifique al residente del cambio?</span>
          </div>

          <div className="containerButtons">
            <Button
              text="SI"
              styleType={`normal`}
              onClick={() => {
                sendTicketStaff(true, true)
              }}
            />

            <Button
              text="NO"
              styleType={`normal`}
              onClick={() => {
                sendTicketStaff(true, false)
              }}
            />
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="modalSelectStaff">
      <div className="modal-header">
        <h2>Asignar a trabajador</h2>
        <p>
          <span>Código:</span> {report.shortID}
        </p>
      </div>
      <div className="log-entry">
        <div className="textRight">
          <div>
            <span>Fecha: </span> {report.createdAt}
          </div>

          <div>
            <span>Creado por: </span> {report.createdName}
          </div>
        </div>
        <div className="separate">
          {
            <div className={`separateTicketPhotoLast`}>
              <PhotoTooltip
                photos={[report.PhotoCreatedAt]}
                altText={report.shortID}
                altPhoto="images/linear.png"
                className="ticket-photo"
              />
            </div>
          }
        </div>
        <div className="dataLogs">
          <div>
            <span>Prioridad: </span>
            {priority[report.priority]}
          </div>
          <div>
            <span>Estado actual: </span>
            {options.find(option => option.value === report.status)?.label || 'Pendiente'}
          </div>
          {report.resolvedById && (
            <div>
              <span>{report.priority === 'Express ' ? 'Notificado ' : 'Tomado Por: '}</span>
              {report.resolvedById}
            </div>
          )}
        </div>
      </div>

      <div className="centerSelectStaff">
        <span>Descripción:</span> <p>{report.description}</p>
      </div>

      <div className="centerSelectStaff">
        <span>Selecciona el trabajador a asignar:</span>

        <Select
          placeholder={'Empleados'}
          options={Staffs.map(staff => ({
            value: staff.id,
            label: staff.name
          }))}
          value={``}
          Name="staff"
          onChange={value => handleChange(value.target.value, report.id)}
        />
      </div>

      <div className="containerButtons">
        <Button
          text="Guardar"
          styleType={
            selected?.staff && selected?.staff !== report.responsibleID ? `normal` : `disable`
          }
          disabled={selected?.staff && selected?.staff !== report.responsibleID ? false : true}
          icon={<FontAwesomeIcon style={{ marginRight: '10px' }} icon={faCloud} />}
          onClick={() => {
            sendTicketStaff(false)
          }}
        />
      </div>

      {selected && (
        <Modal
          styleContainer={{ width: '480px', height: '270px' }}
          isOpen={open}
          onClose={closeModal}
        >
          <Waring />
        </Modal>
      )}
    </div>
  )
}

export default AssignWorkerModal
