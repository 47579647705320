import { KeyValue } from '../components/Tables/Corporate/MultiSelectCells'
import { Corporate } from '../types/Corporate'
import { Pagination, PaginationQuery } from '../types/types'
import axiosInstance from './axiosInstance'

export const listCorp = async ({
  params
}: {
  params: PaginationQuery
}): Promise<Pagination<Corporate>> => {
  try {
    const response = await axiosInstance.get('/api/corporate', {
      params
    })

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de corporate', error)
    throw error
  }
}

export const listClient = async (): Promise<KeyValue> => {
  try {
    const response = await axiosInstance.get('/api/corporate/allClients', {})
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de corporate', error)
    throw error
  }
}

export const updateCorp = async (id: string, params: FormData): Promise<Corporate> => {
  try {
    const response = await axiosInstance.post(`/api/corporate/${id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    console.error('Error en la actualizacion de Corporate:', error)
    throw error
  }
}

export const createCorp = async (params: FormData): Promise<Corporate> => {
  try {
    const response = await axiosInstance.post('/api/corporate', params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    console.error('Error en la creación de Corporate:', error)
    throw error
  }
}

export const deleteCorp = async (id: string): Promise<void> => {
  try {
    const response = await axiosInstance.delete(`/api/corporate/${id}`)

    return response.data
  } catch (error) {
    console.error('Error al eliminar Corporate:', error)
    throw error
  }
}

export const submitClientSelect = async (
  id: string | undefined,
  params: { clientSelect: string }
): Promise<boolean> => {
  try {
    if (id === undefined) return false
    const response = await axiosInstance.post(
      `/api/corporate/submit/${id}`,
      params, // Enviar los parámetros correctamente en el cuerpo de la solicitud
      {
        headers: {
          'Content-Type': 'application/json' // Cambia a JSON si no estás enviando archivos
        }
      }
    )

    return response.data
  } catch (error) {
    console.error('error al modificar los clientes asignados ', error)
    throw error
  }
}
