import React, { FC, useEffect, useRef, useState } from 'react'
import CameraIcon from '@mui/icons-material/CameraAlt'
import Button from '@mui/material/Button'
import { Buffer } from 'buffer'
import { io } from 'socket.io-client'
import { readQrDataFromBase64 } from '../../../utils/readQR'
import { typeQrEnum } from '../../../types/qr'
import { entries } from '../../../types/entries'
import { useAuth } from '../../../Provider/AuthProvider'
import { useDebounce } from '../../../hooks/useDebounce'
import { validate_qr_recepcion } from '../../../services/entries'

type CameraProps = {
  text: string
  onSubmitImage: (value: File | null) => void
  file: string
  defaultImg: string
  watch: File | null
  okEntry: boolean | undefined
  nameCamera: string
  TimeClearqr: number
  loading: React.Dispatch<React.SetStateAction<boolean>>
  setlastEntry: React.Dispatch<React.SetStateAction<entries | null | undefined>>
}

const NewCameraFiles: FC<CameraProps> = ({
  text,
  file,
  defaultImg,
  watch,
  okEntry,
  onSubmitImage,
  nameCamera,
  setlastEntry,
  loading,
  TimeClearqr
}) => {
  const { token, user } = useAuth()
  const imageRef = useRef<HTMLImageElement>(null)
  const [taken, setTaken] = useState(false)
  const [Img64, setImg64] = useState('')
  const [timeReset, setTimeReset] = useState<NodeJS.Timeout | undefined>(undefined)
  const debounceValue = useDebounce(Img64, 1000)

  useEffect(() => {
    if (okEntry) return

    const imageElement = imageRef.current
    if (imageElement) {
      imageElement.src = defaultImg
    }

    const socket = io('http://localhost:8085')

    socket.on(`${file}`, base64Cam => {
      console.log('entrie base64Cam')
      loading(true)
      setImg64(base64Cam) // Esto se debouncerá
    })

    return () => {
      socket.off(`${file}`) // Asegúrate de limpiar correctamente
      socket.disconnect()
    }
  }, [file, okEntry])

  useEffect(() => {
    if (okEntry) return
    if (debounceValue) {
      // Verificar que sea diferente
      handlerImg(debounceValue).finally(() => {
        setImg64('')
      }) // Pasa el valor debounced
    }
  }, [debounceValue]) // Asegúrate de que esto escuche solo a debounceValue

  const handlerImg = async (base64: string) => {
    const imageElement = imageRef.current
    if (base64 && imageElement) {
      imageElement.src = base64
      const transform = dataUrlToFile(base64, 'captured-image.png')
      const { file, buff } = transform ? transform : { file: undefined, buff: undefined }
      console.log('buscando qr en principal')
      const dataQr = buff ? await readQrDataFromBase64(buff) : false
      loading(false)

      if (file && !dataQr) {
        onSubmitImage(file) // Enviar imagen solo si no hay datos QR
      }
      if (dataQr) {
        await processDataqr(dataQr)

        if (timeReset) clearTimeout(timeReset)

        setTimeReset(
          setTimeout(() => {
            retakePhoto()
          }, TimeClearqr)
        )
      }

      setTaken(true)
    }
  }

  useEffect(() => {
    if (!watch) {
      retakePhoto()
    }
  }, [watch])

  const dataUrlToFile = (
    dataUrl: string,
    filename: string
  ): { file: File; buff: Buffer } | undefined => {
    const arr = dataUrl.split(',')
    if (arr.length < 2) {
      return undefined
    }
    const mimeArr = arr[0].match(/:(.*?);/)
    if (!mimeArr || mimeArr.length < 2) {
      return undefined
    }
    const mime = mimeArr[1]
    const buff = Buffer.from(arr[1], 'base64')

    const file = new File([buff], filename, { type: mime })

    return { file, buff }
  }

  const retakePhoto = () => {
    const imageElement = imageRef.current
    if (imageElement) {
      imageElement.src = defaultImg
    }
    setTaken(false)
    onSubmitImage(null)
    setImg64('')
  }

  const clipRetakePhoto = () => {
    retakePhoto()
    onSubmitImage(null)
  }

  const processDataqr = async (dataQr: string) => {
    if (dataQr) {
      const type = dataQr.slice(0, 4)
      if (Object.values(typeQrEnum).includes(type as typeQrEnum)) {
        const entrie = new entries()
        entrie.qrCode = dataQr
        entrie.cameraIn = nameCamera
        setlastEntry(entrie)
        const params = { camera: nameCamera, code: dataQr, clientID: user?.clientId as string }
        try {
          const entrie = await validate_qr_recepcion({ params, token })
          const imageElement = imageRef.current

          if (entrie.photo && text === 'Foto del rostro' && imageElement) {
            imageElement.src = entrie.photo
          }

          if (entrie.DNI_A && text === 'DNI frontal' && imageElement) {
            imageElement.src = entrie.DNI_A
          }
        } catch (error) {
          setlastEntry(undefined)
        }
      }
    }
  }

  return (
    <div className="camera-section-RTSP">
      <p className="textoCamera-RTSP">{text}</p>
      {/* Mostrar la imagen capturada si existe */}
      <img
        ref={imageRef}
        alt={taken ? 'Imagen capturada desde la cámara' : ''}
        style={{
          display: 'inline-block',
          objectFit: taken ? 'cover' : 'contain'
        }}
      />
      {/* Botón para retomar la foto */}

      <Button
        variant="contained"
        className="capture-button-RTSP"
        startIcon={taken && !okEntry ? <CameraIcon /> : null} // Cambié '' a null
        onClick={clipRetakePhoto}
        disabled={(!taken && !okEntry) || (taken && !okEntry)}
      >
        {taken && !okEntry ? 'Reintentar' : ''}
      </Button>
    </div>
  )
}

export default NewCameraFiles
