// LoginPage.tsx
import React, { useState } from 'react'
import { useAuth } from '../../Provider/AuthProvider'
import { useLoading } from '../../Provider/LoadingProvider'
import Button from '../../components/Form/Button'
import Input from '../../components/Form/Input'
import { roleEnum } from '../../types/user'
import './styles.scss'
const LoginPage: React.FC = () => {
  const { login } = useAuth()
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const { isElectron } = useLoading()
  const [role, setRole] = useState(isElectron ? roleEnum.RECEPTION : roleEnum.SUPER_ADMIN)
  const roles = isElectron
    ? [{ value: roleEnum.RECEPTION, text: 'Recepción', icon: 'guardia.png' }]
    : [
        { value: roleEnum.RECEPTION, text: 'Recepción', icon: 'guardia.png' },
        { value: roleEnum.CORP, text: 'Corp', icon: 'admin.png' },
        { value: roleEnum.ADMIN, text: 'Admin', icon: 'admin.png' },
        {
          value: roleEnum.SUPER_ADMIN,
          text: 'SuperAdmin',
          icon: 'super_admin.png'
        }
      ]
  const handleLogin = () => {
    login({ userName, password, role })
  }

  return (
    <div className="loginContainer">
      <div className="itemsContainer">
        <h2>Inicio de Sesión</h2>
        <h3>Selecciona tu rol:</h3>
        <div className="rolesContainer">
          {roles.map(rol => (
            <div
              onClick={() => setRole(rol.value)}
              className={`rolContainer ${rol.value === role ? 'selected' : ''}`}
              key={rol.text}
            >
              <div className="imageContainer">
                <img src={`images/icons/${rol.icon}`} alt={rol.text} />
              </div>

              <label>{rol.text}</label>
            </div>
          ))}
        </div>
        <div>
          <Input
            label="USUARIO :  "
            value={userName}
            placeholder="Usuario"
            onChange={e => setUserName(e.target.value)}
            icon="images/icons/usuario.png"
          />
          <Input
            label="CONTRASEÑA : "
            value={password}
            type="password"
            placeholder="Contraseña"
            onChange={e => setPassword(e.target.value)}
            icon="images/icons/lock.png"
          />
        </div>

        <Button onClick={handleLogin} text="Iniciar Sesión" />
      </div>
    </div>
  )
}

export default LoginPage
