import React, { FC, useEffect, useState } from 'react'

import './style.scss'
import Select, { SelectOption } from '../../Form/Select'
import { getConcept } from '../../../services/PaymentsConceptsResident'
import InputDark from '../../Form/InputDark'
import Button from '../../Form/Button'

const ConceptsRes: FC<{
  residentId: string
  clientId: string
  lastData?: any
  onSubmit: (dataSend: any) => void
}> = ({ residentId, clientId, lastData, onSubmit }) => {
  const [opcions, setOpcions] = useState<SelectOption[] | undefined>(undefined)
  const [handleChange, setHandleChange] = useState<any>(lastData ? lastData : {})

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    setHandleChange(lastData)
  }, [lastData])

  const getData = async () => {
    try {
      const statement = await getConcept(residentId, clientId)
      setOpcions(statement)
    } catch (error) {
      console.error(error)
    } finally {
    }
  }

  return (
    <div className="containerSetCuota">
      <div className="title">
        <p>CUOTA OPCIONAL</p>
      </div>

      <div className="SelectCuota">
        <Select
          key={residentId}
          placeholder={'Cuota'}
          options={opcions ? opcions : []}
          value={handleChange ? handleChange.paymentsConceptsId : ''}
          onChange={value =>
            setHandleChange((prevData: any) => ({
              ...prevData,
              paymentsConceptsId: value
            }))
          }
        />
      </div>

      <div className="NameCuota">
        <InputDark
          key={residentId}
          placeholder={'Nombre'}
          value={handleChange ? handleChange.name : ''}
          onChange={value =>
            setHandleChange((prevData: any) => ({ ...prevData, name: value.target.value }))
          }
        />
      </div>

      <div className="ButtonCuota">
        <Button
          className="button"
          text={lastData ? 'Actualizar' : `Crear`}
          type="submit"
          onClick={() => onSubmit(handleChange)}
        />
      </div>
    </div>
  )
}

export default ConceptsRes
