// src/services/AccessConfig.ts
import { AccessConfig } from '../types/AccessConfig'
import { Pagination, PaginationQuery } from '../types/types'
import axiosInstance from './axiosInstance'

export const createAccessConfig = async (params: FormData): Promise<AccessConfig> => {
  try {
    const response = await axiosInstance.post('/api/AccessConfig', params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    console.error('Error en la creación de AccessConfig :', error)
    throw error
  }
}

export const updateAccessConfig = async (
  id: string,
  params: FormData
): Promise<AccessConfig> => {
  try {
    const response = await axiosInstance.post(`/api/AccessConfig/${id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    console.error('Error en la actualización de AccessConfig :', error)
    throw error
  }
}

export const listAccessConfig = async ({
  id,
  params
}: {
  id: string
  params: PaginationQuery
}): Promise<Pagination<AccessConfig>> => {
  try {
    const response = await axiosInstance.get(`/api/AccessConfig/${id}`, {
      params
    })

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de AccessConfigCreatees:', error)
    throw error
  }
}

export const deleteAccessConfig = async (id: string): Promise<void> => {
  try {
    const response = await axiosInstance.delete(`/api/AccessConfig/${id}`)

    return response.data
  } catch (error) {
    console.error('Error al eliminar AccessConfig Location:', error)
    throw error
  }
}
