// pages/RoutinesPage.tsx
import React, { useEffect, useRef, useState } from 'react'

import { useLoading } from '../../../Provider/LoadingProvider'
import { columnsRoutines } from '../../../components/Tables/Routines/Columns'
import FormRoutines from '../../../components/Tables/Routines/Form'
import { Pagination } from '../../../types/types'
import './style.scss'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { IconButton } from '@mui/material'

import { useAuth } from '../../../Provider/AuthProvider'
import Modal from '../../../components/GeneralComponents/Modal'
import Table from '../../../components/Table'
import DeleteButton from '../../../components/Table/components/DeleteButton'
import {
  deleteRoutines,
  CopyRoutines,
  listRoutines,
  setEnableRoutines
} from '../../../services/routines'
import { Routines } from '../../../types/routines'
import Switch from '../../../components/Form/Switch'

const RoutinesPage = () => {
  const { user } = useAuth()
  const [data, setData] = useState<Pagination<Routines>>({
    currentPage: 1,
    hasNextPage: false,
    hasPrevPage: false,
    total: 1,
    totalPages: 1,
    data: []
  })
  const limit = 6
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [search, setSearch] = useState('')
  const { startLoading, stopLoading } = useLoading()
  const [selected, setSelected] = useState<Routines | undefined>(undefined)
  const isFirstRender = useRef(true)

  useEffect(() => {
    getData(search.length > 3 ? search : '', data.currentPage)
  }, [])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    const delayDebounceFn = setTimeout(() => {
      getData(search, 1)
    }, 600)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    getData(search, data.currentPage)
  }, [data.currentPage])

  useEffect(() => {
    if (selected) {
      setIsOpenModal(true)
    }
  }, [selected])

  const onCloseModal = () => {
    setSelected(undefined)
    setIsOpenModal(false)
  }

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const getData = async (searchValue: string, page: number) => {
    try {
      startLoading()
      const routines = await listRoutines({
        params: { page, search: searchValue, limit },
        id: user?.clientId as string
      })
      setData(routines)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const handleSubmit = async () => {
    setSelected(undefined)
    setIsOpenModal(false)
    await getData(search.length > 3 ? search : '', data.currentPage)
  }

  const handlePageChange = (newPage: number) => {
    getData(search.length >= 3 ? search : '', newPage)
  }

  const handleDelete = async (id: string) => {
    try {
      startLoading()
      await deleteRoutines(id)
      await getData(search.length > 3 ? search : '', data.currentPage)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const handleView = (value: Routines) => {
    setSelected(value)
  }

  const handleCopy = async (id: string) => {
    try {
      startLoading()
      await CopyRoutines(id)
      await getData(search.length > 3 ? search : '', data.currentPage)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const handleEnable = (id: string) => {
    setData(lastData => ({
      ...lastData,
      data: lastData.data.map(item =>
        item.id === id ? { ...item, enable: !item.enable } : item
      )
    }))

    setEnableRoutines(id)
  }

  const Actions = ({ id, item }: { id: string; item: Routines }) => {
    return (
      <div className="actionsContainer">
        <Switch
          onChange={() => {
            handleEnable(id)
          }}
          value={item.enable}
        />
        <IconButton onClick={() => handleView(item as Routines)}>
          <VisibilityIcon style={{ color: 'white' }} />
        </IconButton>

        <IconButton onClick={() => handleCopy(id)}>
          <ContentCopyIcon style={{ color: 'white' }} />
        </IconButton>

        <DeleteButton OnDelete={() => handleDelete(id)} />
      </div>
    )
  }

  return (
    <>
      <div className="routinesContainer">
        <div className="title">
          <p>Planes</p>
        </div>
        <div className="tableContainer">
          <Table
            onPageChange={handlePageChange}
            onChangeSearch={setSearch}
            pagination={data}
            itemsPerPage={limit} //6
            handleOpenModal={handleOpenModal}
            columns={columnsRoutines}
            Actions={Actions as any}
          />
        </div>
      </div>
      <Modal isOpen={isOpenModal} onClose={onCloseModal}>
        <FormRoutines onSubmit={handleSubmit} initialValue={selected} />
      </Modal>
    </>
  )
}

export default RoutinesPage
