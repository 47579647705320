import React, { FC, useState } from 'react'
import Switch from '../Form/Switch'
import './styles.scss'
import Button from '../Form/Button'

interface Props {
  enable: boolean
  guest: boolean
  onUpdate: (value: { Resident: boolean; Guest: boolean }) => void
}

const EnableComponentDelegate: FC<Props> = ({ enable, guest, onUpdate }) => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false)
  const [Resident, setResident] = useState(enable)
  const [Guest, setGuest] = useState(guest)

  const handleAccept = () => {
    setIsPopUpOpen(false)
    onUpdate({ Resident, Guest })
  }

  const handleReject = () => {
    setResident(enable)
    setGuest(guest)
    setIsPopUpOpen(false)
  }

  const handleOpen = () => {
    setIsPopUpOpen(true)
  }

  const handleResidentChange = (value: boolean) => {
    setResident(value)
    if (value) {
      setGuest(true)
    } else {
      setGuest(false)
    }
  }

  const handleGuestChange = (value: boolean) => {
    setGuest(value)
  }

  return (
    <div>
      <Switch value={enable} onChange={handleOpen} />
      {isPopUpOpen && (
        <div className='popup-overlay-resident'>
          <div className='popup'>
            <h3>Configuraciones</h3>
            <div className='itemsContainerResident'>
              <Switch
                label='Delegado:'
                onChange={handleResidentChange}
                value={Resident}
              />
              <Switch
                label='Invitados:'
                onChange={handleGuestChange}
                value={Guest}
              />
            </div>
            <div className='itemsContainer'>
              <Button color='primary' text='Aceptar' onClick={handleAccept} />
              <Button color='primary' text='Cerrar' onClick={handleReject} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default EnableComponentDelegate
