import React, { FC, useEffect, useState } from 'react'
import { useLoading } from '../../../Provider/LoadingProvider'
import { Pagination } from '../../../types/types'
import Table, { TableColumn, enumTypeColumn } from '../../Table'

import Modal from '../../GeneralComponents/Modal'

import {
  deleteWorker,
  enableWorker,
  listWorkersByResident,
  regenerateCodeWorker
} from '../../../services/worker'
import { Worker } from '../../../types/worker'
import DeleteButton from '../../Table/components/DeleteButton'
import ViewQr from '../../ViewQr'
import { columnWorker } from './Columns'
import FormDelegateResident from './Form'
import { useAuth } from '../../../Provider/AuthProvider'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { IconButton } from '@mui/material'
import EnableComponent from '../../Table/components/EnableComponent'

const WorkerTable: FC<{ id: string }> = ({ id }) => {
  const [data, setData] = useState<Pagination<Worker>>({
    currentPage: 1,
    hasNextPage: false,
    hasPrevPage: false,
    total: 1,
    totalPages: 1,
    data: []
  })
  const limit = 6
  const { user } = useAuth()
  const [search, setSearch] = useState('')
  const { startLoading, stopLoading } = useLoading()
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<Worker | undefined>(undefined)

  useEffect(() => {
    getData('', 1)
  }, [])

  const onCloseModal = () => {
    setOpen(false)
  }
  const handleOpenModal = () => {
    setOpen(true)
  }
  const handleDelete = async (id: string) => {
    try {
      startLoading()
      await deleteWorker(id)
      getData(search.length > 3 ? search : '', data.currentPage)
      stopLoading()
    } catch (error) {
      console.error(error)
    }
  }
  const handleView = (value: Worker) => {
    setSelected(value)
  }
  const Actions = ({ id, item }: { id: string; item: Worker }) => {
    return (
      <div style={{ display: 'flex' }}>
        <ViewQr
          regenerateCode={(id) => regenerateCodeWorker(id)}
          onSubmit={() =>
            getData(search.length > 3 ? search : '', data.currentPage)
          }
          item={item}
          text={{
            names: `${item.name} ${item.lastName}`,
            clientName: `${user?.client?.name}`,
            type: 'TR',
            destine: item.resident?.destinationCode || ''
          }}
        />
        <IconButton onClick={() => handleView(item as Worker)}>
          <VisibilityIcon style={{ color: 'white' }} />
        </IconButton>
        <DeleteButton OnDelete={() => handleDelete(id)} />
      </div>
    )
  }
  const handlePageChange = (newPage: number) => {
    getData(search.length >= 3 ? search : '', newPage)
  }
  const getData = async (searchValue: string, page: number) => {
    try {
      startLoading()
      const workers = await listWorkersByResident({
        id,
        params: { page, search: searchValue, limit }
      })
      setData(workers)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }
  const handleSubmit = () => {
    setOpen(false)
    getData(search.length > 3 ? search : '', data.currentPage)
  }
  useEffect(() => {
    if (selected) {
      setOpen(true)
    }
  }, [selected])
  const handleChangeEnable = async ({
    value,
    id
  }: {
    value: boolean
    id: string
  }) => {
    try {
      startLoading()
      const form = new FormData()
      form.append('enable', value.toString())
      await enableWorker(id, form)
      getData(search.length > 3 ? search : '', data.currentPage)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }
  const columnsWorkerEnable: TableColumn[] = [
    ...columnWorker,
    {
      title: 'Habilitado',
      dataIndex: 'enable',
      key: 'enable',
      type: enumTypeColumn.custom,
      CustomRender: ({ id, item }: { id: string; item?: Worker }) => {
        if (!item) return null
        return (
          <EnableComponent
            enable={item.enable}
            onUpdate={() => handleChangeEnable({ value: !item?.enable, id })}
          />
        )
      }
    }
  ]
  return (
    <>
      <div className='title'>
        <h2>Trabajadores</h2>
      </div>
      <Table
        onPageChange={handlePageChange}
        onChangeSearch={setSearch}
        pagination={data}
        handleOpenModal={handleOpenModal}
        columns={columnsWorkerEnable}
        Actions={Actions as any}
        itemsPerPage={limit}
      />
      <Modal
        styleContainer={{ width: '900px' }}
        isOpen={open}
        onClose={onCloseModal}>
        <FormDelegateResident
          initialValue={selected}
          residentId={id}
          onSubmit={handleSubmit}
        />
      </Modal>
    </>
  )
}

export default WorkerTable
