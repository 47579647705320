import React, { FC, useEffect, useState, useRef } from 'react'
import { useLoading } from '../../../Provider/LoadingProvider'
import { Pagination } from '../../../types/types'
import Modal from '../../GeneralComponents/Modal'
import Table from '../../Table'

import {
  deleteReceptionist,
  listReceptionistClient
} from '../../../services/receptionist'
import { Receptionist } from '../../../types/receptionist'
import DeleteButton from '../../Table/components/DeleteButton'
import { columnsReceptionist } from './Columns'
import FormReceptionist from './Form'
import { Visibility } from '@mui/icons-material'
import { IconButton } from '@mui/material'
const ReceptionistByClientTable: FC<{ id: string }> = ({ id }) => {
  const [data, setData] = useState<Pagination<Receptionist>>({
    currentPage: 1,
    hasNextPage: false,
    hasPrevPage: false,
    total: 1,
    totalPages: 1,
    data: []
  })
  const [search, setSearch] = useState('')
  const { startLoading, stopLoading } = useLoading()
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<Receptionist | undefined>(undefined)
  const isFirstRender = useRef(true)

  useEffect(() => {
    getDataReceptionist('', 1)
  }, [])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    const delayDebounceFn = setTimeout(() => {
      getDataReceptionist(search, 1)
    }, 600)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const onCloseModal = () => {
    setOpen(false)
    setSelected(undefined)
  }
  const handleOpenModal = () => {
    setOpen(true)
  }
  const handleDelete = async (id: string) => {
    try {
      startLoading()
      await deleteReceptionist(id)
      getDataReceptionist(search, data.currentPage)
      stopLoading()
    } catch (error) {
      console.error(error)
    }
  }
  const handleView = (value: Receptionist) => {
    setSelected(value)
  }
  const Actions = ({ id, item }: { id: string; item?: Receptionist }) => {
    return (
      <>
        <IconButton onClick={() => handleView(item as Receptionist)}>
          <Visibility style={{ color: 'white' }} />
        </IconButton>
        <DeleteButton OnDelete={() => handleDelete(id)} />
      </>
    )
  }
  const handlePageChange = (newPage: number) => {
    getDataReceptionist(search.length >= 3 ? search : '', newPage)
  }

  const getDataReceptionist = async (searchValue: string, page: number) => {
    try {
      startLoading()
      const receptionist = await listReceptionistClient({
        id,
        params: { page, search: searchValue }
      })
      setData(receptionist)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }
  const handleSubmit = () => {
    setOpen(false)
    getDataReceptionist(search, data.currentPage)
  }

  useEffect(() => {
    if (selected) {
      setOpen(true)
    }
  }, [selected])

  return (
    <>
      <div className='title'>
        <h2>Recepcionistas</h2>
      </div>
      <Table
        onPageChange={handlePageChange}
        onChangeSearch={setSearch}
        pagination={data}
        handleOpenModal={handleOpenModal}
        columns={columnsReceptionist}
        Actions={Actions}
      />
      <Modal
        styleContainer={{ width: '900px' }}
        isOpen={open}
        onClose={onCloseModal}>
        <FormReceptionist
          initialValue={selected}
          clientId={id}
          onSubmit={handleSubmit}
        />
      </Modal>
    </>
  )
}

export default ReceptionistByClientTable
