import { cameras } from '../types/cameras'
import { AccessConfig } from '../types/AccessConfig'
import axiosInstance from './axiosInstance'
import axios from 'axios'

//manda a reproducir las camaras que tenga en local gracias al back-Local de IPASS
export const startCamerasLocalRTSP = async (
  cameras: cameras[],
  token: string
): Promise<cameras[]> => {
  try {
    const response = await axios.post(
      `http://localhost:8085/api/cameras/newCams`,
      { cameras },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de marcas:', error)
    return []
  }
}

export const startCamerasLocaFile = async (
  cameras: AccessConfig[],
  token: string
): Promise<cameras[]> => {
  try {
    const response = await axios.post(
      `http://localhost:8085/api/cameras/newCamsFiles`,
      { cameras },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de marcas:', error)
    return []
  }
}

//busca si el cliente posee camaras en local listadas en BD
export const listcameras = async (ClientId: string): Promise<cameras[]> => {
  try {
    const response = await axiosInstance.get(`/api/cameras/${ClientId}`)

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de destinos:', error)
    throw error
  }
}

export const listcamerasFile = async (ClientId: string): Promise<AccessConfig[]> => {
  try {
    const response = await axiosInstance.get(`/api/AccessConfig/cams/${ClientId}`)

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de destinos:', error)
    throw error
  }
}

export const createcameras = async (params: FormData): Promise<cameras> => {
  try {
    const response = await axiosInstance.post('/api/camera', params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })
    return response.data
  } catch (error) {
    console.error('Error en la creación de Marca:', error)
    throw error
  }
}

export const updatecameras = async (id: string, params: FormData): Promise<cameras> => {
  try {
    const response = await axiosInstance.post(`/api/camera/${id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })
    return response.data
  } catch (error) {
    console.error('Error en la creación de Marca:', error)
    throw error
  }
}

export const deletecameras = async (id: string): Promise<void> => {
  try {
    await axiosInstance.delete(`/api/camera/${id}`)
  } catch (error) {
    console.error('Error al eliminar Marca:', error)
    throw error
  }
}
