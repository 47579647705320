import { Client } from './client'

export type IUser = {
  id: string
  name: string
  email: string
  role: roleEnum
  client?: Client
  canApprove?: boolean
  OkId?: boolean
  number: string
  fileMaster?: string
  photo: string
  clientId?: string
  clientSelect: string
}

export enum roleEnum {
  'ADMIN' = 'admin',
  'CORP' = 'corporate',
  'SUPER_ADMIN' = 'super_admin',
  'RECEPTION' = 'reception'
}

export enum roleEnumString {
  'admin' = 'Admin',
  'corporate' = 'Corporate',
  'super_admin' = 'Super admin',
  'reception' = 'Recepción'
}
