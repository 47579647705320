import React, { FC } from 'react'
import { useLoading } from '../../../Provider/LoadingProvider'
import { createDelegate, updateDelegate } from '../../../services/delegateResident'
import { DelegateResident, DelegateResidentCreate } from '../../../types/delegate_resident'
import DynamicForm from '../../Form/DynamicForm'
import { delegateResidentFormConfig } from './Columns'
import './style.scss'
import { useAuth } from '../../../Provider/AuthProvider'
import {} from '../../../utils/utils'

const FormDelegateResident: FC<{
  onSubmit: () => void
  residentId: string
  initialValue?: DelegateResident
}> = ({ onSubmit, initialValue, residentId }) => {
  const { startLoading, stopLoading } = useLoading()
  const { user } = useAuth()

  const handleSubmit = async (formData: DelegateResidentCreate) => {
    try {
      const data = {
        name: formData.name,
        lastName: formData.lastName,
        phone: formData.phone,
        residentId,
        clientId: user?.clientId as string
      }
      startLoading()
      if (initialValue) {
        await updateDelegate(initialValue.id, data)
      } else {
        await createDelegate(data)
      }

      onSubmit()
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  return (
    <div className="newDelegateResidentForm">
      <h2>Nuevo residente delegado</h2>
      <DynamicForm
        initialValues={initialValue}
        formConfig={delegateResidentFormConfig}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

export default FormDelegateResident
