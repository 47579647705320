// delegateResident.ts
import { FieldTypeEnum, FormField } from '../../Form/DynamicForm'
import { TableColumn, enumTypeColumn } from '../../Table'

export const columnDelegateResident: TableColumn[] = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    type: enumTypeColumn.string
  },
  {
    title: 'Apellido',
    dataIndex: 'lastName',
    key: 'lastName',
    type: enumTypeColumn.string
  },
  {
    title: 'Teléfono',
    dataIndex: 'phone',
    key: 'phone',
    type: enumTypeColumn.string
  },
  {
    title: 'Creado en',
    dataIndex: 'createdAt',
    key: 'createdAt',
    type: enumTypeColumn.date
  }
]

export const delegateResidentFormConfig: FormField[] = [
  { type: FieldTypeEnum.text, label: 'Nombre', name: 'name' },
  { type: FieldTypeEnum.text, label: 'Apellido', name: 'lastName' },
  { type: FieldTypeEnum.phone, label: 'Teléfono', name: 'phone' }
]
