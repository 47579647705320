import React, { HTMLInputTypeAttribute, useState, useRef } from 'react'
import './style.scss'

interface InputProps {
  icon?: string
  placeholder?: string
  value?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  label?: string
  type?: HTMLInputTypeAttribute
  color?: 'dark' | 'white'
}

const Input: React.FC<InputProps> = ({
  icon,
  placeholder,
  value,
  type,
  onChange,
  label
}) => {
  const [selected, setSelected] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleContainerClick = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  return (
    <div className={`input-container`} onClick={handleContainerClick}>
      {label && <label className='label'>{label}</label>}
      <div className={`icon-input-container ${selected ? 'selected' : ''}`}>
        {icon && <img className='icon' src={icon} alt={placeholder} />}
        <input
          ref={inputRef}
          type={type}
          onFocus={() => setSelected(true)}
          onBlur={() => setSelected(false)}
          className='input-element'
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export default Input
