import {
  GeneralData,
  searchReport,
  GeneralDataCorp,
  detailReport,
  DashCheckPoint,
  detailCheckReport,
  DashCheckReport
} from '../types/dashboard'
import axiosInstance from './axiosInstance'

export const getGeneralDataByClientId = async (id: string): Promise<GeneralData> => {
  try {
    const response = await axiosInstance.get(`/api/dash_board/reports/${id}`)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('error dashboard reports Get', error)
    throw error
  }
}

export const getReportForDate = async (
  date: [string, string],
  clientId: string,
  token: string
): Promise<searchReport[]> => {
  try {
    const response = await axiosInstance.get(`/api/dash_board/report_for_date`, {
      params: { date, clientId },
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('error dashboard reports Get', error)
    throw error
  }
}

export const getLogsTicket = async (id: string): Promise<detailReport> => {
  try {
    const response = await axiosInstance.get(`/api/dash_board/reports_ticket/${id}`)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('error dashboard reports Get', error)
    throw error
  }
}

export const getLogsCheckPoint = async (id: string): Promise<detailCheckReport> => {
  try {
    const response = await axiosInstance.get(`/api/dash_board/reports_CheckPoint/${id}`)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('error dashboard reports Get', error)
    throw error
  }
}

export const getGeneralDataCorp = async (
  id: string,
  month: string,
  listId: string
): Promise<GeneralDataCorp> => {
  try {
    const response = await axiosInstance.get(`/api/dash_board/corp/${id}/${month}/${listId}`)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('error dashboard corp Get', error)
    throw error
  }
}

export const getGeneralCheckpointByClientId = async (id: string): Promise<DashCheckPoint> => {
  try {
    const response = await axiosInstance.get(`/api/dash_board/reportsCheckpoint/${id}`)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('error dashboard reports Get', error)
    throw error
  }
}

export const getdashCheckReportFilter = async (
  id: string,
  filter: any
): Promise<DashCheckReport[]> => {
  try {
    const response = await axiosInstance.get(
      `/api/dash_board/filter_report_check_point_filter/${id}`,
      { params: { ...filter } }
    )
    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('error dashboard reports Get', error)
    throw error
  }
}
