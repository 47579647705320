import React from 'react'
import { DatePicker, ConfigProvider } from 'antd'
import es_ES from 'antd/locale/es_ES' // Importa la localización en español
import dayjs from 'dayjs'
import 'dayjs/locale/es' // Importa el idioma español para dayjs
import type { Dayjs } from 'dayjs'
import './style.scss'

dayjs.locale('es') // Configura el idioma a español

interface DatePickerProps {
  defaultValue?: Dayjs[]
  onChange?: (date: Dayjs[] | null, dateString: string | string[]) => void
  label?: string
}

const MultiDatePicker: React.FC<DatePickerProps> = ({
  defaultValue = [],
  onChange,
  label
}) => {
  const fechas =
    !defaultValue || defaultValue.length === 0
      ? null
      : defaultValue.map((day) => {
          return dayjs(day)
        })

  return (
    <ConfigProvider locale={es_ES}>
      <div className='date-picker-container'>
        {label && <label className='input-label'>{label}</label>}
        <div className='date-picker-wrapper'>
          <DatePicker
            placeholder={label}
            multiple
            onChange={onChange}
            maxTagCount='responsive'
            defaultValue={fechas}
            className='date-picker-wrapper'
            popupClassName='date-picker-dropdown'
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              color: 'white',
              fontFamily: 'inherit',
              width: '100%',
              padding: '15px'
            }}
          />
        </div>
      </div>
    </ConfigProvider>
  )
}

export default MultiDatePicker
