import React, { FC } from 'react'
import Modal from '../GeneralComponents/Modal'
import Button from '../Form/Button'
import QrCode from 'qrcode.react'
import './styles.scss'
const ShowQr: FC<{
  isModalOpen: boolean
  qr: string
  handleCloseModal: () => void
}> = ({ isModalOpen, handleCloseModal, qr }) => {
  const print = () => {
    window.print()
  }
  const drawBackground = (elem: HTMLImageElement) => {
    // create a new canvas
    const c = document.createElement('canvas')
    // set its width&height to the required ones
    // draw our canvas to the new one
    c.height = elem.height + 9
    c.width = elem.width + 9
    const ctx = c.getContext('2d') as CanvasRenderingContext2D
    ctx.fillStyle = '#fff'
    ctx.fillRect(0, 0, elem.width + 9, elem.height + 9)

    ctx.drawImage(
      elem,
      0,
      0,
      elem.width + 9,
      elem.height + 9,
      7,
      7,
      elem.width,
      elem.height
    )
    // return the resized canvas dataURL
    return c.toDataURL('image/jpeg')
  }
  const download = () => {
    const canvas = document.getElementById(
      'qr-Printer'
    ) as HTMLCanvasElement | null
    if (canvas) {
      // const imageData = canvas.toDataURL('image/jpeg').toString()
      const img = new Image()
      img.src = canvas.toDataURL('image/jpeg')
      img.onload = () => {
        const imageData = drawBackground(img)
        const downloadLink = document.createElement('a')
        downloadLink.href = imageData
        downloadLink.download = `qr.jpeg`
        downloadLink.click()
      }
    }
  }
  return (
    <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
      <div className='qr-modal-container'>
        <h3>Código visitante</h3>
        <div className='qr-code-container'>
          <QrCode id={'qr-Printer'} value={qr} size={300} level={'M'} />
        </div>
        <div className='button-container'>
          <Button text='Imprimir' onClick={print} />
          <Button text='Descargar' onClick={download} />
        </div>
      </div>
    </Modal>
  )
}

export default ShowQr
