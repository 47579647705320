import React, { useState, useRef } from 'react'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

const ZoomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'transparent',
    padding: 0,
    maxWidth: 'none',
    boxShadow: theme.shadows[1]
  }
}))

const ZoomImage = ({
  src,
  alt,
  className,
  disableZoom
}: {
  src: string
  alt: string
  className?: string
  disableZoom?: boolean
}) => {
  const [scale, setScale] = useState(1)
  const [translate, setTranslate] = useState({ x: 0, y: 0 })
  const imgRef = useRef<HTMLImageElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)

  const handleWheel = (event: React.WheelEvent) => {
    if (event.deltaY < 0) {
      setScale(prevScale => Math.min(prevScale + 0.1, 3))
    } else {
      setScale(prevScale => Math.max(prevScale - 0.1, 1))
    }
  }

  const handleMouseMove = (event: React.MouseEvent) => {
    if (containerRef.current && imgRef.current) {
      const { left, top, width, height } = containerRef.current.getBoundingClientRect()
      const offsetX = (event.clientX - left) / width
      const offsetY = (event.clientY - top) / height
      setTranslate({
        x: (offsetX - 0.5) * (scale - 1) * 100,
        y: (offsetY - 0.5) * (scale - 1) * 100
      })
    }
  }

  return (
    <div
      ref={containerRef}
      className={className}
      style={{
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40vw',
        height: '40vh',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        borderRadius: '8px',
        zIndex: 1300
      }}
      onWheel={!disableZoom ? handleWheel : undefined}
      onMouseMove={!disableZoom ? handleMouseMove : undefined}
    >
      <img
        ref={imgRef}
        src={src}
        alt={alt}
        style={{
          transform: `scale(${scale}) translate(${translate.x}%, ${translate.y}%)`,
          transition: 'transform 0.2s',
          maxHeight: '100%',
          maxWidth: '100%',
          cursor: scale > 1 ? 'move' : 'default'
        }}
      />
    </div>
  )
}

const PhotoTooltip = ({
  photos,
  altText,
  className,
  selectPlacement,
  altPhoto
}: {
  photos: string[]
  altText: string
  className?: string
  altPhoto: string
  selectPlacement?: TooltipProps['placement']
}) => {
  // Filtrar fotos válidas
  const validPhotos = photos.filter(photo => photo && photo !== 'undefined' && photo !== '')

  return (
    <ZoomTooltip
      title={
        photos[1] && photos[2]  ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row', // Asegura que las imágenes se dispongan en fila
              justifyContent: 'center',
              alignItems: 'center',
              width: '40vw',
              height: '40vh',
              gap: '10px',
              flexWrap: 'wrap' // Permite que las imágenes se envuelvan si es necesario
            }}
          >
            {validPhotos.slice(1).map((photo, index) => (
              <img
                key={index}
                src={photo}
                alt={`${altText}-${index}`}
                style={{
                  width: 'auto',
                  height: '100%',
                  objectFit: 'contain',
                  borderRadius: '10px'
                }}
              />
            ))}
          </div>
        ) : validPhotos.length === 0 ? (
          <></>
        ) : (
          <ZoomImage
            src={validPhotos[0]}
            alt={altText}
            className={className}
            disableZoom={false}
          />
        )
      }
      {...(validPhotos.length > 0 && {
        arrow: true,
        placement: selectPlacement ? selectPlacement : 'top'
      })}
    >
      <img
        className={className}
        src={validPhotos[0] !== '' && validPhotos[0] ? validPhotos[0] : altPhoto}
        alt={altText}
        style={{ width: '30px', height: '30px', objectFit: 'cover', borderRadius: '50%' }}
      />
    </ZoomTooltip>
  )
}

export default PhotoTooltip
