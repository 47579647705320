// pages/ResidentPage.tsx
import React, { useEffect, useState, useRef } from 'react'

import { useLoading } from '../../Provider/LoadingProvider'
import { columnsResident } from '../../components/Tables/Resident/Columns'
import FormResident from '../../components/Tables/Resident/Form'
import { Pagination } from '../../types/types'
import './style.scss'
import { Notifications, NotificationsOff, Visibility } from '@mui/icons-material'
import { NotificationResident } from '../../services/resident'
import { LightTooltip } from '../../components/tooltips/text'
import { IconButton, Tooltip, Fade } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../Provider/AuthProvider'
import EnableComponentResident from '../../components/EnableComponentResident'
import Button from '../../components/Form/Button'
import Modal from '../../components/GeneralComponents/Modal'
import LockNotification from '../../components/LockNotification'
import QrLimit from '../../components/QrLimit'
import Table, { TableColumn, enumTypeColumn } from '../../components/Table'
import DeleteButton from '../../components/Table/components/DeleteButton'
import ViewQr from '../../components/ViewQr'
import RefreshIcon from '@mui/icons-material/Refresh'
import {
  deleteResident,
  enableResident,
  listResidents,
  regenerateCode,
  resetResidentQr
} from '../../services/resident'

import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Resident } from '../../types/resident'
import UploadExcel from './UploadExcel'

const ResidentPage = () => {
  const { user } = useAuth()
  const [data, setData] = useState<Pagination<Resident>>({
    currentPage: 1,
    hasNextPage: false,
    hasPrevPage: false,
    total: 1,
    totalPages: 1,
    data: []
  })

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [search, setSearch] = useState('')
  const { startLoading, stopLoading } = useLoading()
  const navigate = useNavigate()
  const limit = 6
  const isFirstRender = useRef(true)

  useEffect(() => {
    getData('', 1)
  }, [])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    const delayDebounceFn = setTimeout(() => {
      getData(search, 1)
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const onCloseModal = () => {
    setIsOpenModal(false)
  }

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }
  const toggleNotification = async (residentId: string, notification: boolean) => {
    try {
      startLoading()
      await NotificationResident(residentId, !notification)
      await getData(search, data.currentPage)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const getData = async (searchValue: string, page: number) => {
    try {
      startLoading()
      const residents = await listResidents({
        params: { page, search: searchValue, limit },
        id: user?.clientId as string
      })
      setData(residents)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const handleSubmit = async () => {
    setIsOpenModal(false)
    await getData(search, data.currentPage)
  }

  const handlePageChange = (newPage: number) => {
    getData(search, newPage)
  }

  const handleDelete = async (id: string) => {
    try {
      startLoading()
      await deleteResident(id)
      await getData(search, data.currentPage)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }
  const handleView = (id: string) => {
    navigate(`/resident/${id}`)
  }
  const resetQr = async (id: string) => {
    try {
      startLoading()
      await resetResidentQr(id)
      await getData(search, data.currentPage)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }
  const Actions = ({ id, item }: { id: string; item: Resident }) => {
    return (
      <div className="actionsContainer">
        <div onClick={() => toggleNotification(item.id, item.enableNotification)}>
          {item.enableNotification ? (
            <Notifications style={{ color: 'green' }} />
          ) : (
            <NotificationsOff style={{ color: 'red' }} />
          )}
        </div>
        {!user?.client?.infiniteGeneration &&
          item.currentGeneration >= (user?.client?.maxGeneration as number) && (
            <Tooltip title="Reiniciar generación QR" arrow>
              <IconButton onClick={() => resetQr(id)}>
                <RefreshIcon style={{ color: 'green' }} />
              </IconButton>
            </Tooltip>
          )}
        <ViewQr
          regenerateCode={id => regenerateCode(id)}
          onSubmit={() => getData(search, data.currentPage)}
          item={item}
          text={{
            clientName: `${user?.client?.name}`,
            names: `${item.name} ${item.lastName}`,
            type: 'RP',
            destine: item.destinationCode
          }}
        />
        <IconButton onClick={() => handleView(id)}>
          <Visibility style={{ color: 'white' }} />
        </IconButton>
        <DeleteButton OnDelete={() => handleDelete(id)} />
      </div>
    )
  }
  const handleChangeEnable = async ({
    value,
    visitor,
    guest,
    worker,
    delegate,
    id
  }: {
    value: boolean
    visitor: boolean
    guest: boolean
    worker: boolean
    delegate: boolean
    id: string
  }) => {
    try {
      startLoading()
      await enableResident(id, {
        enable: value,
        visitor,
        guest,
        worker,
        delegate
      })
      getData(search, data.currentPage)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const columnsResidentEnable: TableColumn[] = [
    ...columnsResident,
    {
      title: 'Delegados',
      dataIndex: 'delegatedQuantity',
      key: 'delegatedQuantity',
      type: enumTypeColumn.custom,
      CustomRender: ({ item }: { item?: Resident }) => {
        if (!item) return null
        return (
          <LightTooltip
            title={
              item.delegateResidents.length > 0
                ? item.delegateResidents.map((delegate, index) => (
                    <span key={index}>
                      <FontAwesomeIcon
                        icon={faCircle}
                        style={{ textAlign: 'start' }}
                        className={delegate.enable ? 'green' : 'red'}
                      />
                      {` ${delegate.name} ${delegate.lastName}`}
                      {index < item.delegateResidents.length - 1 && <br />}
                    </span>
                  ))
                : 'SIN DELEGADOS'
            }
            followCursor
            TransitionComponent={Fade}
          >
            <div className="containerDelegatesQuantity">
              <div>{item.delegatedQuantity}</div>
            </div>
          </LightTooltip>
        )
      }
    },
    {
      title: 'Habilitado',
      dataIndex: 'enable',
      key: 'enable',
      type: enumTypeColumn.custom,
      CustomRender: ({ id, item }: { id: string; item?: Resident }) => {
        if (!item) return null
        return (
          <div className="enableContainer">
            <EnableComponentResident
              enable={item.enable as boolean}
              visitor={item.enableVisitor as boolean}
              guest={item.enableGuest as boolean}
              worker={item.enableWorker as boolean}
              delegate={item.enableDelegate as boolean}
              onUpdate={({ Resident, Visitor, Guest, Employer, Delegate }) =>
                handleChangeEnable({
                  value: Resident,
                  id,
                  visitor: Visitor,
                  guest: Guest,
                  worker: Employer,
                  delegate: Delegate
                })
              }
            />
            <span className={`${item.enableVisitor ? 'green' : 'red'}`}>V</span>
            <span className={`${item.enableGuest ? 'green' : 'red'}`}>I</span>
            <span className={`${item.enableWorker ? 'green' : 'red'}`}>T</span>
            <span className={`${item.enableDelegate ? 'green' : 'red'}`}>D</span>
          </div>
        )
      }
    }
  ]

  const downloadTemplate = () => {
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = `${process.env.REACT_APP_API_URL_BACKEND}/template`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  return (
    <>
      <div className="residentContainer">
        <div className="containerTitle">
          <h4>Residentes</h4>
        </div>
        <div className="tableContainer">
          <Table
            onPageChange={handlePageChange}
            onChangeSearch={setSearch}
            pagination={data}
            AdditionalButtons={() => (
              <div style={{ display: 'flex' }}>
                <QrLimit />
                <LockNotification />
                <Button
                  onClick={downloadTemplate}
                  style={{ marginRight: '10px' }}
                  text="Plantilla"
                />
                <UploadExcel onClose={() => getData('', 1)} />
              </div>
            )}
            itemsPerPage={limit}
            handleOpenModal={handleOpenModal}
            columns={columnsResidentEnable}
            Actions={Actions as any}
          />
        </div>
      </div>
      <Modal isOpen={isOpenModal} onClose={onCloseModal}>
        <FormResident onSubmit={handleSubmit} />
      </Modal>
    </>
  )
}

export default ResidentPage
