import React, { useState } from 'react'
import Button from '../../../components/Form/Button'
import { Corporate } from '../../../types/Corporate'

import './styles.scss'

export interface KeyValue {
  [key: string]: string
}

export interface MultiSelectCellsProps {
  items: KeyValue
  initialSelected: KeyValue
  personalSelect: Corporate | undefined
  onSubmit: (updatedSelected: KeyValue) => void // Función de callback para actualizar el estado externo
}

const MultiSelectCells: React.FC<MultiSelectCellsProps> = ({
  items,
  initialSelected,
  personalSelect,
  onSubmit
}) => {
  const [selectedItems, setSelectedItems] = useState<KeyValue>(initialSelected)

  const handleSelect = (key: string) => {
    const updatedSelected = { ...selectedItems }
    if (selectedItems[key]) {
      delete updatedSelected[key]
    } else {
      updatedSelected[key] = items[key]
    }
    setSelectedItems(updatedSelected)
  }

  const handleButtonClick = () => {
    onSubmit(selectedItems)
  }

  return (
    <div className='ContainerCorp'>
      <h2>{`Clientes asignados a Corporativo ${personalSelect?.name}`}</h2>
      <div className='multi-select-container'>
        {Object.entries(items).map(([key, value]) => (
          <div
            key={key}
            onClick={() => handleSelect(key)}
            className={`select-item ${selectedItems[key] ? 'selected' : ''}`}>
            {value}
          </div>
        ))}
      </div>
      <Button
        className='update-button'
        text='Actualizar'
        type='button'
        onClick={handleButtonClick}
      />
    </div>
  )
}

export default MultiSelectCells
