import React, { FC } from 'react'
import { useLoading } from '../../../Provider/LoadingProvider'
import DynamicForm from '../../Form/DynamicForm'
import { FormConfigPaymentConcept } from './Columns'
import './styles.scss'
import { PaymentConcept, PaymentConceptCreate } from '../../../types/Payments'
import { createPaymentConcept, updatePaymentConcept } from '../../../services/Payments'

const FormPaymentconcepts: FC<{
  onSubmit: () => void
  clientId: string
  initialValue?: PaymentConcept
}> = ({ onSubmit, initialValue, clientId }) => {
  const { startLoading, stopLoading } = useLoading()

  const handleSubmit = async (formData: PaymentConceptCreate) => {
    try {
      startLoading()
      const data = new FormData()
      Object.entries({
        name: formData.name,
        type: formData.type,
        price: formData.price,
        global: formData.global,
        icon: formData.icon ? formData.icon : '',
        clientId
      }).forEach(([key, value]) => {
        data.append(key, value !== undefined ? String(value) : '')
      })

      if (initialValue) {
        await updatePaymentConcept(initialValue.id, data)
      } else {
        await createPaymentConcept(data)
      }

      onSubmit()
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  return (
    <div className="newClientForm">
      <h2>{initialValue ? 'Actualizar concepto' : 'Nuevo concepto'}</h2>
      <DynamicForm
        initialValues={initialValue}
        formConfig={FormConfigPaymentConcept}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

export default FormPaymentconcepts
