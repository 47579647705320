import React, { useEffect, useState } from 'react'
import './style.scss'
import { useAuth } from '../../Provider/AuthProvider'
import { get_whatsapp, reset_whatsapp } from '../../services/whatsapp'
import { io } from 'socket.io-client'
import QrCode from 'qrcode.react'
import Button from '../../components/Form/Button'
import Modal from '../../components/GeneralComponents/Modal'

const WhatsappPage = () => {
  const { user, token } = useAuth()
  const [status, setStatus] = useState('... Cargando')
  const [open, setOpen] = useState<boolean>(false)
  const [qr, setQr] = useState('')
  useEffect(() => {
    if (user?.clientId || user?.role === 'super_admin') {
      getData()
    }
  }, [user])

  useEffect(() => {
    const socket = io(
      process.env.REACT_APP_API_URL_WHATS || ('https://whats.ipass.com.gt' as string)
    )
    if (status === 'esperando') {
      // socket.on('connect', () => {
      //   console.info('Conectado al servidor de socket')
      // })
      const code = user?.role === 'super_admin' ? user.id : user?.clientId
      socket.on(`qr_code_${code}`, (message: string) => {
        setQr(message)
      })
      socket.on(`autenticated_${code}`, (message: string) => {
        setStatus(message)
      })
    }

    return () => {
      socket.disconnect()
    }
  }, [status])

  const getData = async () => {
    try {
      const { status } = await get_whatsapp(user?.clientId as string, token)
      setStatus(status)
    } catch (error) {
      console.error('Error fetching WhatsApp status:', error)
    }
  }

  const handleReset = async (acceptWaring: boolean) => {
    try {
      if (
        status === 'Desconectado' ||
        status === 'esperando' ||
        user?.role === 'super_admin' ||
        acceptWaring
      ) {
        const { status: data } = await reset_whatsapp(user?.clientId as string, token)
        setStatus(data)
        setQr('')
        setOpen(false)
      } else {
        setOpen(true)
      }
    } catch (error) {
      console.error('Error resetting WhatsApp:', error)
    }
  }

  const Waring: React.FC = () => {
    return (
      <>
        <div className="WaringModal">
          <div className="Waring-header">
            <h2>ADVERTENCIA</h2>
          </div>
          <div className="Waring-text">
            Estas a punto de reiniciar la conexión de WhatsApp, esta acción se notificara al
            administrador y corporativo. <br />
            <br />
            <span>¿Deseas continuar?</span>
          </div>

          <div className="containerButtons">
            <Button
              text="SI"
              styleType={`normal`}
              onClick={() => {
                handleReset(true)
              }}
            />

            <Button
              text="NO"
              styleType={`normal`}
              onClick={() => {
                setOpen(false)
              }}
            />
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="whatsAppContainer">
      <div className="title">
        <p>Configuración de whatsapp</p>
      </div>
      <h2>Estado actual : {status}</h2>

      {qr && status !== 'activado' ? (
        <div>
          <h2>Escanea este código para activar whatsapp</h2>
          <QrCode
            style={{ border: '5px solid white', borderRadius: '10px' }}
            id={'qr-whatsapp'}
            value={qr}
            size={300}
            level={'M'}
          />
        </div>
      ) : (
        <></>
      )}

      <div>
        <Button
          text="Reiniciar"
          onClick={() => {
            handleReset(false)
          }}
        />
      </div>

      <Modal
        styleContainer={{ width: '480px', height: '270px' }}
        isOpen={open}
        onClose={() => {
          setOpen(false)
        }}
      >
        <Waring />
      </Modal>
    </div>
  )
}

export default WhatsappPage
