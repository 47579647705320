import React from 'react'
import { useAuth } from '../../../Provider/AuthProvider'
import './style.scss'
import { roleEnum, roleEnumString } from '../../../types/user'
const UserInfo = () => {
  const { user } = useAuth()

  return (
    <div className='containerUserInfo'>
      <div className='imageContainer'>
        <img
          src={
            user?.photo !== 'undefined' ? user?.photo : `images/icons/user.png`
          }
          alt=''
        />
      </div>
      <div className='textContainer'>
        <h3>{user?.name}</h3>
        <p>{roleEnumString[user?.role as roleEnum]}</p>
        {user?.client?.name && <p>Cliente : {user?.client?.name}</p>}
      </div>
    </div>
  )
}

export default UserInfo
