import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import './style.scss'
import { useAuth } from '../../Provider/AuthProvider'
import { io } from 'socket.io-client'
import { GeneralPayment, searchPayment } from '../../types/Payments'
import ReactECharts from 'echarts-for-react'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { IconButton } from '@mui/material'
import { getGeneralPaymentByClientId } from '../../services/Payments'
import { useLoading } from '../../Provider/LoadingProvider'
import Modal from '../../components/GeneralComponents/Modal'
import SearchIcon from '@mui/icons-material/Search'
import Select from '../../components/Form/select2'
import ExportToExcel from './generateXML'
import PhotoTooltip from '../../components/tooltips/img'
import PaymentsDetails from '../../components/Tables/PaymentsDetails'
import moment from 'moment'

const Accreditations = () => {
  const { user } = useAuth()
  const { startLoading, stopLoading } = useLoading()
  const { id } = useParams<{ id: string }>()
  const [idSearch, setIdSearch] = useState<string | undefined>(undefined)

  const [GeneralData, setGeneralData] = useState<GeneralPayment | null>()
  const [selected, setSelected] = useState<{ id: string; action: string } | undefined>(
    undefined
  )
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [search] = useState({
    confirmed: ``,
    destinationCode: '',
    order: ''
  })

  const [filteredReports, setFilteredReports] = useState<searchPayment[]>([])

  useEffect(() => {
    const socket = io(
      process.env.REACT_APP_API_URL_WHATS || ('https://whats.ipass.com.gt' as string)
    )
    if (user?.clientId) {
      getGeneralData(user?.clientId)

      socket.on(`payment-${user?.clientId}`, (message: string) => {
        getGeneralData(message)
      })
    }

    return () => {
      socket.disconnect()
    }
  }, [])

  useEffect(() => {
    const dataId = user?.role === 'corporate' ? (id as string) : user?.clientId
    setIdSearch(dataId)
  }, [id, idSearch, setIdSearch, user])

  useEffect(() => {
    if (GeneralData && GeneralData.Reports) {
      const filtered = GeneralData.Reports.filter(report => {
        return (
          search.destinationCode === '' ||
          report.destinationCode.includes(search.destinationCode)
        )
      })

      const sorted = filtered.sort((a, b) => {
        if (search.order === 'desc') {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        } else {
          return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        }
      })

      setFilteredReports(sorted)
    }
  }, [GeneralData, search])

  const getGeneralData = async (id: string) => {
    try {
      startLoading()
      const data = await getGeneralPaymentByClientId(id)
      setGeneralData(data)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  if (!GeneralData || !user?.clientId)
    return (
      <div className="AccreditationsContainer">
        <div className="containerTitle">
          <h4>Acreditamientos</h4>
        </div>
      </div>
    )

  const closeModal = () => {
    setIsModalOpen(false)
    setSelected(undefined)
    if (idSearch) {
      getGeneralData(idSearch)
    }
  }

  const barChartOptions = {
    title: {
      text: `Mes en curso ${moment(Date.now()).format('MM YYYY')}`,
      subtext: `CUOTA/RESERVA/MORA`,
      left: 'center',
      textStyle: {
        color: '#fff'
      }
    },
    tooltip: {
      trigger: 'axis'
    },
    xAxis: {
      type: 'category',
      data: ['TOTAL', 'CUOTA', 'RESERVA', 'MORA']
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        name: 'TOTAL',
        type: 'bar',
        data: [
          GeneralData.grafTotal.payment + GeneralData.grafTotal.noPayment,
          GeneralData.grafQuotas.payment + GeneralData.grafQuotas.noPayment,
          GeneralData.grafReservations.payment + GeneralData.grafReservations.noPayment,
          GeneralData.grafLatePayments.payment + GeneralData.grafLatePayments.noPayment
        ],
        itemStyle: {
          color: '#f600c0' // Color personalizado para 'TOTAL'
        }
      },
      {
        name: 'ACREDITADOS',
        type: 'bar',
        data: [
          GeneralData.grafTotal.payment,
          GeneralData.grafQuotas.payment,
          GeneralData.grafReservations.payment,
          GeneralData.grafLatePayments.payment
        ],
        itemStyle: {
          color: '#cc00aa'
        }
      },
      {
        name: 'RESIDENTES AL DIA',
        type: 'bar',
        data: [
          GeneralData.grafTotal.residentsPayment,
          GeneralData.grafQuotas.residentsPayment,
          GeneralData.grafReservations.residentsPayment,
          GeneralData.grafLatePayments.residentsPayment
        ],
        itemStyle: {
          color: '#990093'
        }
      },
      {
        name: 'RESIDENTES POR PAGAR',
        type: 'bar',
        data: [
          GeneralData.grafTotal.residentsNoPayment,
          GeneralData.grafQuotas.residentsNoPayment,
          GeneralData.grafReservations.residentsNoPayment,
          GeneralData.grafLatePayments.residentsNoPayment
        ],
        itemStyle: {
          color: '#4409a4' // Color personalizado para 'TOTAL'
        }
      }
    ]
  }

  return (
    <div className="AccreditationsContainer">
      <div className="containerTitle">
        <h4>Acreditamientos</h4>
      </div>
      {GeneralData && (
        <div>
          {selected && (
            <>
              {selected.action === 'viewPayment' && (
                <Modal
                  isOpen={isModalOpen}
                  styleContainer={{
                    width: '100%',
                    padding: '0px'
                  }}
                  onClose={closeModal}
                >
                  <PaymentsDetails PaymentId={selected.id} onClose={closeModal} />
                </Modal>
              )}
            </>
          )}

          <div className="accreditations-graf">
            <ReactECharts
              option={barChartOptions}
              style={{ height: '400px', width: '100%' }}
            />
          </div>

          <div className="accreditations-search-section">
            <h3 className="accreditations-barStatus">ESTATUS</h3>

            <Select
              placeholder={'Estado'}
              options={[
                { value: 'true', label: 'confirmado' },
                { value: 'false', label: 'Por confirmar' }
              ]}
              value={`Estado`}
              Name="confirmed"
              onChange={value => console.log(value)}
            />
            <Select
              placeholder={'Orden'}
              options={[
                { value: 'asc', label: 'Recientes' },
                { value: 'desc', label: 'Antiguos' }
              ]}
              value={`Orden`}
              Name="order"
              onChange={value => console.log(value)}
            />
            <div className="container-button-excel">
              <ExportToExcel filteredReports={filteredReports} />
            </div>
            <div className="accreditations-search-bar-container">
              <div className="accreditations-search-icon">
                <SearchIcon style={{ color: 'white' }} />
              </div>
              <input
                name="destinationCode"
                onChange={value => console.log(value)}
                type="text"
                className="accreditations-search-input"
                placeholder="Destino..."
              />
            </div>
          </div>

          <div className="accreditations-container">
            <div className="order header">
              <span>DESTINO</span>
              <span>RESIDENTE</span>
              <span>MONTO</span>
              <span>CONCEPTO</span>
              <span>FECHA</span>
              <span>IMAGEN</span>
              <span>CUENTA</span>
              <span>ESTATUS</span>
              <span>ACCION</span>
            </div>

            {filteredReports.map((report, index) => (
              <div key={index} className="order Payments">
                <span>{report.destinationCode}</span>
                <span>{report.residentname}</span>
                <span>{report.amount}</span>
                <span>{report.concept}</span>
                <span>{report.createText}</span>

                <PhotoTooltip
                  photos={[report.photo]}
                  altText={report.concept}
                  altPhoto="images/logo_inicio.png"
                  selectPlacement="left-start"
                  className="custom-tooltip"
                />

                <span>{report.statusResident}</span>
                <span>{report.status}</span>

                <IconButton
                  onClick={() => {
                    setSelected({ id: report.id, action: 'viewPayment' })
                    setIsModalOpen(true)
                  }}
                >
                  <VisibilityIcon style={{ color: 'white' }} />
                </IconButton>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Accreditations
