// ModalCamera.tsx
import SettingsIcon from '@mui/icons-material/Settings'
import { IconButton } from '@mui/material'
import React, { FC, useState, useRef, useEffect } from 'react'
import { useAuth } from '../../../Provider/AuthProvider'
import { useCamera } from '../../../Provider/CameraProvider'
import { useLoading } from '../../../Provider/LoadingProvider'
import { createVisitor } from '../../../services/visitor'
import { printQRCode } from '../../../utils/printer'
import Button from '../../Form/Button'
import Modal from '../../GeneralComponents/Modal'
import PopUp from '../../GeneralComponents/PopUp'
import ShowQr from '../../ShowQr'
import FormModalCamera from '../FormModalCamera'
import NewCamera from '../NewCameraComponent'
import PopUpConfig from '../PopUpConfig'
import { Toaster, toast } from 'sonner'
import { listBrandsAll } from '../../../services/brand'
import { brand } from '../../../types/brand'
import './styles.scss'

type ModalProps = {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}

const ModalCamera: FC<ModalProps> = ({ isOpen, onClose, onOpen }) => {
  const [showCameraConfigPopup, setShowCameraConfigPopup] = useState(false)
  const [brands, setBrands] = useState<brand[]>([])

  const [images, setImages] = useState<{
    photo: File | null
    DNI_A: File | null
    DNI_B: File | null
  }>({
    photo: null,
    DNI_A: null,
    DNI_B: null
  })
  const [showPopup, setShowPopup] = useState(false)
  const [formValues, setFormValues] = useState({
    brand: '',
    destinationCode: ''
  })
  const selectRef = useRef<HTMLInputElement>(null)
  const { cameraDNI, cameraPhoto } = useCamera()

  const [showQr, setShowQr] = useState('')
  const { user, token } = useAuth()
  const { startLoading, stopLoading } = useLoading()

  useEffect(() => {
    if (!brands.length /*  || !destine.length */) {
      getData()
    }
  }, [])

  const getData = async () => {
    try {
      const brands = await listBrandsAll(token)
      setBrands(brands)
    } catch (error) {
      console.error(error)
    }
  }
  const handleEntendidoClick = (openAgain?: boolean) => {
    setShowPopup(false)
    onClose()
    setFormValues({
      brand: '',
      destinationCode: ''
    })
    setImages({
      photo: null,
      DNI_A: null,
      DNI_B: null
    })

    if (openAgain) {
      setTimeout(() => {
        onOpen()
        if (selectRef.current) {
          selectRef.current.focus() // Enfocar el campo de texto automáticamente
        }
      }, 4000)
    }
  }

  const handleCheckIn = async () => {
    try {
      startLoading()
      const formData = new FormData()

      Object.entries(formValues).forEach(([key, value]) => {
        formData.append(key, value)
      })
      Object.entries(images).forEach(([key, value]) => {
        formData.append(key, value as Blob)
      })
      formData.append('clientId', user?.clientId as string)

      const getData = async () => {
        try {
          const visitor = await createVisitor(formData, token)
          if (visitor) {
            toast.success('El check-in se registró correctamente', {
              description: `${visitor.name} ${visitor.lastName}`,
              position: 'top-right'
            })
          } else {
            toast.success('El check-in se registró correctamente', {
              position: 'top-right'
            })
          }
          return visitor
        } catch (error: any) {
          if (error?.response?.data?.msg) {
            toast.warning(`${error?.response?.data?.msg}`, {
              position: 'top-right'
            })
            throw new Error(`${error?.response?.data?.msg}`)
          } else if (error?.response?.status === 400) {
            toast.warning(`Falta información`, {
              position: 'top-right'
            })
            throw new Error(`${error?.response?.data?.msg}`)
          } else {
            toast.error(`Error desconocido`, {
              position: 'top-right'
            })
            throw new Error(`${error?.response?.data?.msg}`)
          }
        }
      }

      // Llamar a la función al montar el componente
      const entry = await getData()

      await printQRCode(entry.qrCode as string, entry, user?.client?.name || '')
      handleEntendidoClick(true)
      // showQrFn(visitor.qrCode)
      //setShowPopup(true)
    } catch (error) {
      console.error('API Error:', error)
    } finally {
      stopLoading()
    }
  }

  if (!isOpen) return null
  return (
    <>
      <Modal
        styleContainer={{ padding: 0, maxHeight: '90vh', height: '100%' }}
        styleContainerContent={{
          padding: 0,
          height: '100%',
          maxHeight: '90vh',
          overflow: 'hidden'
        }}
        isOpen={isOpen}
        onClose={onClose}
      >
        <div className="containerModalCamera">
          <div className="containerTitle">
            <div className="leftSection">
              <IconButton
                className="buttonConfiguracion"
                onClick={() => setShowCameraConfigPopup(true)}
              >
                <SettingsIcon style={{ color: 'white' }} />
              </IconButton>
            </div>
            <div className="middleSection">
              <h2>CHECK-IN</h2>
            </div>
          </div>
          <div className="modal-sections">
            <FormModalCamera
              brands={brands}
              formValues={formValues}
              onFormValuesChange={setFormValues}
              inputRef={selectRef} // Pasar la referencia al input
            />

            <div className="modal-section">
              <NewCamera
                onSubmitImage={value => setImages((old: any) => ({ ...old, photo: value }))}
                text="Foto del rostro"
                deviceId={cameraPhoto ?? ''}
              />
              <NewCamera
                onSubmitImage={value => setImages((old: any) => ({ ...old, DNI_A: value }))}
                text="DNI frontal"
                deviceId={cameraDNI ?? ''}
              />
              <NewCamera
                onSubmitImage={value => setImages((old: any) => ({ ...old, DNI_B: value }))}
                text="DNI trasera"
                deviceId={cameraDNI ?? ''}
              />
            </div>
            <div className="button-section">
              <Button
                styleType="cancel"
                text="Cancelar"
                onClick={() => {
                  handleEntendidoClick(false)
                }}
              />
              <Button
                disabled={!images.photo || !images.DNI_A || !images.DNI_B}
                style={
                  !images.photo || !images.DNI_A || !images.DNI_B
                    ? { background: 'gray', pointerEvents: 'none' }
                    : {}
                }
                text="→ Registrar check in"
                onClick={handleCheckIn}
              />
            </div>
          </div>
        </div>
      </Modal>
      {showPopup && (
        <PopUp
          buttonText="Entendido"
          description="El check-in se registró correctamente"
          title="Registro exitoso"
          handleOk={handleEntendidoClick}
        />
      )}
      {showCameraConfigPopup && (
        <PopUpConfig handleClose={() => setShowCameraConfigPopup(false)} />
      )}
      <ShowQr qr={showQr} isModalOpen={!!showQr} handleCloseModal={() => setShowQr('')} />
      <Toaster richColors />
    </>
  )
}

export default ModalCamera
