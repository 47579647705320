import React from 'react'
import * as XLSX from 'xlsx'
import { Button } from '@mui/material'
import { searchPayment } from '../../types/Payments'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

interface ExportToExcelProps {
  filteredReports: searchPayment[]
}

const ExportToExcel: React.FC<ExportToExcelProps> = ({ filteredReports }) => {
  const handleExport = () => {
    // Map filteredReports to an array of objects
    const data = filteredReports.map(report => ({
      Destino: report.destinationCode,
      'Nombre Apellido': report.residentname,
      Estado: report.status,
      Monto: report.amount,
      Concepto: report.concept,
      Fecha: report.createdAt,
      Foto: report.photo
    }))

    // Create a new workbook and a worksheet
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(data)

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Reportes')

    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, 'reportes.xlsx')
  }

  return (
    <Button
      variant="contained"
      className={`button-excel`}
      startIcon={<ArrowDownwardIcon />}
      onClick={handleExport}
      style={{ textTransform: 'none' }}
    >
      Excel
    </Button>
  )
}

export default ExportToExcel
