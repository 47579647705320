import { brand } from '../types/brand'
import { Pagination, PaginationQuery } from '../types/types'
import axiosInstance from './axiosInstance'

export const listBrandsAll = async (token?: string): Promise<brand[]> => {
  try {
    const headers = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      : {}
    const response = await axiosInstance.get('/api/brands/all', headers)
    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de marcas:', error)
    throw error
  }
}

export const listBrand = async ({
  params
}: {
  params: PaginationQuery
}): Promise<Pagination<brand>> => {
  try {
    const response = await axiosInstance.get('/api/brands', {
      params
    })

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de destinos:', error)
    throw error
  }
}

export const createBrand = async (params: FormData): Promise<brand> => {
  try {
    const response = await axiosInstance.post('/api/brands', params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })
    return response.data
  } catch (error) {
    console.error('Error en la creación de Marca:', error)
    throw error
  }
}

export const updateBrand = async (
  id: string,
  params: FormData
): Promise<brand> => {
  try {
    const response = await axiosInstance.post(`/api/brands/${id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })
    return response.data
  } catch (error) {
    console.error('Error en la creación de Marca:', error)
    throw error
  }
}

export const deleteBrand = async (id: string): Promise<void> => {
  try {
    await axiosInstance.delete(`/api/brands/${id}`)
  } catch (error) {
    console.error('Error al eliminar Marca:', error)
    throw error
  }
}
