import React, { FC, useState } from 'react'
import Switch from '../Form/Switch'
import './styles.scss'
import Button from '../Form/Button'

interface Props {
  enable: boolean
  visitor: boolean
  guest: boolean
  worker: boolean
  delegate: boolean
  onUpdate: (value: {
    Resident: boolean
    Visitor: boolean
    Guest: boolean
    Employer: boolean
    Delegate: boolean
  }) => void
}

const EnableComponentResident: FC<Props> = ({
  enable,
  visitor,
  guest,
  worker,
  delegate,
  onUpdate
}) => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false)
  const [Resident, setResident] = useState(enable)
  const [Visitor, setVisitor] = useState(visitor)
  const [Guest, setGuest] = useState(guest)
  const [Employer, setEmployer] = useState(worker)
  const [Delegate, setDelegate] = useState(delegate)

  const handleAccept = () => {
    setIsPopUpOpen(false)
    onUpdate({ Resident, Visitor, Guest, Employer, Delegate })
  }

  const handleReject = () => {
    setResident(enable)
    setVisitor(visitor)
    setGuest(guest)
    setEmployer(worker)
    setDelegate(delegate)
    setIsPopUpOpen(false)
  }

  const handleOpen = () => {
    setIsPopUpOpen(true)
  }

  const handleResidentChange = (value: boolean) => {
    setResident(value)
    if (value) {
      setVisitor(true)
      setGuest(true)
      setEmployer(true)
      setDelegate(true)
    } else {
      setEmployer(false)
      setDelegate(false)
    }
  }

  const handleVisitorChange = (value: boolean) => {
    setVisitor(value)
  }

  const handleGuestChange = (value: boolean) => {
    setGuest(value)
  }

  const handleEmployerChange = (value: boolean) => {
    setEmployer(value)
  }

  const handleDelegateChange = (value: boolean) => {
    setDelegate(value)
  }

  return (
    <div>
      <Switch value={enable} onChange={handleOpen} />
      {isPopUpOpen && (
        <div className='popup-overlay-resident'>
          <div className='popup'>
            <h3>Configuraciones</h3>
            <div className='itemsContainerResident'>
              <Switch
                label='Residente:'
                onChange={handleResidentChange}
                value={Resident}
              />
              <Switch
                label='Visitantes:'
                onChange={handleVisitorChange}
                value={Visitor}
              />
              <Switch
                label='Invitados:'
                onChange={handleGuestChange}
                value={Guest}
              />
              <Switch
                label='Trabajadores:'
                onChange={handleEmployerChange}
                value={Employer}
              />
              <Switch
                label='Delegados:'
                onChange={handleDelegateChange}
                value={Delegate}
              />
            </div>
            <div className='itemsContainer'>
              <Button color='primary' text='Aceptar' onClick={handleAccept} />
              <Button color='primary' text='Cerrar' onClick={handleReject} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default EnableComponentResident
