import React, { HTMLInputTypeAttribute, useState, useRef } from 'react'
import './style.scss'

interface InputProps {
  icon?: string
  placeholder?: string
  value?: string | number
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  type?: HTMLInputTypeAttribute
  className?: string
  label?: string
  min?: number
}

const InputDark: React.FC<InputProps> = ({
  icon,
  className,
  placeholder,
  value,
  type,
  onChange,
  label,
  min
}) => {
  const [selected, setSelected] = useState(false)
  const inputContainerRef = useRef<HTMLDivElement>(null)

  const handleClick = () => {
    if (inputContainerRef.current) {
      const inputElement = inputContainerRef.current.querySelector(
        '.input-element'
      ) as HTMLInputElement | null

      if (inputElement) {
        inputElement.focus()
      }
    }
  }

  return (
    <div
      className={`input-container-dark ${
        selected ? 'selectedInput' : ''
      } ${className}`}
      onClick={handleClick}
      ref={inputContainerRef}>
      <label className='input-label'>{label}</label> {/* Nuevo label */}
      <div className={`icon-input-container`}>
        {icon && <img className='icon' src={icon} alt={placeholder} />}
        <input
          min={min}
          type={type}
          onFocus={() => setSelected(true)}
          onBlur={() => setSelected(false)}
          className='input-element'
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          pattern={type === 'number' ? '[0-9]*' : undefined}
        />
      </div>
    </div>
  )
}

export default InputDark
