import { Administrator } from '../types/administrators'
import { Pagination, PaginationQuery } from '../types/types'
import axiosInstance from './axiosInstance'

export const listAdministratorClient = async ({
  id,
  params
}: {
  id: string
  params: PaginationQuery
}): Promise<Pagination<Administrator>> => {
  try {
    const response = await axiosInstance.get(
      `/api/administrators/byclient/${id}`,
      {
        params
      }
    )

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de inicio de sesión:', error)
    throw error
  }
}

export const updateAdministrator = async (
  id: string,
  params: FormData
): Promise<Administrator> => {
  try {
    const response = await axiosInstance.post(
      `/api/administrators/${id}`,
      params,
      {
        headers: {
          'Content-Type': 'multipart/form-data;'
        }
      }
    )

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de inicio de sesión:', error)
    throw error
  }
}

export const createAdministrator = async (
  params: FormData
): Promise<Administrator> => {
  try {
    const response = await axiosInstance.post(`/api/administrators`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de inicio de sesión:', error)
    throw error
  }
}

export const deleteAdministrator = async (id: string): Promise<void> => {
  try {
    const response = await axiosInstance.delete(`/api/administrators/${id}`)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de inicio de sesión:', error)
    throw error
  }
}
