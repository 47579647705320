import React, { FC, useEffect, useState } from 'react'
import AutoComplete from '../../Form/AutoComplete'
import './style.scss'
import { listByDestinationCode } from '../../../services/resident'
import { useAuth } from '../../../Provider/AuthProvider'
import { brand } from '../../../types/brand'

interface FormModalCameraProps {
  brands: brand[]
  formValues: {
    brand: string
    destinationCode: string
  }
  onFormValuesChange: (values: { brand: string; destinationCode: string }) => void
  inputRef: React.RefObject<HTMLInputElement>
}

const FormModalCamera: FC<FormModalCameraProps> = ({
  formValues,
  onFormValuesChange,
  inputRef,
  brands
}) => {
  // Si no se pasan brands como prop, obtenerlas del contexto
  // const { brands: contextBrands } = useCheckIn()
  const availableBrands = brands // Usar las brands de las props o del contexto

  const { user } = useAuth()
  const [optionsDestinations, setOptionsDestinations] = useState<
    { label: string; value: string }[]
  >([])
  const [searchedValue, setSearchedValue] = useState('')

  // Maneja el cambio de valores del formulario
  const handleInputChange = (inputName: string, value: string) => {
    onFormValuesChange({
      ...formValues,
      [inputName]: value
    })
  }

  // Maneja el cambio del texto de búsqueda sin afectar el valor seleccionado
  const handleChangeText = (value: string) => {
    setSearchedValue(value)
  }

  // Obtiene los datos de códigos de destino según la búsqueda
  const getDataCodes = async () => {
    try {
      const values = await listByDestinationCode({
        search: searchedValue,
        clientId: user?.clientId as string
      })
      setOptionsDestinations(
        values.map(e => ({
          label: `${e.destinationCode} ${e.name} ${e.lastName}`,
          value: e.destinationCode
        }))
      )
    } catch (error) {
      console.error('Error fetching destination codes:', error)
    }
  }

  // Llama a la función de búsqueda cuando cambia el texto de búsqueda
  useEffect(() => {
    if (searchedValue.length) {
      getDataCodes()
    }
  }, [searchedValue])

  // Preselecciona la marca si está en localStorage
  useEffect(() => {
    const preSelectBrand = localStorage.getItem('preSelectBrand')
    if (preSelectBrand) {
      handleInputChange('brand', preSelectBrand)
    }
  }, [])

  return (
    <div className="input-section">
      <div className="containerInputs">
        <AutoComplete
          icon={'images/icons/brands.png'}
          label="Marcas"
          options={availableBrands.map(e => ({ value: e.id, label: e.name }))}
          value={formValues.brand}
          onChange={value => {
            handleInputChange('brand', value)
            localStorage.setItem('preSelectBrand', value)
          }}
        />
        <AutoComplete
          icon={'images/icons/user.png'}
          label="Destino"
          options={optionsDestinations}
          onChangeText={handleChangeText}
          value={formValues.destinationCode}
          ref={inputRef} // Pasamos la referencia al CustomSelect
          onChange={value => {
            handleInputChange('destinationCode', value)
            setSearchedValue('') // Opcional: Resetea el valor de búsqueda después de la selección
          }}
        />
      </div>
    </div>
  )
}

export default FormModalCamera
