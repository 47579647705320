import { ConfigProvider, Select } from 'antd'
import { SelectProps } from 'antd/lib'
import React from 'react'

interface SelectOption {
  value: string
  label: string
}

interface MultiselectProps {
  options: SelectOption[]
  value?: string[]
  placeholder?: string
  onChange?: (event: any) => void
}

export const MultiSelectANTD: React.FC<MultiselectProps> = ({
  options,
  placeholder,
  onChange,
  value = []
}) => {
  const sharedProps: SelectProps = {
    mode: 'multiple',
    options,
    style: { width: '100%', height: '100%' },
    maxTagCount: 'responsive',
    size: 'large'
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            colorBgContainer: 'rgba(56, 78, 183, 0.3)',
            colorBorder: '#484747',
            colorPrimary: 'white',
            hoverBorderColor: 'white',
            colorText: 'white',
            colorTextBase: 'white',
            optionSelectedColor: 'white',
            selectorBg: 'rgba(56, 78, 183, 0.3)',
            optionSelectedBg: 'rgba(56, 78, 183, 0.3)',
            multipleItemBg: 'rgba(56, 78, 183, 0.3)',
            colorIcon: 'white',
            colorIconHover: 'white'
          }
        },
        token: {
          colorBgBase: 'rgba(56, 78, 183, 0.3)',
          colorBgContainer: 'rgba(56, 78, 183, 0.3)',
          colorText: 'white',
          colorTextPlaceholder: 'white',
          colorIcon: 'white',
          colorIconHover: 'white'
        }
      }}
    >
      <div className="multiSelectAntContainer">
        <Select
          {...sharedProps}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          filterOption={(input, option) =>
            typeof option?.label === 'string' &&
            option.label.toLowerCase().includes(input.toLowerCase())
          }
        />
      </div>
    </ConfigProvider>
  )
}
