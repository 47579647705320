// FormAdministrator.tsx
import React, { FC } from 'react'
import DynamicForm from '../../Form/DynamicForm'
import './styles.scss'

import { useLoading } from '../../../Provider/LoadingProvider'

import { formAccessConfig } from './Columns'
import { AccessConfigCreate, AccessConfig } from '../../../types/AccessConfig'
import { createAccessConfig, updateAccessConfig } from '../../../services/AccessConfig'
import { useAuth } from '../../../Provider/AuthProvider'

const FormAccessConfig: FC<{
  initialValue?: AccessConfig
  onSubmit: () => void
}> = ({ onSubmit, initialValue }) => {
  const { startLoading, stopLoading } = useLoading()
  const { user, initial } = useAuth()

  const handleSubmit = async (formData: AccessConfigCreate) => {
    try {
      startLoading()
      const data = new FormData()
      Object.entries({
        name: formData.name,
        FileFace: formData.FileFace,
        FileDNI1: formData.FileDNI1,
        FileMaster: formData.FileMaster,
        clientId: user?.clientId as string
      }).forEach(([key, value]) => {
        data.append(key, value)
      })

      if (initialValue) {
        await updateAccessConfig(initialValue.id, data)
      } else {
        await createAccessConfig(data)
      }

      onSubmit()
      initial()
    } catch (error) {
      console.error('error')
    } finally {
      stopLoading()
    }
  }

  return (
    <div className="newCheckPointsLocationForm">
      <h2>{initialValue ? 'ACTUALIZAR ACESSO' : 'NUEVO ACESSO'}</h2>
      <DynamicForm
        containtFileMaster={true}
        formConfig={formAccessConfig}
        onSubmit={handleSubmit}
        initialValues={initialValue}
      />
    </div>
  )
}

export default FormAccessConfig
