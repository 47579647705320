// administratorConfig.ts
import { FieldTypeEnum, FormField } from '../../../components/Tables/Routines/DynamicFormRoutines'
import { TableColumn, enumTypeColumn } from '../../Table'
export const columnsCheckPoints: TableColumn[] = [
  {
    title: 'FOTO',
    dataIndex: 'photo',
    key: 'photo',
    type: enumTypeColumn.photo
  },
  {
    title: 'NOMBRE',
    dataIndex: 'name',
    key: 'name',
    type: enumTypeColumn.string
  },
  {
    title: 'DIRECCIÓN',
    dataIndex: 'address',
    key: 'address',
    type: enumTypeColumn.string
  },
  {
    title: 'TIPO LOCACIÓN',
    dataIndex: 'type',
    key: 'type',
    type: enumTypeColumn.string
  }
]

export const CheckPointsLocationFormConfig: FormField[] = [
  { type: FieldTypeEnum.text, label: 'Nombre', name: 'name' },
  { type: FieldTypeEnum.text, label: 'Dirección', name: 'address' },
  {
    type: FieldTypeEnum.select,
    label: 'tipo locación',
    name: 'type',
    options: [
      { label: 'Limpieza', value: 'Limpieza' },
      { label: 'Mantenimiento', value: 'Mantenimiento' },
      { label: 'Seguridad', value: 'Seguridad' }
    ]
  },
  {
    type: FieldTypeEnum.text,
    label: 'Objetivo',
    name: 'target',
    dependentOn: 'type',
    dependentValue: 'Seguridad'
  },

  { type: FieldTypeEnum.image, label: 'Foto', name: 'photo' }
]
