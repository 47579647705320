import React, { ButtonHTMLAttributes, ReactNode } from 'react'
import './style.scss'
interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void
  text?: string
  styleType?: 'normal' | 'cancel' | 'disable'
  icon?: ReactNode
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  text,
  styleType,
  icon,
  ...buttonProps
}) => {
  return (
    <button
      {...buttonProps}
      className={`custom-button ${styleType}`}
      onClick={onClick}>
      {icon}
      {text}
    </button>
  )
}

export default Button
