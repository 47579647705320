// ClientDetailsPage.tsx
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import ButtonSection from '../../components/ButtonSelection'
import { clientFormConfig } from '../../components/Tables/Clients/Columns'
import DynamicForm from '../../components/Form/DynamicForm'
import { getClient, updateClient } from '../../services/clients'
import { Client, ClientCreate } from '../../types/client'
import './styles.scss'
import AdministratorByClientTable from '../../components/Tables/Administrators/AdministratorByClientTable'
import ReceptionistByClientTable from '../../components/Tables/Receptionist/ReceptionistByClientTable'
import LocationByClientTable from '../../components/Tables/Location/LocationByClientTable'
import EquipmentByClientTable from '../../components/Tables/Equipment/EquipmentByClientTable'
import { useLoading } from '../../Provider/LoadingProvider'
const ClientDetailsPage = () => {
  const { id } = useParams<{ id: string }>()
  const [clientDetails, setClientDetails] = useState<Client | null>(null)
  const { startLoading, stopLoading } = useLoading()

  const fetchData = async () => {
    try {
      const details = await getClient(id as string)

      setClientDetails(details)
    } catch (error) {
      console.error('Error fetching client details:', error)
    }
  }
  useEffect(() => {
    if (id) {
      fetchData()
    }
  }, [id])
  const handleSubmit = async (formData: ClientCreate) => {
    try {
      startLoading()
      const data = new FormData()
      Object.entries({
        name: formData.name,
        phone: formData.phone,
        email: formData.email,
        nit: formData.nit,
        photo: formData.photo,
        administratorName: formData.administratorName
      }).forEach(([key, value]) => {
        data.append(key, value)
      })
      await updateClient(clientDetails?.id as string, data)
      fetchData()
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }
  const buttonsInfo = [
    {
      text: 'Ver administradores',
      component: <AdministratorByClientTable id={id as string} />
    },
    {
      text: 'Ver recepcionistas',
      component: <ReceptionistByClientTable id={id as string} />
    },
    {
      text: 'Ver locaciones',
      component: <LocationByClientTable id={id as string} />
    },
    {
      text: 'Ver equipos',
      component: <EquipmentByClientTable id={id as string} />
    }
  ]
  return (
    <div className='clientContainer'>
      <div className='title'>
        <p>Detalles de cliente</p>
      </div>
      {clientDetails && (
        <DynamicForm
          initialValues={clientDetails}
          formConfig={clientFormConfig}
          onSubmit={handleSubmit}
        />
      )}
      <div className='title'>
        <p>Gestión de cliente</p>
      </div>
      {id && <ButtonSection buttonsInfo={buttonsInfo} />}
    </div>
  )
}

export default ClientDetailsPage
