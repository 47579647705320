import React, { FC, useState } from 'react'
import Switch from '../../../Form/Switch'
import PopUpConfirm from '../PopupConfirm'

const EnableComponent: FC<{ onUpdate: () => void; enable: boolean }> = ({
  onUpdate,
  enable
}) => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false)

  const handleAccept = () => {
    setIsPopUpOpen(false)
    onUpdate()
  }

  const handleReject = () => {
    setIsPopUpOpen(false)
  }

  return (
    <div>
      <Switch value={enable} onChange={(_e: boolean) => setIsPopUpOpen(true)} />
      <PopUpConfirm
        isOpen={isPopUpOpen}
        onClose={() => setIsPopUpOpen(false)}
        title={`¿Estás seguro de ${enable ? 'Deshabilitar' : 'habilitar'} ?`}
        description=""
        acceptText="Aceptar"
        rejectText="Cancelar"
        handleAccept={handleAccept}
        handleReject={handleReject}
      />
    </div>
  )
}

export default EnableComponent
