import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  DislikeOutlined
} from '@ant-design/icons'
import Icon from '@ant-design/icons'
import { ReactComponent as waitInLobby } from '../../../assets/svg/waitInLobby.svg'
import { ReactComponent as leaveInLobby } from '../../../assets/svg/leaveInLobby.svg'
import { ReactComponent as noLeaveInLobby } from '../../../assets/svg/noLeaveInLobby.svg'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import QRCodeIcon from '@mui/icons-material/QrCode'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import WarningIcon from '@mui/icons-material/Warning'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Fade, IconButton } from '@mui/material'
import React, { FC } from 'react'
import { useAuth } from '../../../Provider/AuthProvider'
import { useCheckIn } from '../../../Provider/CheckInProvider'
import { entries, entriesApprovedEnum } from '../../../types/entries'
import { roleEnum } from '../../../types/user'
import Table, { TableColumn, enumTypeColumn } from '../../Table'
import ValidateEntry from '../../Table/components/ValidateEntry'
import { columnsCheckIn } from './columns'
import { columnsCheckInOkId } from './columnsOkid'
import { useLoading } from '../../../Provider/LoadingProvider'
import { DownloadPdfOkId } from '../../../services/entries'
import { LightTooltip } from '../../tooltips/text'

const TableCheckIn: FC<{ handleOpenModal: () => void }> = ({ handleOpenModal }) => {
  const { user, token } = useAuth()
  const { data, getDataCheckIn, setSearch, search, setLastEntry } = useCheckIn()
  const OkId = user?.client?.OkId

  const { startLoading, stopLoading } = useLoading()

  const onChangeSearch = (value: string) => {
    setSearch(value)
  }

  const handlePageChange = (newPage: number) => {
    getDataCheckIn(search.length >= 3 ? search : '', newPage)
  }
  const handleIconClick = (item?: entries) => {
    console.info(item)
  }
  const handleView = (item?: entries) => {
    if (item) {
      setLastEntry(item)
    }
  }

  const handlePdfOkId = async (id?: string) => {
    if (id) {
      try {
        startLoading()
        console.log(id)
        const dataPdf64 = await DownloadPdfOkId(id, token) // Pedimos el base64 del pdf

        // Convierte el base64 en un objeto Blob
        const pdfBlob = base64ToBlob(dataPdf64?.pdf64, 'application/pdf')

        // Crea un enlace para descargar el PDF
        const pdfUrl = URL.createObjectURL(pdfBlob)

        // Puedes mostrar un modal con el PDF o descargarlo directamente
        window.open(pdfUrl)

        // También puedes crear un enlace de descarga
        const downloadLink = document.createElement('a')
        downloadLink.href = pdfUrl
        downloadLink.download = 'document.pdf'
        downloadLink.click()
      } catch (error) {
        console.error('Error al crear reporte', error) // Maneja el error
      } finally {
        stopLoading()
      }
    }
  }

  // Función para convertir base64 a Blob
  const base64ToBlob = (base64: string, contentType: string) => {
    const byteCharacters = atob(base64)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    return new Blob([byteArray], { type: contentType })
  }

  const Actions = ({ id, item }: { id: string; item?: entries }) => {
    return (
      <>
        {user?.canApprove && item?.approved === entriesApprovedEnum.WAITING && (
          <ValidateEntry id={id} />
        )}
        {item?.OKIDNumber && user?.role === 'admin' && (
          <IconButton onClick={() => handlePdfOkId(item?.OKIDNumber)}>
            <PictureAsPdfIcon style={{ color: 'red' }} />
          </IconButton>
        )}

        <IconButton onClick={() => handleIconClick(item)}>
          <QRCodeIcon
            style={{
              color: item?.approved ? (item?.timeOut ? 'red' : 'green') : 'yellow'
            }}
          />
        </IconButton>
        <IconButton onClick={() => handleView(item)}>
          <VisibilityIcon style={{ color: 'white' }} />
        </IconButton>
      </>
    )
  }
  const svgOkid = (item: entries | undefined) => {
    if (item?.okid && user?.role === 'admin') {
      const dataOkid = parseInt(item?.okid)
      if (item.FECHA_DEFUNCION) {
        return <WarningIcon style={{ color: 'red' }} />
      }
      if (dataOkid >= 70) {
        return <DoneAllIcon style={{ color: 'green' }} />
      }
      if (dataOkid >= 70) {
        return <PriorityHighIcon style={{ color: 'yellow' }} />
      } else {
        return <WarningIcon style={{ color: 'red' }} />
      }
    }
  }

  const statusSvg: {
    [key: string]: React.JSX.Element
  } = {
    WAITING: <ClockCircleOutlined style={{ color: 'yellow' }} />,
    APPROVED: <CheckCircleOutlined style={{ color: 'green' }} />,
    REJECT: <DislikeOutlined style={{ color: 'red' }} />,
    RECESSION: <Icon component={waitInLobby} />,
    LEAVE: <Icon component={leaveInLobby} />,
    NO_LEAVE: <Icon component={noLeaveInLobby} />,
    UNANSWERED: <CloseCircleOutlined style={{ color: 'red' }} />
  }

  const valueTable = OkId ? columnsCheckInOkId : columnsCheckIn
  const showAProved: TableColumn[] = [
    ...valueTable,
    {
      title: 'Estados',
      dataIndex: 'approved',
      key: 'approved',
      type: enumTypeColumn.custom,
      CustomRender: ({ item }: { id: string; item?: entries }) => {
        return (
          <LightTooltip
            title={
              item && item.cameraIn && item.authorizedIn && item.approved !== 'WAITING' ? (
                <>
                  {`INGRESO:${item?.cameraIn}\n${item?.authorizedIn}`}
                  {item?.cameraOut ? `\nEGRESO:${item?.cameraOut}\n${item?.authorizedOut}` : ''}
                </>
              ) : (
                'ESPERANDO'
              )
            }
            followCursor
            TransitionComponent={Fade}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '30px',
                gap: '0.5rem'
              }}
            >
              {item?.approved ? statusSvg[item?.approved] : statusSvg.WAITING}
              {item && svgOkid(item)}
            </div>
          </LightTooltip>
        )
      }
    }
  ]
  return (
    <Table
      onChangeSearch={onChangeSearch}
      pagination={data}
      handleOpenModal={handleOpenModal}
      onPageChange={handlePageChange}
      columns={showAProved}
      itemsPerPage={6}
      Actions={Actions}
      canAdd={user?.role === roleEnum.ADMIN ? false : true}
    />
  )
}

export default TableCheckIn
