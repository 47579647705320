// FormAdministrator.tsx
import React, { FC } from 'react'
import DynamicForm from '../../Form/DynamicForm'
import './styles.scss'

import { useLoading } from '../../../Provider/LoadingProvider'
import {
  createSuperAdmin,
  updateSuperAdmin
} from '../../../services/super_admin'
import { SuperAdmin, SuperAdminCreate } from '../../../types/super_admin'
import { superAdminFormConfig } from './Columns'
import {} from '../../../utils/utils'

const FormSuperAdmin: FC<{
  onSubmit: () => void
  initialValue?: SuperAdmin
}> = ({ onSubmit, initialValue }) => {
  const { startLoading, stopLoading } = useLoading()

  const handleSubmit = async (formData: SuperAdminCreate) => {
    try {
      startLoading()
      const data = new FormData()
      Object.entries({
        name: formData.name,
        lastName: formData.lastName,
        userName: formData.userName,
        number: formData.number,
        ...(formData.password ? { password: formData.password } : {}),
        photo: formData.photo
      }).forEach(([key, value]) => {
        data.append(key, value)
      })
      if (initialValue) {
        await updateSuperAdmin(initialValue.id, data)
      } else {
        await createSuperAdmin(data)
      }

      onSubmit()
    } catch (error) {
      // Manejo de errores
    } finally {
      stopLoading()
    }
  }

  return (
    <div className='newSuperAdminForm'>
      <h2>
        {initialValue
          ? 'Actualizar Super Administrador'
          : 'Nuevo Super Administrador'}
      </h2>
      <DynamicForm
        initialValues={initialValue}
        formConfig={superAdminFormConfig}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

export default FormSuperAdmin
