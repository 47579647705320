import React, { FC, useEffect, useRef, useState } from 'react'
import './style.scss'
import CameraIcon from '@mui/icons-material/CameraAlt'
import Button from '@mui/material/Button'

type CameraProps = {
  text: string
  deviceId: string
  onSubmitImage: (value: File) => void
}

const stopTracks = (stream: MediaStream) => {
  const tracks = stream.getTracks()
  tracks.forEach(track => track.stop())
}

const NewCamera: FC<CameraProps> = ({ deviceId, text, onSubmitImage }) => {
  const imageRef = useRef<HTMLImageElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const [taken, setTaken] = useState(false)

  useEffect(() => {
    let stream: MediaStream | null = null
    const initializeCamera = async () => {
      try {
        if (
          !navigator.mediaDevices ||
          typeof navigator.mediaDevices.getUserMedia !== 'function'
        ) {
          console.warn('getUserMedia no está disponible en este navegador.')
          return
        }

        const constraints: MediaStreamConstraints = {
          video: {
            deviceId: deviceId ? { exact: deviceId } : undefined
          }
        }

        stream = await navigator.mediaDevices.getUserMedia(constraints)

        const videoElement = videoRef.current
        if (videoElement) {
          videoElement.srcObject = stream
          const playPromise = videoElement.play()
          if (playPromise !== undefined) {
            try {
              await playPromise
            } catch (error) {
              console.error('error al reproducir camaras local')
            }
          }
        }
      } catch (error) {
        console.error('Error al inicializar la cámara:', error)
      }
    }

    const cleanupCamera = () => {
      if (stream) {
        stopTracks(stream)
      }
    }

    initializeCamera()

    return () => {
      cleanupCamera()
    }
  }, [deviceId])

  const captureImage = () => {
    const videoElement = videoRef.current
    const imageElement = imageRef.current
    if (videoElement && imageElement) {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')

      if (context) {
        canvas.width = videoElement.videoWidth
        canvas.height = videoElement.videoHeight
        context.drawImage(videoElement, 0, 0, canvas.width, canvas.height)
        imageElement.src = canvas.toDataURL('image/png')
        canvas.toBlob((blob: Blob | null) => {
          if (blob) {
            const file = new File([blob], 'captured-image.png')
            onSubmitImage(file)
          }
        }, 'image/png')

        setTaken(true)
      }
    }
  }

  const retakePhoto = () => {
    setTaken(false)
  }

  return (
    <div className="camera-section">
      <p className="textoCamera">{text}</p>
      <video
        ref={videoRef}
        width="100%"
        height="100%"
        autoPlay
        style={{ display: taken ? 'none' : 'inline' }}
      ></video>
      <img
        ref={imageRef}
        width="100%"
        height="100%"
        alt={taken ? 'Imagen capturada desde la cámara' : ''}
        style={{ display: !taken ? 'none' : 'inline' }}
      />
      {!taken && (
        <Button
          variant="contained"
          className={`capture-button`}
          startIcon={<CameraIcon />}
          onClick={captureImage}
        >
          Tomar foto
        </Button>
      )}
      {taken && (
        <Button
          startIcon={<CameraIcon />}
          variant="contained"
          className={`capture-button`}
          onClick={retakePhoto}
        >
          Reintentar
        </Button>
      )}
    </div>
  )
}

export default NewCamera
