import React from 'react'
import './style.scss'
import { useAuth } from '../../Provider/AuthProvider'
import ReceptionistByClientTable from '../../components/Tables/Receptionist/ReceptionistByClientTable'
const ReceptionistPage = () => {
  const { user } = useAuth()
  if (!user || !user.clientId) {
    return null
  }
  return (
    <div className='receptionAdminContainer'>
      <ReceptionistByClientTable id={user.clientId} />
    </div>
  )
}

export default ReceptionistPage
