import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import './styles.scss'
/* import { roleEnum } from '../types/user'
import { QrReader } from 'react-qr-reader' se inhabilita modulo ya q no se usa */

type CameraContextType = {
  cameraDNI: string | null
  cameraPhoto: string | null
  setCameraDNI: React.Dispatch<React.SetStateAction<string | null>>
  setCameraPhoto: React.Dispatch<React.SetStateAction<string | null>>
  devices: MediaDeviceInfo[]
  initializeCameras: () => void
}

const CameraContext = createContext<CameraContextType | undefined>(undefined)

export const CameraProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [cameraDNI, setCameraDNI] = useState<string | null>(null)
  const [cameraPhoto, setCameraPhoto] = useState<string | null>(null)
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([])

  const initializeCameras = () => {
    if (
      navigator.mediaDevices &&
      typeof navigator.mediaDevices.enumerateDevices === 'function'
    ) {
      navigator.mediaDevices.enumerateDevices().then((deviceList) => {
        const videoDevices = deviceList.filter(
          (device) => device.kind === 'videoinput'
        )
        setDevices(videoDevices)

        const savedCameraDNI = localStorage.getItem('cameraDNI')
        if (savedCameraDNI) setCameraDNI(savedCameraDNI)

        const savedCameraPhoto = localStorage.getItem('cameraPhoto')
        if (savedCameraPhoto) setCameraPhoto(savedCameraPhoto)
      })
    } else {
      console.warn('mediaDevices no está disponible en este navegador.')
    }
  }

  useEffect(() => {
    initializeCameras()
  }, [])

  const contextValue: CameraContextType = {
    cameraDNI,
    cameraPhoto,
    setCameraDNI,
    setCameraPhoto,
    devices,
    initializeCameras
  }
  /*  const scannedQr = async (result: any | null) => {
    try {
      if (result) {
        if (beepSound.current) {
          beepSound.current.play()
        }
        const resp = await validate_qr_recepcion({
          params: {
            code: result.text,
            clientID: user?.clientId as string
          },
          token
        })
      }
    } catch (error) {}
  } */

  return (
    <CameraContext.Provider value={contextValue}>
      <>
        {children}
        {/* {cameraPhoto && user?.role === roleEnum.RECEPTION && (
          <QrReader
            scanDelay={1000}
            constraints={{
              advanced: [{ facingMode: cameraPhoto }]
            }}
            onResult={scannedQr}
          />
        )}
        <audio ref={beepSound} src="sounds/beep.mp3" /> */}
      </>
    </CameraContext.Provider>
  )
}

export const useCamera = () => {
  const context = useContext(CameraContext)
  if (!context) {
    throw new Error('useCamera debe ser utilizado dentro de un CameraProvider')
  }
  return context
}
