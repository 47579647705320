import React, { FC, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import PopUpConfirm from '../PopupConfirm'

const DeleteButton: FC<{ OnDelete: () => void }> = ({ OnDelete }) => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false)

  const handleAccept = () => {
    setIsPopUpOpen(false)
    OnDelete()
  }

  const handleReject = () => {
    setIsPopUpOpen(false)
  }

  return (
    <div>
      <IconButton onClick={() => setIsPopUpOpen(true)}>
        <DeleteIcon style={{ color: 'tomato' }} />
      </IconButton>

      <PopUpConfirm
        isOpen={isPopUpOpen}
        onClose={() => setIsPopUpOpen(false)}
        title="¿Estás seguro de eliminar?"
        description="Esta acción no se puede deshacer."
        acceptText="Aceptar"
        rejectText="Cancelar"
        handleAccept={handleAccept}
        handleReject={handleReject}
      />
    </div>
  )
}

export default DeleteButton
