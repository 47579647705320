import React, { FC } from 'react'
import './style.scss'
type PopUpType = {
  handleOk: () => void
  buttonText: string
  title: string
  description: string
}
const PopUp: FC<PopUpType> = ({ handleOk, buttonText, title, description }) => {
  return (
    <div className='popup-overlay'>
      <div className='popup'>
        <div className='check-icon'></div>
        <p className='regexi'>{title}</p>
        <p>{description}</p>
        <button
          className='register-button'
          style={{ display: 'inline', width: '250px', height: '46px' }}
          onClick={handleOk}>
          {buttonText}
        </button>
      </div>
    </div>
  )
}

export default PopUp
