// src/services/super_admins.ts
import { SuperAdmin } from '../types/super_admin'
import { Pagination, PaginationQuery } from '../types/types'
import axiosInstance from './axiosInstance'

export const listSuperAdmins = async ({
  params
}: {
  params: PaginationQuery
}): Promise<Pagination<SuperAdmin>> => {
  try {
    const response = await axiosInstance.get('/api/super_admins', {
      params
    })

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de super_admins:', error)
    throw error
  }
}

export const createSuperAdmin = async (params: FormData): Promise<SuperAdmin> => {
  try {
    const response = await axiosInstance.post('/api/super_admins', params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    console.error('Error en la creación de SuperAdmin:', error)
    throw error
  }
}

export const updateSuperAdmin = async (id: string, params: FormData): Promise<SuperAdmin> => {
  try {
    const response = await axiosInstance.post(`/api/super_admins/${id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    console.error('Error en la actualizacion de SuperAdmin:', error)
    throw error
  }
}

export const deleteSuperAdmin = async (id: string): Promise<void> => {
  try {
    const response = await axiosInstance.delete(`/api/super_admins/${id}`)

    return response.data
  } catch (error) {
    console.error('Error al eliminar SuperAdmin:', error)
    throw error
  }
}
