import React, { useState, useEffect } from 'react'
import InputDark from '../../../components/Form/InputDark'
import Select from '../../../components/Form/Select'
import Button from '../../../components/Form/Button'
import { Search as SearchIcon } from '@mui/icons-material'
import MultiDatePicker from '../../../components/Form/MultiDatePicker'
import ImageUpload from '../../../components/Form/ImageUpload'

import './styles.scss'
import DragAndDrop from '../../DragAndDrop'
import { LocationsItems } from '../../../types/routines'

export enum FieldTypeEnum {
  'text' = 'text',
  'select' = 'select',
  'image' = 'image',
  'DragAndDrop' = 'DragAndDrop',
  'search' = 'search',
  'datePicker' = 'datePicker'
}

export interface FormField {
  name: string
  label: string
  type: FieldTypeEnum
  options?: SelectOption[]
  Locations?: LocationsItems[]
  dependentOn?: string // Campo del cual depende la visibilidad
  dependentValue?: string // Valor específico que debe tener el campo dependiente
}

interface SelectOption {
  value: string
  label: string
}

interface DynamicFormProps {
  formConfig: FormField[]
  onSubmit: (formData: any) => void
  initialValues?: Record<string, any>
}

// ... (otros imports)

const DynamicForm: React.FC<DynamicFormProps> = ({ formConfig, onSubmit, initialValues }) => {
  const [formData, setFormData] = useState<any>(initialValues ?? {})
  const [ChangeSearch, setChangeSearch] = useState<string>('')

  useEffect(() => {
    setFormData(initialValues ?? {})
  }, [initialValues])

  const handleChange = (name: string, value: any) => {
    setFormData((prevData: any) => ({ ...prevData, [name]: value }))
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onSubmit(formData)
  }

  return (
    <form className="formContainerRoutines" onSubmit={handleSubmit}>
      <div className="elementsContainerRoutines">
        {formConfig.map(field => {
          const shouldShow =
            !field.dependentOn ||
            (field.dependentOn &&
              formData[field.dependentOn] &&
              formData[field.dependentOn] === field.dependentValue)

          if (!shouldShow) return null

          return (
            <div className={`formElementRoutines ${field.type}`} key={field.name}>
              {
                {
                  [FieldTypeEnum.text]: (
                    <InputDark
                      placeholder={field.label}
                      key={field.name}
                      label={field.label}
                      value={formData ? formData[field?.name] ?? '' : ''}
                      onChange={value => handleChange(field?.name, value.target.value)}
                    />
                  ),
                  [FieldTypeEnum.search]: (
                    <div className="form-search-section">
                      <div className="form-search-bar-container">
                        <div className="form-search-icon">
                          <SearchIcon style={{ color: 'white' }} />
                        </div>
                        <input
                          onChange={e => setChangeSearch(e.target.value)}
                          type="text"
                          className="form-search-input"
                          placeholder="Buscar disponibles"
                        />
                      </div>
                    </div>
                  ),
                  [FieldTypeEnum.select]: (
                    <Select
                      key={field.name}
                      label={field.label}
                      options={field.options || []}
                      value={formData ? formData[field?.name] ?? '' : ''}
                      onChange={value => handleChange(field?.name, value)}
                    />
                  ),
                  [FieldTypeEnum.datePicker]: (
                    <MultiDatePicker
                      label={field.label}
                      defaultValue={formData[field?.name]}
                      onChange={(_date, dateString) => handleChange(field?.name, dateString)}
                    />
                  ),
                  [FieldTypeEnum.image]: (
                    <ImageUpload
                      label={field.label}
                      key={field.name}
                      value={formData ? formData[field?.name] ?? '' : ''}
                      onChange={(value: any) => handleChange(field.name, value)}
                    />
                  ),
                  [FieldTypeEnum.DragAndDrop]: (
                    <DragAndDrop
                      dataSelect={field.Locations!}
                      search={ChangeSearch}
                      defaultSearch={formData['TypeRoutine']}
                      onChange={(updatedTasks: LocationsItems[]) =>
                        handleChange(field.name, JSON.stringify(updatedTasks))
                      }
                    />
                  )
                }[field.type]
              }
            </div>
          )
        })}
      </div>
      <Button
        className="button"
        text={initialValues ? 'Actualizar' : `Enviar`}
        type="submit"
      />
    </form>
  )
}

export default DynamicForm
