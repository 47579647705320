// delegateResident.ts
import { FieldTypeEnum, FormField } from '../../Form/DynamicForm'
import { TableColumn, enumTypeColumn } from '../../Table'

export const columnWorker: TableColumn[] = [
  {
    title: 'Foto',
    dataIndex: 'photo',
    key: 'photo',
    type: enumTypeColumn.photo
  },
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    type: enumTypeColumn.string
  },
  {
    title: 'Apellido',
    dataIndex: 'lastName',
    key: 'lastName',
    type: enumTypeColumn.string
  },
  {
    title: 'Teléfono',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    type: enumTypeColumn.string
  },
  {
    title: 'Creado en',
    dataIndex: 'createdAt',
    key: 'createdAt',
    type: enumTypeColumn.date
  }
]

export const workerFormConfig: FormField[] = [
  { type: FieldTypeEnum.text, label: 'Nombre', name: 'name' },
  { type: FieldTypeEnum.text, label: 'Apellido', name: 'lastName' },
  { type: FieldTypeEnum.phone, label: 'Teléfono', name: 'phoneNumber' },
  { type: FieldTypeEnum.image, label: 'Foto', name: 'photo' },
  { type: FieldTypeEnum.image, label: 'DNI lado A', name: 'DNI_A' },
  { type: FieldTypeEnum.image, label: 'DNI lado B', name: 'DNI_B' }
]
