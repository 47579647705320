import React, { useEffect, useState } from 'react'
import './styles.scss'
import Box from '@mui/material/Box'
import {
  faCloud,
  faRightLong,
  faLeftLong
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { SxProps } from '@mui/system'
import { useAuth } from '../../Provider/AuthProvider'
import { detailReport } from '../../types/dashboard'
import { useLoading } from '../../Provider/LoadingProvider'
import { getLogsTicket } from '../../services/dashboard'
import { ticket_status } from '../../services/whatsapp'
import PhotoTooltip from '../../components/tooltips/img'
import Select from '../../components/Form/select2'
import Button from '../../components/Form/Button'
import ImageUpload from '../../components/Form/ImageUpload'
import Modal from '../GeneralComponents/Modal'

import TextField from '@mui/material/TextField'

export interface ReportModalProps {
  reportId: string
  onClose: () => void
}

const ModifyStateModal: React.FC<ReportModalProps> = ({
  reportId,
  onClose
}) => {
  const [report, setReport] = useState<detailReport | null>(null)

  const [step, setStep] = useState(1)
  const [error, setError] = useState(false)
  const [open, setOpen] = useState<number>(0)

  const { startLoading, stopLoading } = useLoading()

  const [formState, setFormState] = useState<{
    image: File | null
    status: string
    concept: string
    assignToAdmin: string
  }>({
    image: null,
    status: '',
    concept: '',
    assignToAdmin: ''
  })

  const { user, token } = useAuth()

  useEffect(() => {
    getTicketData(reportId)
  }, [reportId])

  const getTicketData = async (_id: string) => {
    try {
      startLoading()
      const data = await getLogsTicket(reportId)
      setReport(data)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const handleChange = (name: string, value: string | boolean) => {
    setFormState((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const options = [
    { value: 'Open', label: 'Abierto' },
    { value: 'Close', label: 'Cerrado' },
    { value: 'Hold', label: 'En Espera' },
    { value: 'Evaluating', label: 'Evaluando' },
    { value: 'Spam', label: 'Irrelevante' }
  ]

  const optionsChangeStatus = [
    { value: 'Hold', label: 'En Espera' },
    { value: 'Close', label: 'Cerrar' },
    { value: 'Evaluating', label: 'Enviar a evaluación' },
    { value: 'Spam', label: 'Enviar a irrelevante' }
  ]

  const priority: {
    [key: string]: string
  } = {
    Emergency: 'Emergencia',
    Report: 'Reporte',
    Express: 'Ticket Express',
    CheckPoint: 'CheckPoint'
  }

  const sendTicketStaff = async () => {
    if (!report) return

    startLoading()

    if (formState.concept.length < 5) {
      setError(true)
      stopLoading()
      return
    } else {
      setError(false)
      try {
        const data = new FormData()

        Object.entries({
          image: formState.image,
          status: formState.status,
          concept: formState.concept,
          assignToAdmin: formState.assignToAdmin,
          reportId: report?.id
        }).forEach(([key, value]) => {
          if (value !== null && value !== undefined) {
            data.append(key, value as Blob | string)
          }
        })

        await ticket_status(data, token)

        onClose()
      } catch (error) {
        console.error(error)
      } finally {
        stopLoading()
      }
    }
  }
  if (!report) return <div className='modal'>.</div>

  const handleNext = () => setStep(2)
  const handleBack = () => setStep(1)

  const textFieldStyles: SxProps = {
    '& .MuiInputBase-input': {
      color: 'white'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white'
      },
      '&:hover fieldset': {
        borderColor: 'white'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white'
      }
    },
    '& .MuiFormLabel-root': {
      color: 'white'
    },
    '& .MuiFormHelperText-root': {
      color: 'white'
    }
  }

  const Waring: React.FC = () => {
    return (
      <div className='WaringModal'>
        <div className='Waring-header'>
          <h2>ADVERTENCIA</h2>
        </div>
        <div className='Waring-text'>
          No puedes cambiar el estado de este ticket ya que{' '}
          <u>no lo tienes asignado</u>
        </div>

        <div className='Waring-Buttons'>
          <Button text='COMPRENDO' styleType={`normal`} onClick={onClose} />
        </div>
      </div>
    )
  }

  const Waring2: React.FC = () => {
    return (
      <div className='WaringModal'>
        <div className='Waring-header'>
          <h2>ADVERTENCIA</h2>
        </div>
        <div className='Waring-text'>
          Actualmente éste ticket no está asignado a ningún trabajador, si
          continuas será asignado a ti como administrador.
        </div>

        <div className='Waring-Buttons'>
          <Button text='Cerrar' styleType={`normal`} onClick={onClose} />

          <Button
            text='Continuar'
            styleType={`normal`}
            onClick={() => {
              setOpen(0)
              handleNext()
              handleChange('assignToAdmin', 'true')
            }}
          />
        </div>
      </div>
    )
  }

  const closeModal = () => {
    setOpen(0)
    setFormState({
      image: null,
      status: '',
      concept: '',
      assignToAdmin: ''
    })
  }

  const handleChangesSelect = (status: string) => {
    handleChange('status', status)

    if (!status) return

    if (!report?.responsibleID) return setOpen(2)

    if (report?.responsibleID !== user?.id) {
      return setOpen(1)
    }
  }

  return (
    <div
      className={`modal-content ${step === 2 ? 'slide-left' : 'slide-right'}`}>
      <div className='step step-1'>
        <div className='modal-header'>
          <h2>Modificar estatus</h2>
          <p>
            <span>Código:</span> {report.shortID}
          </p>
        </div>

        <div className='log-entry'>
          <div className='time'>
            <div>
              <span>Fecha: </span> {report.createdAt}
            </div>

            <div>
              <span>Creado por: </span> {report.createdName}
            </div>
          </div>
          <div className='separate'>
            {
              <div className={`separateTicketPhotoLast`}>
                <PhotoTooltip
                  photos={[report.PhotoCreatedAt]}
                  altText={report.shortID}
                  altPhoto='images/linear.png'
                  className='ticket-photo'
                />
              </div>
            }
          </div>
          <div className='dataLogs'>
            <div>
              <span>Prioridad: </span>
              {priority[report.priority]}
            </div>
            <div>
              <span>Estado actual: </span>
              {options.find((option) => option.value === report.status)
                ?.label || 'Pendiente'}
            </div>
            {report.resolvedById && (
              <div>
                <span>
                  {' '}
                  {report.priority === 'Express'
                    ? 'Notificado'
                    : 'Tomado Por:'}{' '}
                </span>{' '}
                {report.resolvedById}
              </div>
            )}
          </div>
        </div>

        <div className='centerSelectStaff'>
          <span>Descripción:</span> <p>{report.description}</p>
        </div>

        <div className='centerSelectStaff'>
          <span>Selecciona a que estatus deseas cambiar el ticket:</span>

          <Select
            style={{ width: '140px' }}
            placeholder={'Nuevo estado'}
            options={optionsChangeStatus.map((data) => ({
              value: data.value,
              label: data.label
            }))}
            value={formState?.status}
            Name='staff'
            onChange={(value) => handleChangesSelect(value.target.value)}
          />
        </div>

        <Button
          className='containerButtons'
          text='Siguiente'
          styleType={
            formState?.status && formState?.status !== report.status
              ? `normal`
              : `disable`
          }
          disabled={
            formState?.status && formState?.status !== report.status
              ? false
              : true
          }
          icon={
            <FontAwesomeIcon
              style={{ marginRight: '10px' }}
              icon={faRightLong}
            />
          }
          onClick={handleNext}
        />
      </div>
      <div className='step step-2'>
        <div className='modal-header'>
          <h2>Modificar estatus</h2>
        </div>
        <Box
          component='form'
          sx={{
            '& .MuiTextField-root': { m: 1, width: '100%' }
          }}
          noValidate
          autoComplete='off'>
          <div style={{ width: '98%' }}>
            <TextField
              id='expandable-textfield'
              label='Describe con texto lo realizado.'
              multiline
              value={formState.concept}
              onChange={(event) => handleChange('concept', event.target.value)}
              error={error}
              helperText={error ? 'Debe tener al menos 5 caracteres.' : ''}
              variant='outlined'
              minRows={1}
              maxRows={3}
              fullWidth
              sx={textFieldStyles}
            />
          </div>
        </Box>

        <ImageUpload
          label={'Imagen (Opcional)'}
          key={'image'}
          value={formState.image ? formState.image : null}
          onChange={(value: any) => handleChange('image', value)}
        />
        <div className='containerButtons'>
          <Button
            text='Atrás'
            styleType={`normal`}
            onClick={handleBack}
            icon={
              <FontAwesomeIcon
                style={{ marginRight: '10px' }}
                icon={faLeftLong}
              />
            }
          />

          <Button
            text='Guardar'
            styleType={`normal`}
            onClick={sendTicketStaff}
            icon={
              <FontAwesomeIcon style={{ marginRight: '10px' }} icon={faCloud} />
            }
          />
        </div>
      </div>
      {formState && (
        <>
          <Modal
            styleContainer={{ width: '520px', height: '260px' }}
            isOpen={open === 1}
            onClose={closeModal}>
            <Waring />
          </Modal>

          <Modal
            styleContainer={{ width: '520px', height: '260px' }}
            isOpen={open === 2}
            onClose={closeModal}>
            <Waring2 />
          </Modal>
        </>
      )}
    </div>
  )
}

export default ModifyStateModal
