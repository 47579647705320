import React, { FC, useEffect, useState } from 'react'
import { useLoading } from '../../../Provider/LoadingProvider'
import { deleteLocation, listLocationsClient } from '../../../services/location'
import { Location } from '../../../types/location'
import { Pagination } from '../../../types/types'
import DeleteButton from '../../Table/components/DeleteButton'
import Modal from '../../GeneralComponents/Modal'
import Table from '../../Table'
import {
  TypeLocationEnum,
  columnsLocation,
  convertTypeLocation
} from './Columns'
import FormLocation from './Form'
import { IconButton } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
const LocationByClientTable: FC<{ id: string }> = ({ id }) => {
  const [data, setData] = useState<Pagination<Location>>({
    currentPage: 1,
    hasNextPage: false,
    hasPrevPage: false,
    total: 1,
    totalPages: 1,
    data: []
  })
  const [search, setSearch] = useState('')
  const { startLoading, stopLoading } = useLoading()
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<Location | undefined>(undefined)

  useEffect(() => {
    getDataLocation('', 1)
  }, [])

  const onCloseModal = () => {
    setOpen(false)
    setSelected(undefined)
  }

  const handleOpenModal = () => {
    setOpen(true)
  }

  const handleDelete = async (id: string) => {
    try {
      startLoading()
      await deleteLocation(id)
      getDataLocation(search.length > 3 ? search : '', data.currentPage)
    } catch (error) {
      console.error('Error al eliminar el administrador:', error)
    } finally {
      stopLoading()
    }
  }
  
  const handleView = (item: Location) => {
    setSelected(item)
  }
  const Actions = ({ id, item }: { id: string; item?: Location }) => {
    return (
      <>
        <IconButton onClick={() => handleView(item as Location)}>
          <VisibilityIcon style={{ color: 'white' }} />
        </IconButton>
        <DeleteButton OnDelete={() => handleDelete(id)} />
      </>
    )
  }

  const handlePageChange = (newPage: number) => {
    getDataLocation(search.length >= 3 ? search : '', newPage)
  }

  const getDataLocation = async (searchValue: string, page: number) => {
    try {
      startLoading()
      const administrators = await listLocationsClient({
        id,
        params: { page, search: searchValue }
      })
      setData({
        ...administrators,
        data: administrators.data.map((e) => ({
          ...e,
          typeName: convertTypeLocation(e.type as TypeLocationEnum)
        }))
      })
    } catch (error) {
      console.error('Error al obtener los administradores:', error)
    } finally {
      stopLoading()
    }
  }

  const handleSubmit = () => {
    setOpen(false)
    getDataLocation(search.length > 3 ? search : '', data.currentPage)
  }
  useEffect(() => {
    if (selected) {
      setOpen(true)
    }
  }, [selected])

  return (
    <>
      <div className='title'>
        <h2>Locaciones</h2>
      </div>
      <Table
        onPageChange={handlePageChange}
        onChangeSearch={setSearch}
        pagination={data}
        handleOpenModal={handleOpenModal}
        columns={columnsLocation}
        Actions={Actions}
      />
      <Modal
        styleContainer={{ width: '900px' }}
        isOpen={open}
        onClose={onCloseModal}>
        <FormLocation
          initialValues={selected}
          clientId={id}
          onSubmit={handleSubmit}
        />
      </Modal>
    </>
  )
}

export default LocationByClientTable
