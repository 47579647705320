import React, { FC, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import ThumbUp from '@mui/icons-material/ThumbUp'
import PopUpConfirm from '../PopupConfirm'
import { approve_entry } from '../../../../services/entries'
import { useAuth } from '../../../../Provider/AuthProvider'

const ValidateEntry: FC<{ id: string }> = ({ id }) => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false)
  const { token } = useAuth()
  const handleAccept = async () => {
    try {
      setIsPopUpOpen(false)
      await approve_entry({ id, token })
    } catch (error) {
      console.error(error)
    }
  }

  const handleReject = () => {
    setIsPopUpOpen(false)
  }

  return (
    <div>
      <IconButton onClick={() => setIsPopUpOpen(true)}>
        <ThumbUp style={{ color: 'white' }} />
      </IconButton>

      <PopUpConfirm
        isOpen={isPopUpOpen}
        onClose={() => setIsPopUpOpen(false)}
        title="¿Estás seguro de aprobar el ingreso?"
        description="Esta acción no se puede deshacer."
        acceptText="Aceptar"
        rejectText="Cancelar"
        handleAccept={handleAccept}
        handleReject={handleReject}
      />
    </div>
  )
}

export default ValidateEntry
