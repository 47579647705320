import { Location, LocationCreate } from '../types/location' // Asegúrate de tener la ruta correcta
import { Pagination, PaginationQuery } from '../types/types'
import axiosInstance from './axiosInstance'

export const listLocationsClient = async ({
  id,
  params
}: {
  id: string
  params: PaginationQuery
}): Promise<Pagination<Location>> => {
  try {
    const response = await axiosInstance.get(`/api/locations/byclient/${id}`, {
      params
    })

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de listado de Locaciones:', error)
    throw error
  }
}

export const createLocation = async (params: LocationCreate): Promise<Location> => {
  try {
    const response = await axiosInstance.post('/api/locations', JSON.stringify(params), {})

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de creación de ubicación:', error)
    throw error
  }
}

export const updateLocation = async (
  id: string,
  params: LocationCreate
): Promise<Location> => {
  try {
    const response = await axiosInstance.post(
      `/api/locations/${id}`,
      JSON.stringify(params),
      {}
    )

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de creación de ubicación:', error)
    throw error
  }
}

export const deleteLocation = async (id: string): Promise<void> => {
  try {
    const response = await axiosInstance.delete(`/api/locations/${id}`)

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de eliminación de ubicación:', error)
    throw error
  }
}

export const listLocations = async ({ id }: { id: string }): Promise<Location[]> => {
  try {
    const response = await axiosInstance.get(`/api/locations/${id}`)

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de listado de Locaciones:', error)
    throw error
  }
}
