import jsQR from 'jsqr'
import {
  MultiFormatReader,
  BarcodeFormat,
  DecodeHintType,
  RGBLuminanceSource,
  BinaryBitmap,
  HybridBinarizer
} from '@zxing/library'
import { Jimp } from 'jimp'

export const readQrDataFromBase64 = async (imageBuffer: Buffer): Promise<string | false> => {
  try {
    if (!imageBuffer) return false

    // Función para procesar la imagen y leer el QR con ZXing
    const decodeQrFromImageZXing = (image: any): string | false => {
      try {
        const formats = [BarcodeFormat.QR_CODE]
        const hints = new Map()
        hints.set(DecodeHintType.POSSIBLE_FORMATS, formats)
        hints.set(DecodeHintType.TRY_HARDER, true)

        const reader = new MultiFormatReader()
        reader.setHints(hints)

        const len = image.bitmap.width * image.bitmap.height
        const luminancesUint8Array = new Uint8ClampedArray(len)

        for (let i = 0; i < len; i++) {
          luminancesUint8Array[i] =
            ((image.bitmap.data[i * 4] +
              image.bitmap.data[i * 4 + 1] * 2 +
              image.bitmap.data[i * 4 + 2]) /
              4) &
            0xff
        }

        const luminanceSource = new RGBLuminanceSource(
          luminancesUint8Array,
          image.bitmap.width,
          image.bitmap.height
        )
        const binaryBitmap = new BinaryBitmap(new HybridBinarizer(luminanceSource))

        const decoded = reader.decode(binaryBitmap)
        return decoded.getText()
      } catch (err) {
        return false
      }
    }

    // Función para procesar la imagen y leer el QR con jsQR
    const decodeQrFromImageJsQR = (image: any): string | false => {
      try {
        const width = image.bitmap.width
        const height = image.bitmap.height

        // Obtener los datos de la imagen como un arreglo de píxeles
        const imageData = new Uint8ClampedArray(width * height * 4)
        for (let i = 0; i < width; i++) {
          for (let j = 0; j < height; j++) {
            const idx = (j * width + i) << 2
            imageData[idx] = image.bitmap.data[idx]
            imageData[idx + 1] = image.bitmap.data[idx + 1]
            imageData[idx + 2] = image.bitmap.data[idx + 2]
            imageData[idx + 3] = 255
          }
        }

        // Decodificar el QR
        const decoded = jsQR(imageData, width, height)
        return decoded ? decoded.data : false
      } catch (err) {
        return false
      }
    }

    // Estrategias de procesamiento de imagen
    const processImageVariants = async (image: any): Promise<string | false> => {
      const attempts: (() => string | false)[] = []

      // Intentos con jsQR y ZXing
      attempts.push(() => decodeQrFromImageJsQR(image))
      attempts.push(() => decodeQrFromImageZXing(image))

      attempts.push(() => decodeQrFromImageJsQR(image.blur(1)))
      attempts.push(() => decodeQrFromImageZXing(image))

      attempts.push(() => decodeQrFromImageJsQR(image.invert()))
      attempts.push(() => decodeQrFromImageZXing(image))

      attempts.push(() => decodeQrFromImageJsQR(image.brightness(0.3).contrast(0.5)))
      attempts.push(() => decodeQrFromImageZXing(image))

      attempts.push(() => decodeQrFromImageJsQR(image.greyscale()))
      attempts.push(() => decodeQrFromImageZXing(image))

      // Ejecutamos todas las promesas en paralelo
      const results = await Promise.all(attempts.map(attempt => attempt()))

      // Filtramos el primer resultado que no sea `false`
      const validResult = results.find(result => result !== false)

      if (validResult) {
        console.log(`QR Code Decoded: ${validResult}`)
        return validResult // Retorna el primer valor válido
      }

      console.error('No valid QR code found after all attempts')
      return false
    }
    // Procesar la imagen con Jimp
    const image = await Jimp.read(imageBuffer)
    const qrCode = await processImageVariants(image)

    if (!qrCode) return false

    return qrCode
  } catch (err) {
    console.error('Error Reading QR Code:', err)
    return false
  }
}
