import React, { FC, useEffect, useState, useRef } from 'react'
import { useLoading } from '../../../Provider/LoadingProvider'
import { Pagination } from '../../../types/types'
import Modal from '../../GeneralComponents/Modal'
import Table from '../../Table'
import {
  deleteStaff,
  listStaffClient,
  NotificationStaff
} from '../../../services/staff'
import { Staff } from '../../../types/staff'
import DeleteButton from '../../Table/components/DeleteButton'
import { columnsStaff } from './Columns'
import FormStaff from './Form'
import {
  Visibility,
  Notifications,
  NotificationsOff
} from '@mui/icons-material'
import { IconButton } from '@mui/material'

const ReceptionistByClientTable: FC<{ id: string }> = ({ id }) => {
  const [data, setData] = useState<Pagination<Staff>>({
    currentPage: 1,
    hasNextPage: false,
    hasPrevPage: false,
    total: 1,
    totalPages: 1,
    data: []
  })
  const limit = 6
  const [search, setSearch] = useState('')
  const { startLoading, stopLoading } = useLoading()
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<Staff | undefined>(undefined)
  const isFirstRender = useRef(true)

  useEffect(() => {
    getDataStaff('', 1)
  }, [])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    const delayDebounceFn = setTimeout(() => {
      getDataStaff(search, 1)
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const onCloseModal = () => {
    setSelected(undefined)
    setOpen(false)
  }
  const handleOpenModal = () => {
    setOpen(true)
  }
  const handleDelete = async (id: string) => {
    try {
      startLoading()
      await deleteStaff(id)
      getDataStaff(search, data.currentPage)
      stopLoading()
    } catch (error) {
      console.error(error)
    }
  }
  const handleView = (value: Staff) => {
    setSelected(value)
  }

  const toggleNotification = async (StaffId: string, notification: boolean) => {
    try {
      startLoading()
      await NotificationStaff(StaffId, !notification)
      await getDataStaff(search, data.currentPage)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const Actions = ({ id, item }: { id: string; item: Staff }) => {
    return (
      <div className='actionsContainer'>
        <div
          onClick={() => toggleNotification(item?.id, item.enableNotification)}>
          {item.enableNotification ? (
            <Notifications style={{ color: 'green' }} />
          ) : (
            <NotificationsOff style={{ color: 'red' }} />
          )}
        </div>
        <IconButton onClick={() => handleView(item as Staff)}>
          <Visibility style={{ color: 'white' }} />
        </IconButton>
        <DeleteButton OnDelete={() => handleDelete(id)} />
      </div>
    )
  }
  
  const handlePageChange = (newPage: number) => {
    getDataStaff(search, newPage)
  }

  const getDataStaff = async (searchValue: string, page: number) => {
    try {
      startLoading()
      const staff = await listStaffClient({
        id,
        params: { page, search: searchValue, limit }
      })
      setData(staff)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const handleSubmit = () => {
    setOpen(false)
    getDataStaff(search, data.currentPage)
  }

  useEffect(() => {
    if (selected) {
      setOpen(true)
    }
  }, [selected])
  return (
    <>
      <div className='title'>
        <h4>Trabajadores</h4>
      </div>
      <Table
        onPageChange={handlePageChange}
        onChangeSearch={setSearch}
        pagination={data}
        handleOpenModal={handleOpenModal}
        columns={columnsStaff}
        Actions={Actions as any}
        itemsPerPage={limit}
      />
      <Modal
        styleContainer={{ width: '900px' }}
        isOpen={open}
        onClose={onCloseModal}>
        <FormStaff
          initialValue={selected}
          clientId={id}
          onSubmit={handleSubmit}
        />
      </Modal>
    </>
  )
}

export default ReceptionistByClientTable
