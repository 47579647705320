// LoadingProvider.tsx
import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react'
import { createPortal } from 'react-dom'
import Loading from '../components/GeneralComponents/Loading'
import CustomMessage, { MessageType } from '../components/CustomMessage'

type LoadingContextType = {
  isLoading: boolean
  startLoading: () => void
  stopLoading: () => void
  isElectron: boolean
  showMessage: (type: MessageType, message: string) => void
}

const LoadingContext = createContext<LoadingContextType | undefined>(undefined)

type LoadingProviderProps = {
  children: ReactNode
}

const LoadingProvider: React.FC<LoadingProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [messageType, setMessageType] = useState<MessageType>('success')
  const [message, setMessage] = useState('')
  const [messageVisible, setMessageVisible] = useState(false)

  const startLoading = useCallback(() => {
    setIsLoading(true)
  }, [])

  const stopLoading = useCallback(() => {
    setIsLoading(false)
  }, [])

  const showMessage = (type: MessageType, message: string) => {
    setMessageType(type)
    setMessage(message)
    setMessageVisible(true)
  }

  const hideMessage = useCallback(() => {
    setMessageVisible(false)
  }, [])

  const isInElectron = window && (window as any).ipcRenderer

  const contextValue: LoadingContextType = {
    isLoading,
    startLoading,
    stopLoading,
    isElectron: isInElectron ? true : false,
    showMessage
  }

  return (
    <LoadingContext.Provider value={contextValue}>
      <>
        {createPortal(isLoading && <Loading />, document.body)}
        {children}
        <CustomMessage
          type={messageType}
          message={message}
          visible={messageVisible}
          onClose={hideMessage}
        />
      </>
    </LoadingContext.Provider>
  )
}

const useLoading = () => {
  const context = useContext(LoadingContext)
  if (!context) {
    throw new Error('useLoading must be used within LoadingProvider')
  }
  return context
}

export { LoadingProvider, useLoading }
