import { entries } from '../types/entries'
import axiosInstance from './axiosInstance'

export const createVisitor = async (params: FormData, token?: string): Promise<entries> => {
  const headers = token
    ? {
        Authorization: `Bearer ${token}`
      }
    : {}
  try {
    const response = await axiosInstance.post(`/api/visitor`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;',
        ...headers
      }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de inicio de sesión:', error)
    throw error
  }
}
