// equipmentConfig.ts
import { FieldTypeEnum, FormField } from '../../Form/DynamicForm'
import { TableColumn, enumTypeColumn } from '../../Table'

export const columnsEquipment: TableColumn[] = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    type: enumTypeColumn.string
  },
  {
    title: 'Tipo',
    dataIndex: 'typeShow',
    key: 'typeShow',
    type: enumTypeColumn.string
  },
  {
    title: 'Serial',
    dataIndex: 'serial',
    key: 'serial',
    type: enumTypeColumn.string
  },
  {
    title: 'Locación',
    dataIndex: 'locationName',
    key: 'locationName',
    type: enumTypeColumn.string
  }
]

export const equipmentFormConfig: FormField[] = [
  { type: FieldTypeEnum.text, label: 'Nombre', name: 'name' },
  {
    type: FieldTypeEnum.select,
    label: 'Tipo',
    name: 'type',
    options: [
      {
        value: 'IN',
        label: 'IN'
      },
      {
        value: 'OUT',
        label: 'OUT'
      },
      {
        value: 'BOTH',
        label: 'AMBOS'
      }
    ]
  },
  { type: FieldTypeEnum.text, label: 'Serial', name: 'serial' }
]
