// Home.js
import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../../Provider/AuthProvider'
import { roleEnum } from '../../types/user'

const Home = () => {
  const { user } = useAuth()

  if (user?.role === roleEnum.SUPER_ADMIN) {
    return <Navigate to='/clients' />
  } else if (user?.role === roleEnum.CORP) {
    return <Navigate to='/dashboard_corp' />
  } else if (user?.role === roleEnum.ADMIN) {
    return <Navigate to='/resident' />
  } else if (user?.role === roleEnum.RECEPTION) {
    return <Navigate to='/check_in' />
  } else {
    return <div></div>
  }
}

export default Home
