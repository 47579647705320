import React, { useRef, useCallback, useState, useEffect } from 'react'
import './style.scss' // Asegúrate de tener el archivo de estilos adecuado

interface FileUploadProps {
  label: string
  value: File | null
  onChange: (file: File | null) => void
}

const FileUpload: React.FC<FileUploadProps> = ({ label, value, onChange }) => {
  const [isDragging, setIsDragging] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [fileName, setFileName] = useState<string>('')

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (value instanceof File) {
      setFileName(value.name)
    } else {
      setFileName('')
    }
  }, [value])

  const handleDragEnter = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(true)
  }, [])

  const handleDragLeave = useCallback(() => {
    setIsDragging(false)
  }, [])

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault()
      setIsDragging(false)

      const files = e.dataTransfer.files
      if (files.length > 0) {
        const selectedFile = files[0]
        onChange(selectedFile)
      }
    },
    [onChange]
  )

  const handleFileInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files
      if (files && files.length > 0) {
        const selectedFile = files[0]
        onChange(selectedFile)
      }
    },
    [onChange]
  )

  const openFilePicker = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }, [])

  const handleDeleteClick = () => {
    onChange(null)
  }

  return (
    <div
      className={`image-upload ${isDragging ? 'dragging' : ''}`}
      onDragEnter={handleDragEnter}
      onDragOver={(e) => e.preventDefault()}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={openFilePicker}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <input
        ref={inputRef}
        type='file'
        style={{ display: 'none' }}
        onChange={handleFileInputChange}
      />
      {!fileName &&
        (isDragging ? (
          <p>Suelta aquí</p>
        ) : (
          <p>{`Arrastra y suelta o sube ${label ?? ''}`}</p>
        ))}
      {fileName && (
        <div className={`image-container ${isHovered ? 'hovered' : ''}`}>
          <span>{fileName}</span>
          <button
            type='button'
            className='close-button'
            onClick={(e) => {
              e.stopPropagation()
              handleDeleteClick()
            }}>
            X
          </button>
        </div>
      )}
    </div>
  )
}

export default FileUpload
