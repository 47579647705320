import React, { FC } from 'react'
import { useCamera } from '../../../Provider/CameraProvider'
import './styles.scss'
import { Select, MenuItem, Button, SelectChangeEvent } from '@mui/material'

const PopUpConfig: FC<{ handleClose: () => void }> = ({ handleClose }) => {
  const { devices, cameraDNI, cameraPhoto, setCameraDNI, setCameraPhoto } =
    useCamera()

  const handleCameraDNI = (e: SelectChangeEvent<string>) => {
    const value = e.target.value
    setCameraDNI(value)
    localStorage.setItem('cameraDNI', value)
  }

  const handleCameraPhoto = (e: SelectChangeEvent<string>) => {
    const value = e.target.value
    setCameraPhoto(value)
    localStorage.setItem('cameraPhoto', value)
  }

  return (
    <div className='popup-overlay-config'>
      <div className='popup'>
        <h3>Configuración de Cámara</h3>
        <label className='labelPop'>
          Cámara para DNI:
          <Select
            MenuProps={{
              style: {
                zIndex: 999999,
                color: 'white',
                borderColor: 'white'
              }
            }}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white'
              }
            }}
            style={{
              color: 'white',
              borderColor: 'white'
            }}
            value={cameraDNI || ''}
            onChange={handleCameraDNI}
            fullWidth>
            {devices.map((device) => (
              <MenuItem key={device.deviceId} value={device.deviceId}>
                {device.label || `Cámara ${device.deviceId}`}
              </MenuItem>
            ))}
          </Select>
        </label>
        <label className='labelPop'>
          Cámara para Foto de Rostro:
          <Select
            MenuProps={{
              style: {
                zIndex: 999999,
                color: 'white'
              }
            }}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white'
              }
            }}
            style={{
              color: 'white',
              borderColor: 'white'
            }}
            value={cameraPhoto || ''}
            onChange={handleCameraPhoto}
            fullWidth>
            {devices.map((device) => (
              <MenuItem key={device.deviceId} value={device.deviceId}>
                {device.label || `Cámara ${device.deviceId}`}
              </MenuItem>
            ))}
          </Select>
        </label>
        <Button variant='contained' color='primary' onClick={handleClose}>
          Cerrar
        </Button>
      </div>
    </div>
  )
}

export default PopUpConfig
