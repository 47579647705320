import { PaymentDateType } from '../types/client'
import axiosInstance from './axiosInstance'

export const savePaymentDate = async (params: PaymentDateType): Promise<boolean> => {
  const headers = {
    'Content-Type': 'application/json'
  }

  try {
    const response = await axiosInstance.post(
      `/api/manageress/savePaymentDate`,
      { paymentDate:  params  },
      {
        headers
      }
    )
    return response.status === 200
  } catch (error) {
    console.error('Error al guardar PaymentDate:', error)
    return false
  }
}
