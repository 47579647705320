import React, { FC } from 'react'
import DynamicForm from '../../Form/DynamicForm'
import './styles.scss'

import { useLoading } from '../../../Provider/LoadingProvider'

import { createOrigin, updateOrigin } from '../../../services/origin'
import { origin, originCreate } from '../../../types/origin'
import { originFormConfig } from './Columns'

const FormOrigin: FC<{ onSubmit: () => void; initialValue?: origin }> = ({
  onSubmit,
  initialValue
}) => {
  const { startLoading, stopLoading } = useLoading()

  const handleSubmit = async (formData: originCreate) => {
    try {
      startLoading()
      const data = {
        name: formData.name
      }
      if (initialValue) {
        await updateOrigin(initialValue.id, data)
      } else {
        await createOrigin(data)
      }

      onSubmit()
    } catch (error) {
      // Manejo de errores
    } finally {
      stopLoading()
    }
  }

  return (
    <div className='newOriginForm'>
      <h2>Nuevo origen</h2>
      <DynamicForm
        initialValues={initialValue}
        formConfig={originFormConfig}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

export default FormOrigin
