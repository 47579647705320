import { Client } from '../types/client'
import { Pagination, PaginationQuery } from '../types/types'
import axiosInstance from './axiosInstance'

export const createClient = async (params: FormData): Promise<Client> => {
  try {
    const response = await axiosInstance.post(`/api/clients`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de inicio de sesión:', error)
    throw error
  }
}

export const updateClient = async (id: string, params: FormData): Promise<Client> => {
  try {
    const response = await axiosInstance.post(`/api/clients/${id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de inicio de sesión:', error)
    throw error
  }
}
export const enableClient = async (id: string, params: FormData): Promise<Client> => {
  try {
    const response = await axiosInstance.post(`/api/clients/enable/${id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de inicio de sesión:', error)
    throw error
  }
}
export const listClient = async (params: PaginationQuery): Promise<Pagination<Client>> => {
  try {
    const response = await axiosInstance.get(`/api/clients`, {
      params
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de inicio de sesión:', error)
    throw error
  }
}

export const getClient = async (clientId: string): Promise<Client> => {
  try {
    const response = await axiosInstance.get(`/api/clients/${clientId}`)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de inicio de sesión:', error)
    throw error
  }
}

export const handleNotificationClient = async (
  id: string,
  value: boolean
): Promise<Client> => {
  try {
    const response = await axiosInstance.post(`/api/clients/notification/${id}`, {
      notification: value
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de inicio de sesión:', error)
    throw error
  }
}

export const updateMaxGeneration = async (
  id: string,
  infiniteGeneration: boolean,
  maxGeneration: number
): Promise<Client> => {
  try {
    const response = await axiosInstance.post(`/api/clients/qr_limit/${id}`, {
      infiniteGeneration,
      maxGeneration
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de inicio de sesión:', error)
    throw error
  }
}

export const deleteClient = async (id: string): Promise<void> => {
  try {
    const response = await axiosInstance.delete(`/api/clients/${id}`)

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de eliminación de ubicación:', error)
    throw error
  }
}
