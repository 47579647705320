import React, { FC, useEffect, useRef, useState } from 'react'
import { io } from 'socket.io-client'
import { NotificationPlacement } from 'antd/es/notification/interface'
import { LikeOutlined, DislikeOutlined, StopOutlined } from '@ant-design/icons'
import { entries, entriesApprovedEnum } from '../../../types/entries'
import { ConfigProvider, notification } from 'antd'
import { typeQrEnumStringCheckPointCentral } from '../../../types/qr'
import './style.scss'
import moment from 'moment'

type NotificationsProps = {
  userId: string | undefined
  setNewEntrySocket?: (entry: entries) => void
}

const SocketReadQr: FC<NotificationsProps> = ({ userId, setNewEntrySocket }) => {
  const [api, contextHolder] = notification.useNotification({ stack: { threshold: 1 } })
  const CODIGO_DESCONOCIDO = useRef<HTMLAudioElement>(null)
  const INVITADO_AUTORIZADO = useRef<HTMLAudioElement>(null)
  const INVITADO_NO_AUTORIZADO = useRef<HTMLAudioElement>(null)
  const RESIDENTE_AUTORIZADO = useRef<HTMLAudioElement>(null)
  const RESIDENTE_NO_AUTORIZADO = useRef<HTMLAudioElement>(null)
  const TRABAJADOR_AUTORIZADO = useRef<HTMLAudioElement>(null)
  const TRABAJADOR_NO_AUTORIZADO = useRef<HTMLAudioElement>(null)

  const [userInteracted, setUserInteracted] = useState(false)

  const positionsStylesSvg: React.CSSProperties | undefined = {
    position: 'fixed',
    fontSize: '40px',
    bottom: '30%',
    right: '30px'
  }

  const statusSound: {
    [key: string]: {
      aproved: React.RefObject<HTMLAudioElement>
      noAproved: React.RefObject<HTMLAudioElement>
    }
  } = {
    VISITANTE: { aproved: INVITADO_AUTORIZADO, noAproved: INVITADO_NO_AUTORIZADO },
    INVITADO: { aproved: INVITADO_AUTORIZADO, noAproved: INVITADO_NO_AUTORIZADO },
    RESIDENTE: { aproved: RESIDENTE_AUTORIZADO, noAproved: RESIDENTE_NO_AUTORIZADO },
    'RESIDENTE D': { aproved: RESIDENTE_AUTORIZADO, noAproved: RESIDENTE_NO_AUTORIZADO },
    TRABAJADOR: { aproved: TRABAJADOR_AUTORIZADO, noAproved: TRABAJADOR_NO_AUTORIZADO },
    PERSONAL: { aproved: TRABAJADOR_AUTORIZADO, noAproved: TRABAJADOR_NO_AUTORIZADO }
  }

  const handleUserInteraction = () => {
    setUserInteracted(true)
    document.removeEventListener('click', handleUserInteraction)
    document.removeEventListener('keydown', handleUserInteraction)
    document.removeEventListener('mousemove', handleUserInteraction) // También mousemove
    document.removeEventListener('scroll', handleUserInteraction) // También scroll
  }

  useEffect(() => {
    document.addEventListener('click', handleUserInteraction)
    document.addEventListener('keydown', handleUserInteraction)
    document.addEventListener('mousemove', handleUserInteraction)
    document.addEventListener('scroll', handleUserInteraction)

    const socketWhats = io(process.env.REACT_APP_API_URL_BACKEND)
    socketWhats.on(
      `readQr-${userId}`,
      ({ entry, valid }: { entry?: entries; valid: boolean }) => {
        if (!entry) {
          api.open({
            message: (
              <span className="containerBan">
                <h3> {`CÓDIGO DESCONOCIDO`} </h3>
                <div className="timeTapReadQr">{moment().format('h:mm a')}</div>
                <StopOutlined
                  style={{
                    color: 'red',
                    position: 'fixed',
                    fontSize: '40px',
                    bottom: '15%',
                    right: '42%',
                    backgroundColor: '#263371'
                  }}
                />
              </span>
            ),

            placement: 'topRight' as NotificationPlacement,
            duration: 10,
            showProgress: true,
            pauseOnHover: true,
            className: 'SocketsreactQR',
            style: {
              width: '370px',
              backgroundColor: '#263371',
              padding: '10px',
              height: '115px',
              borderRadius: '20px'
            }
          })

          const soundTrack = CODIGO_DESCONOCIDO

          if (soundTrack.current && userInteracted) {
            soundTrack.current.play()
          }
          return
        }

        const type = entry.qrCode?.slice(
          0,
          4
        ) as keyof typeof typeQrEnumStringCheckPointCentral

        const values = typeQrEnumStringCheckPointCentral[type]
        const validSound: 'noAproved' | 'aproved' = valid ? 'aproved' : 'noAproved'

        const soundTrack = statusSound[values][validSound] //aplicar audio correspondiente
        api.open({
          message: (
            <span className="containerBan">
              <h3> {`${values} ${valid ? 'AUTORIZADO' : 'NO AUTORIZADO'} `} </h3>
              <div className="containerData">
                <div style={{ width: '70%' }}>
                  <h5>
                    {entry.name} {entry.lastName ? `${entry.lastName}` : ''}
                  </h5>
                </div>

                <div>
                  <p> {entry.destination ? `DESTINO:${entry.destination}` : ''}</p>
                  <p> {entry.namesResident ? `RESIDENTE:${entry.namesResident}` : ''}</p>
                </div>
              </div>
              <div className="timeTapReadQr">{moment().format('h:mm a')}</div>
              {valid && (
                <LikeOutlined
                  style={{
                    color: '#f600c0',
                    backgroundColor: '#263371',
                    ...positionsStylesSvg
                  }}
                />
              )}
              {!valid && (
                <DislikeOutlined
                  style={{
                    color: 'red',
                    backgroundColor: '#263371',
                    ...positionsStylesSvg
                  }}
                />
              )}
            </span>
          ),

          placement: 'topRight' as NotificationPlacement,
          duration: 10,
          showProgress: true,
          pauseOnHover: true,
          className: 'SocketsreactQR',
          style: {
            width: '370px',
            backgroundColor: '#263371',
            padding: '10px',
            borderRadius: '20px'
          }
        })

        if (soundTrack.current && userInteracted) {
          soundTrack.current.play()
        }

        entry.approved = valid ? entriesApprovedEnum.APPROVED : entriesApprovedEnum.REJECT

        if (setNewEntrySocket) setNewEntrySocket(entry)
      }
    )

    return () => {
      socketWhats.disconnect()

      document.removeEventListener('click', handleUserInteraction)
      document.removeEventListener('keydown', handleUserInteraction)
      document.removeEventListener('mousemove', handleUserInteraction)
      document.removeEventListener('scroll', handleUserInteraction)
    }
  }, [userId, setNewEntrySocket, userInteracted])

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorInfo: '#5b1fca',
            colorPrimary: '#5b1fca',
            colorInfoText: '5b1fca',
            colorLink: '5b1fca',
            colorPrimaryText: '5b1fca'
          }
        }}
      >
        {contextHolder}
      </ConfigProvider>

      <audio ref={CODIGO_DESCONOCIDO} src="sounds/QrRead/CODIGO_DESCONOCIDO.mp3" />
      <audio ref={INVITADO_AUTORIZADO} src="sounds/QrRead/INVITADO_AUTORIZADO.mp3" />
      <audio ref={INVITADO_NO_AUTORIZADO} src="sounds/QrRead/INVITADO_NO_AUTORIZADO.mp3" />
      <audio ref={RESIDENTE_AUTORIZADO} src="sounds/QrRead/RESIDENTE_AUTORIZADO.mp3" />
      <audio ref={RESIDENTE_NO_AUTORIZADO} src="sounds/QrRead/RESIDENTE_NO_AUTORIZADO.mp3" />
      <audio ref={TRABAJADOR_AUTORIZADO} src="sounds/QrRead/TRABAJADOR_AUTORIZADO.mp3" />
      <audio ref={TRABAJADOR_NO_AUTORIZADO} src="sounds/QrRead/TRABAJADOR_NO_AUTORIZADO.mp3" />
    </>
  )
}

export default SocketReadQr
