// administratorConfig.ts
import { FieldTypeEnum, FormField } from './DynamicFormRoutines'
import { TableColumn, enumTypeColumn } from '../../Table'

export const columnsRoutines: TableColumn[] = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    type: enumTypeColumn.string
  },

  {
    title: 'Tipo plan',
    dataIndex: 'TypeRoutine',
    key: 'TypeRoutine',
    type: enumTypeColumn.string
  },

  {
    title: 'Dia/Meses',
    dataIndex: 'DateRoutine',
    key: 'DateRoutine',
    type: enumTypeColumn.string
  },

  {
    title: 'Locaciones',
    dataIndex: 'amountLocaciones',
    key: 'amountLocaciones',
    type: enumTypeColumn.string
  }
]

export const routinesFormConfig: FormField[] = [
  { type: FieldTypeEnum.text, label: 'Nombre', name: 'name' },
  {
    type: FieldTypeEnum.select,
    label: 'Tipo Plan',
    name: 'TypeRoutine',
    options: [
      { label: 'Limpieza', value: 'Limpieza' },
      { label: 'Mantenimiento', value: 'Mantenimiento' },
      { label: 'Seguridad', value: 'Seguridad' }
    ]
  },
  {
    type: FieldTypeEnum.select,
    name: 'Frequency',
    label: 'Frecuencia',
    options: [
      { value: 'Mensual', label: 'Mensual' },
      { value: 'Diario', label: 'Diario' }
    ]
  },
  {
    type: FieldTypeEnum.datePicker,
    name: 'MensualOptions',
    label: 'Seleccionar Fechas/Meses',
    dependentOn: 'Frequency',
    dependentValue: 'Mensual'
  },
  {
    type: FieldTypeEnum.select,
    name: 'DateRoutine',
    label: 'Opciones Diarias',
    options: [
      { label: 'Lunes', value: 'Lunes' },
      { label: 'Martes', value: 'Martes' },
      { label: 'Miércoles', value: 'Miércoles' },
      { label: 'Jueves', value: 'Jueves' },
      { label: 'Viernes', value: 'Viernes' },
      { label: 'Sábado', value: 'Sábado' },
      { label: 'Domingo', value: 'Domingo' }
    ],
    dependentOn: 'Frequency',
    dependentValue: 'Diario'
  }
]
