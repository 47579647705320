// administratorConfig.ts
import { FieldTypeEnum, FormField } from '../../Form/DynamicForm'
import { TableColumn, enumTypeColumn } from '../../Table'

export const columnsAccessConfig: TableColumn[] = [
  {
    title: 'NOMBRE',
    dataIndex: 'name',
    key: 'name',
    type: enumTypeColumn.string
  },
  {
    title: 'CARPETA MESTRA',
    dataIndex: 'FileMaster',
    key: 'FileMaster',
    type: enumTypeColumn.string
  },
  {
    title: 'ROSTRO',
    dataIndex: 'FileFace',
    key: 'FileFace',
    type: enumTypeColumn.string
  },
  {
    title: 'DPI FRONTAL',
    dataIndex: 'FileDNI1',
    key: 'FileDNI1',
    type: enumTypeColumn.string
  }
]

export const formAccessConfig: FormField[] = [
  { type: FieldTypeEnum.text, label: 'NOMBRE ', name: 'name' },
  { type: FieldTypeEnum.FileMaster, label: 'CARPETA MESTRA ', name: 'FileMaster' },
  { type: FieldTypeEnum.text, label: 'CAMARA ROSTRO', name: 'FileFace' },
  { type: FieldTypeEnum.text, label: 'CAMARA DPI FRONTAL', name: 'FileDNI1' }
]
