import React, { FC } from 'react'
import DynamicForm from '../../Form/DynamicForm'
import './styles.scss'
import { ClientCreate } from '../../../types/client'
import { createClient } from '../../../services/clients'
import { useLoading } from '../../../Provider/LoadingProvider'
import { clientFormConfig } from './Columns'

const FormClient: FC<{ onSubmit: () => void }> = ({ onSubmit }) => {
  const { startLoading, stopLoading } = useLoading()

  const handleSubmit = async (formData: ClientCreate) => {
    try {
      startLoading()
      const data = new FormData()
      Object.entries({
        name: formData.name,
        phone: formData.phone,
        email: formData.email,
        nit: formData.nit,
        photo: formData.photo,
        administratorName: formData.administratorName
      }).forEach(([key, value]) => {
        data.append(key, value)
      })
      await createClient(data)
      onSubmit()
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  return (
    <div className="newClientForm">
      <h2>Nuevo Cliente</h2>
      <DynamicForm formConfig={clientFormConfig} onSubmit={handleSubmit} />
    </div>
  )
}

export default FormClient
