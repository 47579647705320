import React from 'react'

const PlaceholderSvg = () => {
  return (
    <svg
      width='110'
      height='100'
      viewBox='0 0 140 130'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M139.259 114.966V14.5895C139.259 6.70278 132.339 0.25 123.881 0.25H16.2304C7.77218 0.25 0.851807 6.70278 0.851807 14.5895V114.966C0.851807 122.853 7.77218 129.306 16.2304 129.306H123.881C132.339 129.306 139.259 122.853 139.259 114.966ZM43.143 75.5324L62.3662 97.1134L89.2788 64.7778L123.881 107.796H16.2304L43.143 75.5324Z'
        fill='#CCCCCC'
      />
    </svg>
  )
}

export default PlaceholderSvg
