import React from 'react'
import { Switch as AntSwitch, ConfigProvider } from 'antd'
import './style.scss'
interface SwitchProps {
  value?: boolean
  onChange?: (checked: boolean) => void
  label?: string
  activeColor?: string
  ColorDiscative?: string
}

const Switch: React.FC<SwitchProps> = ({
  value,
  onChange,
  label,
  activeColor,
  ColorDiscative
}) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          ...(ColorDiscative ? { colorTextQuaternary: ColorDiscative } : {}),
          ...(ColorDiscative ? { colorTextTertiary: `${ColorDiscative}81` } : {}), //al hacer hover
          ...(activeColor ? { colorPrimaryHover: `${activeColor}81` } : {}), //al hacer hover
          ...(activeColor ? { colorPrimary: activeColor } : {})
        }
      }}
    >
      <div className="switch-container">
        {label && <label className="label">{label}</label>}
        <AntSwitch checked={value} onChange={onChange} />
      </div>
    </ConfigProvider>
  )
}

export default Switch
