import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../Provider/AuthProvider'
import { roleEnum } from '../types/user'

const PrivateRoute: React.FC<{ allowedRoles: roleEnum[] }> = ({ allowedRoles }) => {
  const { user } = useAuth()

  if (!user) {
    return <Navigate to="/login" />
  }

  if (!allowedRoles.includes(user.role)) {
    return <Navigate to="/home" />
  }

  return <Outlet />
}

export default PrivateRoute
