// CustomMessage.tsx
import React from 'react'
import './style.scss'

export type MessageType = 'success' | 'info' | 'warning' | 'error'

interface CustomMessageProps {
  type: MessageType
  message: string
  visible: boolean
  onClose: () => void
}

const CustomMessage: React.FC<CustomMessageProps> = ({
  type,
  message,
  visible,
  onClose
}) => {
  return (
    <>
      {visible && (
        <div className='popup-overlay'>
          <div className='popup'>
            <div className={`${type}-icon`}></div>

            <p className='message'>{message}</p>
            <button
              className='register-button'
              style={{ display: 'inline', width: '250px', height: '46px' }}
              onClick={onClose}>
              Entendido
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default CustomMessage
