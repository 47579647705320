import React, { useState, useEffect, ReactNode } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { entries, entriesApprovedEnum } from '../../../types/entries'
import PopUpConfirm from '../../Table/components/PopupConfirm'
import { modify_entry } from '../../../services/entries'
import { useAuth } from '../../../Provider/AuthProvider'

interface MenuOption {
  label: string
  newStatus: string
  icon?: React.ElementType
  colorSvg?: string
}

interface ContextMenuProps {
  options: MenuOption[]
  children: ReactNode
  setlastEntry: (value: React.SetStateAction<entries | null | undefined>) => void
  lastEntry: entries | null | undefined
}

const ContextMenu: React.FC<ContextMenuProps> = ({
  lastEntry,
  options,
  children,
  setlastEntry
}) => {
  const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number } | null>(
    null
  )
  const [isPopUpOpen, setIsPopUpOpen] = useState(false)
  const [newStatusText, setNewStatusText] = useState<string>('')
  const { token } = useAuth()

  const handleContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    setContextMenu({
      mouseX: event.clientX + 2,
      mouseY: event.clientY - 6
    })
  }

  const handleClick = () => {
    setContextMenu(null)
  }

  useEffect(() => {
    window.addEventListener('click', handleClick)
    return () => {
      window.removeEventListener('click', handleClick)
    }
  }, [])

  const handleAccept = async (id: string | undefined, status?: string) => {
    try {
      if (!id || !status) return
      if (!(status in entriesApprovedEnum)) return
      setIsPopUpOpen(false)
      await modify_entry({ status, id, token })
      setlastEntry(old =>
        old
          ? {
              ...old,
              approved: entriesApprovedEnum[status as keyof typeof entriesApprovedEnum]
            }
          : null
      )
    } catch (error) {
      console.error(error)
    }
  }

  const handleReject = () => {
    setIsPopUpOpen(false)
  }

  const newTextStatus: {
    [key: string]: string
  } = {
    APPROVED: 'AUTORIZADO',
    REJECT: 'DENEGADO',
    UNANSWERED: 'NO ATENDIDO',
    RECESSION: 'ESPERA EN RECEPCIÓN',
    LEAVE: 'GUARDADO EN RECEPCIÓN',
    NO_LEAVE: 'NO ESPERA PAQUETERIA'
  }

  return (
    <div onContextMenu={handleContextMenu} style={{ display: 'inline-block' }}>
      {children}
      <Menu
        open={contextMenu !== null}
        onClose={() => setContextMenu(null)}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {options.map(({ label, colorSvg, newStatus, icon: Icon }, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              console.log(newStatus)

              setIsPopUpOpen(true)
              setNewStatusText(newStatus)
            }}
          >
            {Icon && (
              <Icon
                style={{
                  marginRight: 8,
                  height: '1.5rem',
                  width: '1.5rem',
                  justifyContent: 'center',
                  color: colorSvg
                }}
              />
            )}
            {label}
          </MenuItem>
        ))}
      </Menu>

      <PopUpConfirm
        isOpen={isPopUpOpen}
        onClose={() => setIsPopUpOpen(false)}
        title={`¿EL INGRESO PASARA A SER ${newTextStatus[newStatusText]}?`}
        description="Esta acción no se puede deshacer."
        acceptText="Aceptar"
        rejectText="Cancelar"
        handleAccept={() => {
          handleAccept(lastEntry?.id, newStatusText)
        }}
        handleReject={handleReject}
      />
    </div>
  )
}

export default ContextMenu
