// services/equipmentService.ts
import { Equipment, EquipmentCreate } from '../types/equipment'
import { Pagination, PaginationQuery } from '../types/types'
import axiosInstance from './axiosInstance'

export const listEquipmentByClient = async ({
  id,
  params
}: {
  id: string
  params: PaginationQuery
}): Promise<Pagination<Equipment>> => {
  try {
    const response = await axiosInstance.get(`/api/equipments/byclient/${id}`, {
      params
    })

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de equipos:', error)
    throw error
  }
}

export const createEquipment = async (params: EquipmentCreate): Promise<Equipment> => {
  try {
    const response = await axiosInstance.post(`/api/equipments`, JSON.stringify(params), {})

    return response.data
  } catch (error: any) {
    console.error('Error en la solicitud de creación de equipo:', error)
    throw error.response.data
  }
}
export const updateEquipment = async (
  id: string,
  params: EquipmentCreate
): Promise<Equipment> => {
  try {
    const response = await axiosInstance.post(
      `/api/equipments/${id}`,
      JSON.stringify(params),
      {}
    )

    return response.data
  } catch (error: any) {
    console.error('Error en la solicitud de creación de equipo:', error)
    throw error.response.data
  }
}

export const deleteEquipment = async (id: string): Promise<void> => {
  try {
    const response = await axiosInstance.delete(`/api/equipments/${id}`)

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de eliminación de equipo:', error)
    throw error
  }
}
