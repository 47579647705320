import React, { ReactNode, useEffect } from 'react'
import { get_whatsapp } from '../../../services/whatsapp'
import { Toaster, toast } from 'sonner'
import Sidebar from '../Sidebar'
import './styles.scss'
import UserInfo from '../UserInfo'
import { useAuth } from '../../../Provider/AuthProvider'
import { navBarStore } from '../../../services/store'

interface LayoutProps {
  children: ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { user, token } = useAuth()
  const { statusBar } = navBarStore()

  useEffect(() => {
    // Función para consultar el estado de la otra aplicación
    if (user?.role !== 'admin' && user?.role !== 'super_admin') return

    const getData = async () => {
      try {
        const { status } = await get_whatsapp(user?.clientId as string, token)

        if (status) {
          toast.success(`Estado WhatsApp : ${status} `)
        } else {
          toast.error('Error al verificar WhatsApp.')
        }
      } catch (error) {
        toast.error('Error en WhatsApp WhatsApp.')
      }
    }

    // Llamar a la función al montar el componente
    getData()
  }, [])

  return (
    <div className='layoutContainer'>
      <nav>
        <Sidebar />
      </nav>
      <UserInfo />
      <Toaster />
      <main className={statusBar ? `fullMainContainer` : `mainContainer`}>
        {children}
      </main>
    </div>
  )
}

export default Layout
