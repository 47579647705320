import React, { FC } from 'react'
import { useLoading } from '../../../Provider/LoadingProvider'
import { createStaff, updateStaff } from '../../../services/staff'
import { Staff, StaffCreate } from '../../../types/staff'
import DynamicForm from '../../Form/DynamicForm'
import { FormConfig } from './Columns'
import './styles.scss'
import {} from '../../../utils/utils'

const FormStaff: FC<{
  onSubmit: () => void
  clientId: string
  initialValue?: Staff
}> = ({ onSubmit, initialValue, clientId }) => {
  const { startLoading, stopLoading } = useLoading()

  const handleSubmit = async (formData: StaffCreate) => {
    try {
      startLoading()
      const data = new FormData()
      Object.entries({
        name: formData.name,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
        profile: formData.profile,
        photo: formData.photo,
        clientId: clientId
      }).forEach(([key, value]) => {
        data.append(key, value)
      })
      if (initialValue) {
        await updateStaff(initialValue.id, data)
      } else {
        await createStaff(data)
      }

      onSubmit()
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  return (
    <div className='newClientForm'>
      <h2>{initialValue ? 'Actualizar trabajador' : 'Nuevo trabajador'}</h2>
      <DynamicForm
        initialValues={initialValue}
        formConfig={FormConfig}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

export default FormStaff
