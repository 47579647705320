// administratorConfig.ts
import { FieldTypeEnum, FormField } from '../../Form/DynamicForm'
import { TableColumn, enumTypeColumn } from '../../Table'

const type: any[] = [
  { value: `Cuota`, label: `Cuota` },
  { value: `Reserva`, label: `Reserva` },
  { value: `Mora`, label: `Mora` }
]

export const columnsPaymentConcept: TableColumn[] = [
  {
    title: 'icono',
    dataIndex: 'icon',
    key: 'icon',
    type: enumTypeColumn.photo
  },
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    type: enumTypeColumn.string
  },
  {
    title: 'Valor',
    dataIndex: 'price',
    key: 'price',
    type: enumTypeColumn.string
  },
  {
    title: 'Segmento',
    dataIndex: 'type',
    key: 'type',
    type: enumTypeColumn.string
  },
  {
    title: 'Global',
    dataIndex: 'global',
    key: 'global',
    type: enumTypeColumn.boolean
  }
]

export const FormConfigPaymentConcept: FormField[] = [
  { type: FieldTypeEnum.text, label: 'Nombre', name: 'name' },
  { type: FieldTypeEnum.number, label: 'Valor', name: 'price' },
  {
    type: FieldTypeEnum.select,
    label: 'Segmento',
    name: 'type',
    options: type
  },
  { type: FieldTypeEnum.image, label: 'Icono', name: 'icon' }
]
