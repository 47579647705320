import React, { FC } from 'react'
import { useLoading } from '../../../Provider/LoadingProvider'
import {
  createAdministrator,
  updateAdministrator
} from '../../../services/administrators'
import {
  Administrator,
  AdministratorCreate
} from '../../../types/administrators'
import DynamicForm from '../../Form/DynamicForm'
import { administratorFormConfig } from './Columns'

import './styles.scss'

const FormAdministrator: FC<{
  onSubmit: () => void
  clientId: string
  initialValue?: Administrator
}> = ({ onSubmit, clientId, initialValue }) => {
  const { startLoading, stopLoading } = useLoading()

  const handleSubmit = async (formData: AdministratorCreate) => {
    try {
      startLoading()

      const data = new FormData()
      Object.entries({
        name: formData.name,
        lastName: formData.lastName,
        userName: formData.userName,
        phoneNumber: formData.phoneNumber,
        ...(formData.password ? { password: formData.password } : {}),
        photo: formData.photo,
        clientId: clientId
      }).forEach(([key, value]) => {
        data.append(key, value)
      })
      if (initialValue) {
        await updateAdministrator(initialValue.id, data)
      } else {
        await createAdministrator(data)
      }

      onSubmit()
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  return (
    <div className='newClientForm'>
      <h2>
        {initialValue ? 'Actualizar administrador' : 'Nuevo administrador'}
      </h2>
      <DynamicForm
        initialValues={initialValue}
        formConfig={administratorFormConfig}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

export default FormAdministrator
